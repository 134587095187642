/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Row, Col, FormFeedback, Label } from "reactstrap";
import DualListBox from "react-dual-listbox";

const Wrapper = (props) => {
  return props.noRow ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : (
    <Row className="align-items-center">{props.children}</Row>
  );
};

function DualList(props) {
  return (
    <React.Fragment>
      <Wrapper {...props}>
        {!props.noLabel && (
          <Col sm={2}>
            <Label>{props.label}</Label>
          </Col>
        )}
        <Col>
          <DualListBox
            options={props.options ? props.options : []}
            selected={props.value}
            onChange={(values) => props.onChange(props.name, values)}
            showHeaderLabels
            canFilter
            filterPlaceholder="Filtrar..."
            lang={{
              selectedHeader: "Seleccionado",
              availableHeader: "Disponible",
            }}
          />
          <FormFeedback invalid>
            {props.error || "Este campo es necesario"}
          </FormFeedback>
        </Col>
      </Wrapper>
    </React.Fragment>
  );
}

DualList.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  noRow: PropTypes.bool,
  noLabel: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

DualList.defaultProps = {
  required: true,
  disabled: false,
};

export default DualList;
