import React, { useContext, useState } from "react";
import { Modal, ModalBody, ModalHeader, Col, Button } from "reactstrap";
import { API_URL } from "helpers/ApiHelper";
import { Table } from "components/Table";
import { DataContext } from "./Content";
import NumberFormat from "react-number-format";
import { get } from "helpers/ApiHelper";

const ProductModal = ({ elementId, toggle, addProduct }) => {
  const isOpen = elementId !== -1;

  const { products, setProductFilters } = useContext(DataContext);

  const clickHandler = async (e, item) => {
    e.stopPropagation();
    let ufValue = 0;

    if (!item.price_uf) {
      const response = await get("currencies/" + 1);

      if (response.data.currencyvalues.length) {
        ufValue = response.data.currencyvalues[0].value;
      }
    }

    const data = {
      product_id: item.id,
      product: item,
      model: item.model ? item.model.name : "-",
      supplier: item.model?.brand?.suppliers
        ? item.model.brand.suppliers.map((item) => item.name).join(" - ")
        : "-",
      total: item.price_clp ? item.price_clp : item.price_list,
      totalUF: item.price_uf ? item.price_uf : item.price_list / ufValue,
    };

    addProduct(elementId, data);
  };

  const getProductFromAllSegments = () => {
    setProductFilters((prev) => {
      delete prev.segment_id;

      return { ...prev };
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "Tipo de Elemento",
      hidden: true,
    },
    {
      dataField: "images[0]",
      text: "",
      formatter: (_cell, row) => (
        <Col className="thumbnail-scale" style={{ height: 100 }}>
          <img src={API_URL + row.images[0]?.link} alt="" />
        </Col>
      ),
    },
    {
      dataField: "name",
      text: "Nombre",
      customType: "string",
    },
    {
      dataField: "model.name",
      text: "Modelo",
      customType: "string",
      formatter: (cell) => cell || "-",
    },
    {
      dataField: "model.brand.suppliers",
      text: "Proveedor",
      customType: "string",
      formatter: (cell) =>
        cell ? cell.map((item) => item.name).join(" - ") : "-",
      filterValue: (cell) => cell.map((item) => item.name).join(" - "),
    },
    {
      dataField: "price_clp",
      text: "Precio",
      customType: "price",
      formatter: (cell, row) => (
        <NumberFormat
          prefix="$ "
          thousandSeparator="."
          decimalSeparator=","
          value={cell || row.price_list}
          displayType="text"
        />
      ),
    },
    {
      dataField: "price_uf",
      text: "Precio UF",
      customType: "price",
      formatter: (cell, row) => (
        <NumberFormat
          prefix="UF "
          thousandSeparator="."
          decimalSeparator=","
          value={cell || row.price_list}
          displayType="text"
        />
      ),
    },
    {
      dataField: "segments",
      text: "Segmentos",
      customType: "string",
      formatter: (cell) => cell.map((item) => item.name).join(", "),
      filterValue: (cell) => cell.map((item) => item.name).join(", "),
    },
    {
      dataField: "generic",
      text: "Tipo",
      customType: "select",
      options: [
        { label: "Normal", value: false },
        { label: "Generico", value: true },
      ],
      formatter: (cell) => (cell ? "Generico" : "Normal"),
    },
  ];

  const events = {
    onClick: clickHandler,
  };

  return (
    <Modal size="xl" centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Lista de productos</ModalHeader>
      <ModalBody className="vstack gap-3">
        <div>
          <Button color="info" onClick={getProductFromAllSegments}>
            Obtener productos de todos los segmentos
          </Button>
        </div>
        <Table
          headers={columns}
          data={products}
          rowEvents={events}
          noFieldset
          rowClasses={"pointer"}
        />
      </ModalBody>
    </Modal>
  );
};

export default ProductModal;
