/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Col, FormFeedback, Label, FormGroup } from "reactstrap";
import { DatePicker } from "reactstrap-date-picker";
import { useMemo, useState, useEffect } from "react";
import moment from "moment";

const Wrapper = (props) => {
  return props.noRow ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : (
    <FormGroup className="row align-items-center">{props.children}</FormGroup>
  );
};

function DateInput(props) {
  const [innerValue, setInnerValue] = useState(props.value);
  const [innerError, setInnerError] = useState("");

  useEffect(() => {
    if (props.value) {
      setInnerValue(moment(props.value).utc().format("YYYY-MM-DD HH:mm"));
    } else {
      setInnerValue(null);
    }
  }, [props.value]);

  const minDate = useMemo(() => {
    if (props.minDate === null || props.minDate === undefined) {
      return false;
    }

    if (props.minDate === "today") {
      return moment().startOf("day").toISOString();
    }

    if (props.minDate === "last_year") {
      return moment().subtract(1, "years").startOf("year").toISOString();
    }

    if (props.minDate === "last_month") {
      return moment().subtract(1, "months").toISOString();
    }

    if (props.minDate.instanceOf(Date)) {
      return props.minDate.toISOString();
    }

    return false;
  }, [props.minDate]);

  const onChange = (value) => {
    if (value === null) {
      props.onChange(props.name, null);
      return;
    }

    value = moment(value).format("YYYY-MM-DD");
    if (!minDate) {
      setInnerError("");
      props.onChange(props.name, value);
      return;
    }

    const currentValue = moment(value);

    if (currentValue.isBefore(minDate)) {
      setInnerError(
        "Se necesita una fecha superior a " +
          moment(minDate).format("DD/MM/YYYY")
      );
      props.onChange(props.name, null);
      return;
    }

    setInnerError("");
    props.onChange(props.name, value);
  };

  return (
    <React.Fragment>
      <Wrapper {...props}>
        {!props.noLabel && (
          <Col lg={2}>
            <Label for={props.name} className="mb-0">
              {props.label}
            </Label>
          </Col>
        )}
        <Col>
          <DatePicker
            id={props.name}
            name={props.name}
            onChange={(value) => onChange(value)}
            value={innerValue || ""}
            placeholder={props.placeholder || "Ingrese un valor..."}
            disabled={props.disabled || false}
            required={props.required}
            invalid={!!innerError || !!props.error}
            minDate={minDate ? minDate : null}
            dateFormat="DD/MM/YYYY"
            weekStartsOn={1}
            dayLabels={["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"]}
            monthLabels={[
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ]}
          />
          <FormFeedback
            invalid="true"
            style={{ display: !!innerError || !!props.error ? "block" : "" }}
          >
            {innerError || props.error || "Este campo es necesario"}
          </FormFeedback>
        </Col>
      </Wrapper>
    </React.Fragment>
  );
}

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  noRow: PropTypes.bool,
  noLabel: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.bool,
  ]),
};

DateInput.defaultProps = {
  required: true,
  disabled: false,
};

export default DateInput;
