import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import ProductCard from "./ListComponents/ProductCard";
import Search from "./ListComponents/Search";
import { get } from "helpers/ApiHelper";
import ProductModal from "./Modals/productModal";
import { isNumber } from "lodash";
import Filters from "./ListComponents/Filters";
import "./ListComponents/pilotogoScript";

const Content = () => {
  const [showFilters, setShowFilters] = useState("filters-show");
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [typereviews, setTypereviews] = useState([]);

  const [productOffset, setProductOffset] = useState(0);
  const [productTotal, setProductTotal] = useState(0);
  const [productLimit] = useState(30);

  const [filters, setFilters] = useState({
    sort_by: "date",
    sort_order: "DESC",
  });

  useEffect(() => {
    get("typereviews").then((response) => {
      if (response.status !== 200) return;

      const sortedData = response.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setTypereviews(
        sortedData
          .sort((a, b) => a.sortidx - b.sortidx)
          .map((item) => ({ label: item.name, value: item.id }))
      );
    });
  }, []);

  const sortValues = [
    { sort_by: "price", sort_order: "DESC" },
    { sort_by: "price", sort_order: "ASC" },
    { sort_by: "date", sort_order: "DESC" },
    { sort_by: "date", sort_order: "ASC" },
    { sort_by: "name", sort_order: "ASC" },
    { sort_by: "name", sort_order: "DESC" },
  ];

  const handleInput = (name, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleSort = (idx) => {
    setProductOffset(0);
    setFilters((prevFilters) => ({ ...prevFilters, ...sortValues[idx] }));
  };

  const handleCheck = (name, value) => {
    setFilters((prevData) => {
      prevData[name] = prevData[name] || [];

      if (prevData[name].includes(value)) {
        // Already exist, remove
        prevData[name] = prevData[name].filter((item) => item !== value);
      } else {
        // Inlcude
        prevData[name].push(value);
      }

      return {
        ...prevData,
      };
    });
  };

  const getProducts = useCallback(() => {
    const aux = Object.fromEntries(
      Object.entries(filters).filter(([_key, value]) => value)
    );

    if (aux.quick_search) {
      window.gtag("event", "Buscar", {
        event_category: "Botón",
        event_label: aux.quick_search,
      });
    }

    window.gtag("event", "Filtrar", {
      event_category: "Botón",
      event_label: Object.keys(aux)
        .filter((item) => item !== "quick_search")
        .join(";"),
    });

    const params = { ...aux, limit: productLimit, offset: productOffset };

    const query = new URLSearchParams(params);
    get("products?" + query).then((response) => {
      setProducts(response.status === 200 ? response.data.products : []);
      setProductTotal(response.status === 200 ? response.data.total : 0);
    });
  }, [filters, productOffset, productLimit]);

  useEffect(getProducts, [getProducts, productOffset, productLimit]);

  const toggleModal = async (idx) => {
    setShowModal((prev) => !prev);

    if (isNumber(idx)) {
      setSelectedProduct(idx);

      window.gtag("event", "Abrir Ficha", {
        event_category: "Botón",
        event_label: products[idx].name,
      });
    } else {
      // wait 1 seg to finish animation
      await new Promise((resolve) => setTimeout(resolve, 250));
      setSelectedProduct(null);
    }
  };

  const showFiltersToggler = () => {
    let original;
    setShowFilters((prev) => {
      original = prev;
      return original === "filters-hide"
        ? "filters-show overflow-hidden"
        : "filters-hide";
    });

    setTimeout(
      () =>
        setShowFilters(
          original === "filters-show" ? "filters-hide" : "filters-show"
        ),
      1000
    );
  };

  const formatPagination = () => {
    const total = Math.ceil((productTotal + 1) / productLimit);
    const current = Math.ceil((productOffset + 1) / productLimit);

    const div = [];

    div.push(
      <PaginationItem onClick={() => changeOffset(0)}>
        <PaginationLink first />
      </PaginationItem>
    );

    div.push(
      <PaginationItem
        onClick={() => changeOffset(current > 1 ? current - 2 : 0)}
      >
        <PaginationLink previous />
      </PaginationItem>
    );

    for (let idx = 0; idx < total; idx++) {
      div.push(
        <PaginationItem
          active={current - 1 === idx}
          onClick={() => changeOffset(idx)}
        >
          <PaginationLink>{idx + 1}</PaginationLink>
        </PaginationItem>
      );
    }

    div.push(
      <PaginationItem
        onClick={() => changeOffset(current < total ? current : total - 1)}
      >
        <PaginationLink next />
      </PaginationItem>
    );
    div.push(
      <PaginationItem onClick={() => changeOffset(total - 1)}>
        <PaginationLink last />
      </PaginationItem>
    );

    return div;
  };

  const changeOffset = (idx) => {
    setProductOffset(idx * productLimit);
    setTimeout(
      () =>
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        }),
      500
    );
  };

  return (
    <React.Fragment>
      <ProductModal
        toggle={toggleModal}
        isOpen={showModal}
        product={products[selectedProduct] && { ...products[selectedProduct] }}
        typereviews={typereviews}
      />
      <h2 className="text-white">Resultados Para:</h2>
      <Row className="align-items-center gap-3 mb-3 ">
        <Col className="p-0">
          <div className="bg-primary rounded-border flex-grow-1 p-3">
            <Search
              filters={filters}
              handleInput={handleInput}
              setOffset={setProductOffset}
              onSubmit={getProducts}
            />
          </div>
        </Col>
      </Row>

      <Row className="mb-3">
        <div className="d-grid">
          <Button className="" onClick={showFiltersToggler}>
            Filtros
          </Button>
        </div>
        <div className={showFilters}>
          <Filters
            filters={filters}
            setFilters={setFilters}
            setOffset={setProductOffset}
            handleCheck={handleCheck}
            getProducts={getProducts}
          />
        </div>
      </Row>
      <Row className="mb-3 gap-3 align-items-center">
        <Col
          sm={{ offset: 3, size: 9 }}
          className="text-white d-flex justify-content-between align-items-center"
        >
          <div className="ms-auto">
            <Input
              type="select"
              onChange={(e) => handleSort(Number(e.target.value))}
              defaultValue={2}
            >
              <option>Ordenar por...</option>
              <option value={0}>Mayor a Menor Precio</option>
              <option value={1}>Menor a Mayor Precio</option>
              <option value={2}>Más Reciente</option>
              <option value={3}>Más Antiguo</option>
              <option value={4}>Alfabetico Ascendente</option>
              <option value={5}>Alfabetico Decendente</option>
            </Input>
          </div>
        </Col>
      </Row>
      <Row className="d-flex gap-3 mb-3">
        <Col>
          <Row xs={1} lg={3} id="product-list" className="flex-grow-1 g-3">
            {products.length ? (
              products.map((item, idx) => (
                <ProductCard
                  product={item}
                  key={idx}
                  id={idx}
                  toggleModal={toggleModal}
                  typereviews={typereviews}
                />
              ))
            ) : (
              <Row>
                <h3 className="text-white">No hay productos para mostrar</h3>
              </Row>
            )}
          </Row>
        </Col>
      </Row>

      <Row className="justify-content-end">
        <Pagination listClassName="justify-content-end">
          {formatPagination()}
        </Pagination>
      </Row>
    </React.Fragment>
  );
};

export default Content;
