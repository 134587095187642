/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { AvField } from "availity-reactstrap-validation";
import { FormGroup, Col, Label } from "reactstrap";

class EmailInput extends Component {
    static propTypes = {
        //match: PropTypes.object.isRequired,
        //location: PropTypes.object.isRequired,
        //history: PropTypes.object.isRequired,
        handleChangeTextInput: PropTypes.func,
    };

    render() {
        return (
            <React.Fragment>
                <FormGroup className="row mb-4">
                    <Label className="col-sm-2 col-form-label" htmlfor="password">
                        Password
                    </Label>
                    <Col className="col-sm-10">
                        <AvField
                            name="password"
                            className="form-control"
                            onChange={(e) => this.props.handleChangeTextInput(e)}
                            // value="123456"
                            type="password"
                            required
                            errorMessage="Ingrese su contraseña"
                            placeholder="Ingrese su contraseña"
                        />
                    </Col>
                </FormGroup>
            </React.Fragment>
        );
    }
}

export default EmailInput;
