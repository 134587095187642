import React, { useEffect, useState, createContext } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Button, Alert } from "reactstrap";
import { API_URL, get, put, TOKEN } from "helpers/ApiHelper";
import Zone from "./Zone";
import JsFileDownloader from "js-file-downloader";
import { checkRole } from "helpers/getUser";
import { useFetchSelect } from "components/Form";

export const DataContext = createContext();

const Content = () => {
  const segment = useLocation().pathname.split("/")[2];

  let segment_id;
  const roles = checkRole([
    "Administrador",
    "Inmobiliaria",
    "Gerencia Presupuesto",
    "Gerencia ODI",
    "ODI",
  ]);

  const [data, setData] = useState([]);
  const [version, setVersion] = useState("");
  const [lastUpdated, setLastUpdated] = useState();
  const [update, setUpdate] = useState(0);

  const [message, setMessage] = useState();
  const [error, setError] = useState();

  const typeelements = useFetchSelect("typeelements");
  const generalattributes = useFetchSelect("generalattributes");
  const specificattributes = useFetchSelect("specificattributes");
  const unitmeasurements = useFetchSelect("unitmeasurements");

  useEffect(() => {
    const fetchData = async () => {
      const request = await get("arrays/" + segment_id);

      if (request.status === 200) {
        return request.data;
      }
    };

    let isMounted = true;

    fetchData().then((data) => {
      if (!isMounted && data) return;

      setData(data.zones);
      setVersion(data.version);
      setLastUpdated(new Date(data.updatedAt).toLocaleDateString());
    });

    return () => (isMounted = false);
  }, [segment_id, segment, update]);

  const validateArray = () => {
    let errors = 0;
    const validateElement = (item) => {
      if (!item.typeelement_id) {
        item.error = true;
        ++errors;
      }

      if (!item.elementdetail.budget) {
        item.error = true;
        ++errors;
      }

      if (!item.elementdetail.unit_measurement) {
        item.error = true;
        ++errors;
      }

      if (!item.elementdetail.generalattribute_id) {
        item.error = true;
        ++errors;
      }

      if (!item.elementdetail.specificattribute_id) {
        item.error = true;
        ++errors;
      }
      return true;
    };

    const validateCategory = (item) => {
      if (!item.name) {
        item.error = true;
        ++errors;
      }

      item.elementtypes.forEach(validateElement);
    };

    const validateZone = (item) => {
      if (!item.name) {
        item.error = true;
        ++errors;
      }

      item.categories.forEach(validateCategory);
    };

    setData((data) => {
      data.forEach(validateZone);
      return [...data];
    });

    if (errors !== 0) {
      setMessage("Error en la validación de los datos.");
    } else {
      // if no errors where found
      return errors === 0;
    }
  };

  const sendForm = async () => {
    if (!validateArray()) return;

    const response = await put("arrays/" + segment_id, { zones: data });
    setMessage(response.message);

    if (response.status === 200) {
      setUpdate(new Date().getTime());
      setError(false);
    } else {
      setError(true);
    }
  };

  const renderTitle = () => {
    if (segment === "hight") {
      segment_id = 5;
      return "Alto";
    }
    if (segment === "middle-hight") {
      segment_id = 4;
      return "Medio Alto";
    }
    if (segment === "middle") {
      segment_id = 3;
      return "Medio";
    }
    if (segment === "middle-low") {
      segment_id = 2;
      return "Medio Bajo";
    }
    if (segment === "low") {
      segment_id = 1;
      return "Bajo";
    }
  };

  const downloadFile = async (type) => {
    if (roles) {
      await sendForm();
    }

    const filename = type === "pdf" ? "matriz.pdf" : "matriz.xlsx";

    await new JsFileDownloader({
      url: `${API_URL}export/${type}/array/${segment_id}`,
      headers: [{ name: "Authorization", value: `Bearer ${TOKEN}` }],
      contentTypeDetermination: "header",
      filename: filename,
    });
  };

  const canRenderZones = () => {
    if (!data.length) return false;
    if (!typeelements.length) return false;
    if (!generalattributes.length) return false;
    if (!specificattributes.length) return false;

    return true;
  };

  const appendZone = () => {
    setData((prev) => {
      prev.push({
        name: " - ",
        pos: data.length + 1,
        categories: [
          {
            name: " - ",
            pos: 1,
            elementtypes: [
              {
                name: "",
                pos: 1,
                typeelement: {
                  name: "",
                },
                elementdetail: {
                  name: "",
                  general_attribute: "",
                  specific_attribute: "",
                  unit_measurement: "",
                },
              },
            ],
          },
        ],
      });
      return [...prev];
    });
  };

  const renderZones = () => {
    if (!canRenderZones) return;

    if (!specificattributes.length) return;

    if (!data.length) {
      return (
        <div className="my-3">
          <Button size="sm" color="success" outline onClick={appendZone}>
            Añadir zona
          </Button>
        </div>
      );
    }

    return data.map((item, idx) => (
      <Zone key={idx} zone_idx={idx} data={item} />
    ));
  };

  return (
    <React.Fragment>
      <Row className="align-items-center mb-3">
        <Col lx="auto" className="me-auto">
          <section className="hstack gap-3">
            <h4 className="text-white">Matrices / {renderTitle()}</h4>
            <h5 className="text-white">
              (Versión: {version} - fecha: {lastUpdated})
            </h5>
          </section>
        </Col>
        <Col lx={1} className="hstack gap-3 justify-content-end">
          <Button color="info" size="lg" onClick={() => downloadFile("pdf")}>
            Exportar a PDF
          </Button>

          {checkRole([
            "Administrador",
            "Inmobiliaria",
            "Gerencia Presupuesto",
            "Gerencia ODI",
            "ODI",
          ]) && (
            <Button
              color="info"
              size="lg"
              onClick={() => downloadFile("excel")}
            >
              Exportar a Excel
            </Button>
          )}
        </Col>
      </Row>
      <fieldset className="pb-4">
        <Row className="fs-5 mx-0 mb-3 text-center">
          <Col xs={3} className="text-warning border border-dark bg-secondary">
            Recinto
          </Col>
          <Col
            xs={8}
            className="text-info border border-start-0 border-dark bg-secondary"
          >
            Atributos
          </Col>
        </Row>
        <DataContext.Provider
          value={{
            data,
            setData,
            unitmeasurements,
            typeelements,
            generalattributes,
            specificattributes,
          }}
        >
          {renderZones()}
        </DataContext.Provider>
        {message && (
          <Row>
            <Col xs={11}>
              <Alert color={error ? "danger" : "success"}>{message}</Alert>
            </Col>
          </Row>
        )}
        {roles && (
          <Row>
            <Col xs={11} className="d-grid">
              <Button color="success" onClick={sendForm}>
                Guardar
              </Button>
            </Col>
          </Row>
        )}
      </fieldset>
    </React.Fragment>
  );
};

export default Content;
