import React from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import Content from "../../../components/Sections/Maintainers/Lists/ElementType";

const ElementType = () => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {process.env.REACT_APP_PAGE_TITLE} | Mantenedor de Tipos de Elemento
        </title>
      </MetaTags>
      <Container fluid>
        <Content />
      </Container>
    </div>
  );
};

export default ElementType;
