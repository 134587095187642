import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { PaginationItem, PaginationLink } from "reactstrap";

const PaginationContext = createContext();

export default function usePagination() {
  const [state, dispatch, fetchItems] = useContext(PaginationContext);

  const updateFilters = (name, value) => {
    dispatch({ type: "update-filters", name, value });
  };

  return {
    items: state.items,
    filters: state.filters,
    updateFilters,
    fetchItems,
  };
}

export function PaginationItems() {
  const [state, dispatch] = useContext(PaginationContext);

  const changeOffset = async (idx) => {
    dispatch({ type: "update", name: "offset", value: idx * state.limit });

    await new Promise((r) => setTimeout(r, 500));

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const total = Math.ceil((state.total + 1) / state.limit);
  const current = Math.ceil((state.offset + 1) / state.limit);

  const div = [];

  div.push(
    <PaginationItem onClick={() => changeOffset(0)}>
      <PaginationLink first />
    </PaginationItem>
  );

  div.push(
    <PaginationItem onClick={() => changeOffset(current > 1 ? current - 2 : 0)}>
      <PaginationLink previous />
    </PaginationItem>
  );

  for (let idx = 0; idx < total; idx++) {
    div.push(
      <PaginationItem
        active={current - 1 === idx}
        onClick={() => changeOffset(idx)}
      >
        <PaginationLink>{idx + 1}</PaginationLink>
      </PaginationItem>
    );
  }

  div.push(
    <PaginationItem
      onClick={() => changeOffset(current < total ? current : total - 1)}
    >
      <PaginationLink next />
    </PaginationItem>
  );
  div.push(
    <PaginationItem onClick={() => changeOffset(total - 1)}>
      <PaginationLink last />
    </PaginationItem>
  );

  return div;
}

export function PaginationProvider({ children, getData }) {
  const reducer = (state, action) => {
    switch (action.type) {
      case "update":
        return {
          ...state,
          [action.name]: action.value,
        };
      case "update-filters":
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.name]: action.value,
          },
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    items: [],
    offset: 0,
    total: 0,
    limit: 30,
    filters: {},
  });

  const getQueryString = useCallback(() => {
    const { filters, limit, offset } = state;

    return new URLSearchParams({ ...filters, limit, offset });
  }, [state]);

  const update = ({ items, total, limit }) => {
    // prevent adding or removing other data
    if (items) {
      dispatch({ type: "update", name: "items", value: items });
    }

    if (total) {
      dispatch({ type: "update", name: "total", value: total });
    }

    if (limit) {
      dispatch({ type: "update", name: "limit", value: limit });
    }
  };

  const fetchItems = async () => {
    const data = await getData(getQueryString());

    if (!data) return;

    const { items, total } = data;

    update({ items, total });
  };

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.limit, state.offset]);

  return (
    <PaginationContext.Provider value={[state, dispatch, fetchItems]}>
      {children}
    </PaginationContext.Provider>
  );
}
