import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Alert, Button, Container } from "reactstrap";

class Forbidden extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: false,
    };
  }

  async componentDidMount() {
    console.log("hola");
    const obj = JSON.parse(localStorage.getItem("authUser"));
    await this.setState({
      message: obj
        ? "Usted no está autorizado para acceder a esta página."
        : "Usted necesita iniciar sesion para acceder a esta página.",
    });
    console.log(this.state.message);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              Karubag | Reciclaje de Residuos Orgánicos e Inorgánicos |
              Forbidden
            </title>
          </MetaTags>
          <Container fluid>
            <h4>Accesso no autorizado</h4>
            <Alert color="danger">{this.state.message}</Alert>
            <Button color="primary" href="/">
              Volver a inicio
            </Button>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Forbidden;
