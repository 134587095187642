import React, { Component } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";

import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { AvForm } from "availity-reactstrap-validation";

// actions
import { apiError, loginUser, socialLogin } from "../../store/actions";

import logoPrincipal from "../../assets/Piloto_Go/Images/pilotoGO_logo_2.png";
import logoSecondary from "../../assets/Piloto_Go/Images/logo_login.png";

//Helpers
import { post } from "../../helpers/ApiHelper";
import EmailInput from "components/Form/EmailInput";
import PasswordInput from "components/Form/PasswordInputLogin";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_data: [],
      loading: true,
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  async handleValidSubmit(event, values) {
    event.preventDefault();
    let data = {
      email: this.state.form_data.email,
      password: this.state.form_data.password,
    };
    const response = await post("login", data);
    console.log(response.data);

    if (response.status === 200) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("authUser", JSON.stringify(response.data.user));
      window.location = "/";
    } else {
      console.log(response.message);
      this.setState({
        errorMessage: response.message,
      });
    }
  }

  async componentDidMount() {
    const token = await JSON.parse(localStorage.getItem("token"));
    const auth = await JSON.parse(localStorage.getItem("authUser"));
    console.log(token);
    if (token !== null && auth !== null) {
      console.log("hola");
      window.location.href = "/";
    }
    // let user = JSON.parse(localStorage.getItem("user"));
    // await this.setState({ user: user loading: false });
    this.props.apiError("");
  }

  handleChangeTextInput = async (event) => {
    await this.setState((state) => ({
      form_data: {
        ...state.form_data,
        [event.target.name]: event.target.value,
      },
    }));
  };

  handleCheckbox = (event) => {
    console.log(event.target.checked);
    this.setState((state) => ({
      form_data: {
        ...state.form_data,
        [event.target.name]: event.target.checked,
      },
    }));
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content login">
          <MetaTags>
            <title>{process.env.REACT_APP_PAGE_TITLE} | Login</title>
          </MetaTags>
          <Container fluid>
            <div className="home-btn">
              <Link to="/" className="text-dark">
                <i className="bx bx-home h2" />
              </Link>
            </div>
            <div className="account-pages">
              <Row className="justify-content-center">
                <Col md={8} lg={6} className="col-xxl-4">
                  <Card className="overflow-hidden login_fondo">
                    <section className="bg-login-footer p-3 d-flex flex-column flex-md-row gap-3">
                      <img
                        src={logoPrincipal}
                        className="img-principal-login py-3"
                        alt="pilotogo almagro logo"
                      />
                      <div className="vr d-none d-md-block" />
                      <hr className="hr d-md-none" />
                      <div className="text-white fs-5 text-center py-3">
                        Nuestro objetivo es{" "}
                        <strong>acelerar los testeos y validaciones</strong> de
                        productos y nuevos diseños
                      </div>
                    </section>
                    <section className="bg-black text-white text-center p-1">
                      Bienvenido, en PILOTO GO Online podrás conocer los
                      elementos validados por Calidad Almagro de manera simple
                    </section>
                    <CardBody className="">
                      <div className="auth-logo">
                        <Link to="/" className="auth-logo-light">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              {/* <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            /> */}
                            </span>
                          </div>
                        </Link>
                        {/* <Link to="/" className="auth-logo-dark"> */}
                        {/*   <div className="avatar-md profile-user-wid mb-4"> */}
                        {/*     <span */}
                        {/*       style={{ background: "#7cac47" }} */}
                        {/*       className="avatar-title rounded-circle" */}
                        {/*     > */}
                        {/*       <img */}
                        {/*         src={logo} */}
                        {/*         alt="" */}
                        {/*         className="rounded-circle" */}
                        {/*         height="72" */}
                        {/*       /> */}
                        {/*     </span> */}
                        {/*   </div> */}
                        {/* </Link> */}
                      </div>
                      <div className="p-2">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={this.handleValidSubmit}
                        >
                          <div className="mb-3 text-nowrap">
                            <EmailInput
                              user={this.state.user}
                              handleChangeTextInput={(e) =>
                                this.handleChangeTextInput(e)
                              }
                            />
                          </div>
                          <div className="mb-3 text-nowrap">
                            <PasswordInput
                              user={this.state.user}
                              handleChangeTextInput={(e) =>
                                this.handleChangeTextInput(e)
                              }
                            />
                          </div>
                          {/* <div className="form-check">
                          <input
                            type="checkbox"
                            checked={
                              this.state.user ? this.state.user.check : false
                            }
                            className="form-check-input"
                            id="remember_me"
                            name="remember_me"
                            onClick={e => this.handleCheckbox(e)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="remember_me"
                          >
                            Recordar usuario
                          </label>
                        </div> */}
                          {this.state.errorMessage && (
                            <Alert color="danger">
                              {this.state.errorMessage}
                            </Alert>
                          )}
                          <div className="mt-3 d-grid">
                            <button
                              className="btn bg-login-footer text-white btn-block"
                              type="submit"
                            >
                              Ingresar
                            </button>
                          </div>
                          <div className="mt-4 text-center">
                            <Link
                              to="/forgot-password"
                              className="btn"
                              id="login_forgotpassword"
                            >
                              <i className="mdi mdi-lock me-1" /> ¿Olvidó su
                              contraseña?
                            </Link>
                          </div>
                        </AvForm>
                      </div>
                      <section className="text-center vstack">
                        <small className="team">
                          <p>
                            Equipo:
                            <br />
                            Patricio Campos, Álvaro Labbé, Cristián Arias,
                            Carolina Werth,
                            <br />
                            Francisca González, Gonzalo Puelma, Oscar Cornejo,
                            Pablo Urzúa
                          </p>
                        </small>
                        <div className="vstack">
                          <div className="fs-6">
                            Proyecto incubado en el programa de innovación
                          </div>
                          <div>
                            <img
                              className="img-secondary-login"
                              src={logoSecondary}
                              alt="almagro"
                            />
                          </div>
                          <div className="login-small-text">
                            MÁS IDEAS &#8226; MÁS COLABORACIÓN &#8226; MÁS
                            INNOVACIÓN
                          </div>
                        </div>
                        <p className="mt-3 mb-0 usercode-brand">
                          Hecho por <a href="https://usercode.cl/">UserCode</a>.
                        </p>
                      </section>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);
