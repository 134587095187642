import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isNumber } from "lodash";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  ModalFooter,
} from "reactstrap";
import { Table } from "../../../Table/";
import { get, put } from "helpers/ApiHelper";

const Content = () => {
  const [data, setData] = useState([]);
  const [dataActive, setDataActive] = useState([]);
  const [dataInactive, setDataInactive] = useState([]);
  const [update, setUpdate] = useState(0);

  const [showModalActivate, setShowModalActivate] = useState(false);
  const [showModalDeactivate, setShowModalDeactivate] = useState(false);
  const [modalId, setModalId] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let request;

      request = await get("works?status=3");
      setData(request.status === 200 ? request.data : []);

      request = await get("works?status=1");
      setDataActive(request.status === 200 ? request.data : []);

      request = await get("works?status=2");
      setDataInactive(request.status === 200 ? request.data : []);
    };

    fetchData();
  }, [update]);

  const formatButtons = (_cell, row) => {
    return (
      <Row xs={1} md={2} className="g-1 align-items-start flex-nowrap">
        <Col className="d-grid">
          <Button
            size="sm"
            color="success"
            onClick={() => toggleModalActivate(row.id)}
          >
            Activar
          </Button>
        </Col>
        <Col className="d-grid">
          <Button
            size="sm"
            color="danger"
            onClick={() => toggleModalDeactivate(row.id)}
          >
            Rechazar
          </Button>
        </Col>
      </Row>
    );
  };

  const columns = [
    {
      dataField: "id",
      customType: "number",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Nombre",
      customType: "string",
    },
    {
      dataField: "code",
      text: "Código",
      customType: "string",
    },
    {
      dataField: "actions",
      text: "Acciones",
      formatter: formatButtons,
    },
  ];

  const toggleModalActivate = (id) => {
    setShowModalActivate((prev) => !prev);
    setModalId(isNumber(id) ? id : 0);
    setTimeout(() => setErrorMessage(""), 500);
  };

  const toggleModalDeactivate = (id) => {
    setShowModalDeactivate((prev) => !prev);
    setModalId(isNumber(id) ? id : 0);
    setTimeout(() => setErrorMessage(""), 500);
  };

  const activateFunction = async () => {
    const response = await put("works/activate", { works: [{ id: modalId }] });

    if (response.status !== 200) {
      setErrorMessage(response.message);
      setShowModalActivate(true);
      return;
    }

    setUpdate(new Date().getTime());
    toggleModalActivate();
  };

  const deactivateFunction = async () => {
    const response = await put("works/deactivate", {
      works: [{ id: modalId }],
    });

    if (response.status !== 200) {
      setErrorMessage(response.message);
      setShowModalDeactivate(true);
      return;
    }

    setUpdate(new Date().getTime());
    toggleModalDeactivate();
  };

  return (
    <React.Fragment>
      <Modal centered toggle={toggleModalActivate} isOpen={showModalActivate}>
        <ModalHeader className="justify-content-center">
          ¿Esta seguro?
        </ModalHeader>
        <ModalBody className="">
          Esta operación puede tomar unos segundos.
          {errorMessage && (
            <div className="mt-3">
              <Alert color="danger">{errorMessage}</Alert>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <section className="w-100 hstack justify-content-around">
            <div className="d-grid">
              <Button size="lg" onClick={toggleModalActivate}>
                Cancelar
              </Button>
            </div>
            <div className="d-grid">
              <Button color="info" size="lg" onClick={activateFunction}>
                Aceptar
              </Button>
            </div>
          </section>
        </ModalFooter>
      </Modal>

      <Modal
        centered
        toggle={toggleModalDeactivate}
        isOpen={showModalDeactivate}
      >
        <ModalHeader className="justify-content-center">
          ¿Esta seguro?
        </ModalHeader>
        <ModalBody className="">
          Esta operación no puede deshacerse.
          {errorMessage && (
            <div className="mt-3">
              <Alert color="danger">{errorMessage}</Alert>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <section className="w-100 hstack justify-content-around">
            <div className="d-grid">
              <Button size="lg" onClick={toggleModalDeactivate}>
                Cancelar
              </Button>
            </div>
            <div className="d-grid">
              <Button color="info" size="lg" onClick={deactivateFunction}>
                Aceptar
              </Button>
            </div>
          </section>
        </ModalFooter>
      </Modal>

      <Row className="align-items-center mb-3">
        <Col sm={4} lx="auto" className="me-auto">
          <h4 className="text-white">Proyectos / Listar</h4>
        </Col>
        <Col sm={4} lx={2}>
          <Link to="/works/toggle" className="d-grid">
            <Button color="info" size="lg">
              Seleccionar proyectos para filtros
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="p-2">
        <form>
          <fieldset>
            <h3>Proyectos En Espera</h3>
            <Table headers={columns} data={data} noFieldset />
          </fieldset>
        </form>
      </Row>
      <Row className="p-2">
        <form>
          <fieldset>
            <h3>Proyectos Aceptados</h3>
            <Table
              headers={columns.filter((item) => item.dataField !== "actions")}
              data={dataActive}
              noFieldset
            />
          </fieldset>
        </form>
      </Row>

      <Row className="p-2">
        <form>
          <fieldset>
            <h3>Proyectos Rechazados</h3>
            <Table
              headers={columns.filter((item) => item.dataField !== "actions")}
              data={dataInactive}
              noFieldset
            />
          </fieldset>
        </form>
      </Row>
    </React.Fragment>
  );
};

export default Content;
