import React, { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert, Input, Label } from "reactstrap";
import { get, put } from "helpers/ApiHelper";
import { Link } from "react-router-dom";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";

const Content = () => {
  const see = window.location.pathname.includes("/see/");

  const [data, setData] = useState([]);
  const [requestMessage, setRequestMessage] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await get("works?status=1");
    setData(response.data);
  };

  const handleInput = (idx) => {
    setData((prevData) => {
      prevData[idx].show_in_filter = !prevData[idx].show_in_filter;
      return [...prevData];
    });
  };

  const sendForm = async () => {
    setRequestMessage("");
    console.log(data);
    const response = await put("works", { works: data });

    setErrors(response.status !== 200 ? response.data : false);
    setRequestMessage(response.message);
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>
          r<h4 className="text-white">Proyectos Iconstruye / Editar</h4>;
        </Col>
      </Row>
      <Row className="p-2">
        <AvForm onValidSubmit={sendForm}>
          <fieldset>
            <h3>Lista de proyectos</h3>
            <Row xs={2} className="mt-4">
              {data.map((item, idx) => (
                <Col key={idx} className="mb-2">
                  <AvGroup check inline>
                    <Input
                      name="works"
                      type="checkbox"
                      checked={item.show_in_filter}
                      onChange={() => handleInput(idx)}
                    />
                    <Label check>{item.name}</Label>
                  </AvGroup>
                </Col>
              ))}
            </Row>
            {requestMessage && (
              <Row>
                <Alert
                  color={Object.keys(errors).length ? "danger" : "success"}
                >
                  {requestMessage}
                </Alert>
              </Row>
            )}
            <Row>
              <Col className="d-grid">
                <Link to="/works" className="d-grid">
                  <Button color="info" size="lg">
                    Volver a la lista
                  </Button>
                </Link>
              </Col>
              {!see && (
                <Col className="d-grid">
                  <Button type="submit" size="lg" color="info">
                    Guardar
                  </Button>
                </Col>
              )}
            </Row>
          </fieldset>
        </AvForm>
      </Row>
    </React.Fragment>
  );
};

export default Content;
