import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import { Table } from "../../../Table/";
import { get, del } from "helpers/ApiHelper";

const Content = () => {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const request = await get("models");
      setData(request.status === 200 ? request.data : []);
    };

    fetchData();
  }, [update]);

  const columns = [
    {
      dataField: "id",
      text: "Product ID",
      customType: "number",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Nombre",
      customType: "string",
    },
    {
      dataField: "brand.name",
      text: "Marca",
      customType: "string",
    },
    {
      dataField: "description",
      text: "Descripción",
      customType: "string",
    },
    {
      dataField: "actions",
      text: "Acciones",
      customType: "actions-delete",
    },
  ];

  const deleteFunction = async (id) => {
    const response = await del("models/" + id);

    if (response.status !== 200) return response.data.id;

    setUpdate(new Date().getTime());
  };

  return (
    <React.Fragment>
      <Row className="align-items-center mb-3">
        <Col sm={4} lx="auto" className="me-auto">
          <h4 className="text-white">Modelo / Listar</h4>
        </Col>
        <Col sm={4} lx={2}>
          <Link to="/model/create" className="d-grid">
            <Button color="info" size="lg">
              Crear Modelo
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="p-2">
        <Table headers={columns} data={data} delete={deleteFunction} />
      </Row>
    </React.Fragment>
  );
};

export default Content;
