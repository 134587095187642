import React, { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert } from "reactstrap";
import { TextAreaInput, TextInput } from "../../../Form/";
import { get, post, put } from "helpers/ApiHelper";
import { useParams, Link } from "react-router-dom";

const Content = () => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const [data, setData] = useState({});
  const [requestMessage, setRequestMessage] = useState("");
  const [requestOk, setRequestOk] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    const response = await get("productstatuses/" + id);
    setData(response.data);
    setRequestMessage("");
  };

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name.replace(/_/g, "")]: value }));
  };

  const sendForm = async () => {
    setRequestMessage("");
    console.table(data);
    const response = id
      ? await put("productstatuses/" + id, data)
      : await post("productstatuses", data);

    console.log(response.data);

    setRequestOk(response.status === 200);
    setErrors(response.status !== 200 ? response.data : {});
    setRequestMessage(response.message);
  };

  const renderTitle = () => {
    let text;
    if (see) {
      text = "Ver";
    } else if (id) {
      text = "Editar";
    } else {
      text = "Crear";
    }

    return <h4 className="text-white">Estado / {text}</h4>;
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>{renderTitle()}</Col>
      </Row>
      <Row className="p-2">
        <AvForm onValidSubmit={sendForm}>
          <fieldset>
            <h3>Detalle estado</h3>
            <TextInput
              // HACK: for some reason set name="status" make input render weird, remove underscore in handleInput
              name="_status"
              label="Estado"
              placeholder="-- Ingrese estado --"
              value={data.status}
              onChange={handleInput}
              error={errors.status}
              disabled={see}
            />
            <TextAreaInput
              name="description"
              label="Descripción"
              placeholder="-- Ingrese descripción --"
              value={data.description}
              onChange={handleInput}
              error={errors.description}
              disabled={see}
              required={false}
            />
            {requestMessage && (
              <Row>
                <Alert color={requestOk ? "success" : "danger"}>
                  {requestMessage}
                </Alert>
              </Row>
            )}
            <Row>
              <Col className="d-grid">
                <Link to="/state" className="d-grid">
                  <Button color="info" size="lg">
                    Volver a la lista
                  </Button>
                </Link>
              </Col>
              {!see && (
                <Col className="d-grid">
                  <Button type="submit" size="lg" color="info">
                    Guardar
                  </Button>
                </Col>
              )}
            </Row>
          </fieldset>
        </AvForm>
      </Row>
    </React.Fragment>
  );
};

export default Content;
