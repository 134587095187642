/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Label, FormFeedback } from "reactstrap";
import { AvGroup } from "availity-reactstrap-validation";
import { get } from "../../helpers/ApiHelper";

const useFetchSelect = (endpoint, update) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // return if update exist and is a falsy value
    if (update !== undefined && !update) return;

    let isActive = true;

    get(endpoint).then((response) => {
      if (response.status !== 200) return;

      const sortedData = response.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      if (!isActive) return;

      setData(
        sortedData.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        }))
      );
    });

    return () => (isActive = false);
  }, [endpoint, update]);

  return data;
};

const Wrapper = (props) => {
  return props.noRow ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : (
    <AvGroup className="row align-items-center">{props.children}</AvGroup>
  );
};

function SelectInput(props) {
  const onChange = (e) => {
    let value;

    if (Array.isArray(e)) {
      value = e.map((item) => item.value);
    } else if (e === null) {
      value = null;
    } else {
      value = e.value;
    }

    props.onChange(props.name, value);
  };

  const getValue = () => {
    if (!props.value) return null;

    if (props.isMultiple) {
      return props.options.filter((item) => props.value?.includes(item.value));
    }

    return props.options.find((item) => item.value === props.value);
  };

  return (
    <React.Fragment>
      <Wrapper {...props}>
        {!props.noLabel && (
          <Col sm={2}>
            <Label className="mb-0">{props.label}</Label>
          </Col>
        )}
        <Col>
          <Select
            className={
              props.error ? "border border-danger rounded is-invalid" : null
            }
            name={props.name}
            onChange={onChange}
            options={props.options}
            value={getValue()}
            isClearable
            isMulti={props.isMultiple}
            placeholder={props.placeholder || "Ingrese un valor..."}
            isDisabled={props.disabled}
          />
          <FormFeedback invalid>
            {props.error || "Este campo es necesario"}
          </FormFeedback>
        </Col>
      </Wrapper>
    </React.Fragment>
  );
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  noRow: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isMultiple: PropTypes.bool,
};

export default SelectInput;
export { useFetchSelect };
