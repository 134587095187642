import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { get } from "helpers/ApiHelper";

import Iconstruye from "./Forms/Iconstruye";
import ProductData from "./Forms/ProductData";
import RequestState from "./Forms/RequestState";
import ProductResult from "./Forms/ProductResult";
import Clasification from "./Forms/Clasification";
import Odi from "./Forms/Odi";

function FormProducts() {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");
  const [data, setData] = useState({});

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    const response = await get("products/" + id);
    setData(response.data);
  };

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const renderTitle = () => {
    let text;
    if (see) {
      text = "Ver";
    } else if (id) {
      text = "Editar";
    } else {
      text = "Crear";
    }

    return <h4 className="text-white">Producto Generico / {text}</h4>;
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>{renderTitle()}</Col>
      </Row>
      <Row className="p-2">
        <ProductData />
        {id && (
          <>
            <Clasification data={data} handleInput={handleInput} />
            <Odi />
          </>
        )}
      </Row>
    </React.Fragment>
  );
}

export default FormProducts;
