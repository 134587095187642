import React from "react";
import NumberFormat from "react-number-format";
import {
  Col,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
} from "reactstrap";
import Popover from "./Popover";
// import Stars from "./Stars";
import { API_URL } from "helpers/ApiHelper";
import { checkRole } from "helpers/getUser";

import infoImg from "assets/Piloto_Go/Images/Icono i Piloto Go_10.png";

const ProductCard = ({ id, product, toggleModal, typereviews }) => {
  const formatProjects = () => {
    // Sort most recent first
    let works = product.icomponent.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );

    // get all the works from each icomponent
    works = works.reduce(
      (total, item) => total.concat(item.work.map((item) => item.name)),
      []
    );

    // Remove duplicates
    works = [...new Set(works)];

    // Get the first 3 items
    works = works.slice(0, 3);

    // Join as a string
    works = works.join(" - ");

    return works;
  };

  const formatSubtitle = () => {
    const model = product.model?.brand.name || "";
    const suppliers = product.model.brand.suppliers
      .map((item) => item.name)
      .join(", ");

    return `${model} - ${suppliers}`;
  };

  const formatCode = () => {
    const codes = product.icomponent.map((item) => item.icode);

    if (codes.length === 0) {
      return " - ";
    } else if (codes.length === 1) {
      return codes[0];
    } else {
      return "Varios";
    }
  };

  return (
    <Col>
      <Card className="h-100 product-card shadow">
        <div className="thumbnail-scale overflow-visible">
          <section className="mx-4 mt-4 d-flex flex-wrap gap-2">
            <Popover
              status={product.productresult?.name || "Nuevo"}
              values={product.typereviews.map((item) => item.id)}
              typereviews={typereviews}
              id={id}
            />

            {product.segments.map((item, idx) => (
              <Button
                className="opacity-100"
                color="dark"
                size="sm"
                disabled
                key={idx}
              >
                {item.name}
              </Button>
            ))}
          </section>
          <img alt="Item img" src={API_URL + product.images[0]?.link} />
        </div>
        <CardBody className="position-relative">
          <div className="position-absolute bottom-0 end-0 m-2 d-flex gap-3">
            {checkRole(["Administrador", "ODI", "Calidad"]) && (
              <Button color="dark" href={`product/edit/${product.id}`}>
                Editar
              </Button>
            )}
            <Button className="" color="info" onClick={() => toggleModal(id)}>
              Ficha
            </Button>
          </div>
          <CardSubtitle className="d-flex justify-content-between">
            <div>{formatSubtitle()}</div>
            <div>COD: {product.code_supplier}</div>
          </CardSubtitle>
          <CardTitle>{product.name}</CardTitle>
          <CardSubtitle>{product.details}</CardSubtitle>
        </CardBody>
        <section className="px-4 bg-secondary text-white">
          <div>Código iConstruye: {formatCode()}</div>
        </section>
        <section className="px-4 bg-dark text-white">
          <div>
            Proyectos:
            {" " + formatProjects()}
          </div>
        </section>
        <div className="rounded-border-bottom py-2 px-4 fs-4 fw-bold bg-info text-white hstack align-items-center gap-3">
          {product.price_uf !== 0 && (
            <NumberFormat
              className=""
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              prefix="UF "
              displayType="text"
              value={product.price_uf || 0}
            />
          )}
          {/* <span className="fs-3">-</span> */}
          <NumberFormat
            className=""
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={0}
            prefix="CLP "
            displayType="text"
            value={product.price_clp || product.price_list || 0}
          />
          <div className="tooltip-hover h-100 d-flex align-items-center">
            <img src={infoImg} height="26px" alt="info price" />
            <div
              className="fs-6 tooltiptext-hover vstack gap-2 justify-content-between bg-dark text-nowrap"
              style={{
                top: "-75%",
                right: "100%",
                margin: "0 .25rem",
                height: "auto",
                width: "fit-content",
                padding: ".25rem",
              }}
            >
              <div>
                {product.price_uf ? "Ultima compra" : "Precio de referencia"}
              </div>
              <div>
                {product.icomponent.length > 1
                  ? "Varios productos"
                  : "Producto único"}
              </div>
              <div>
                {product.icomponent.every(
                  (item) => item.original_currency === "CLP"
                )
                  ? "Precio convertido a CLP"
                  : "Precio Original"}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default ProductCard;
