import React, { useState, useEffect } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert } from "reactstrap";
import { SelectInput, TextAreaInput, TextInput } from "../../../Form/";
import { get } from "helpers/ApiHelper";
import { useParams, Link } from "react-router-dom";
import { useFetchSelect, useForm } from "components/Form";

const Content = () => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const [data, setData] = useState({});

  const { requestMessage, errors, sendForm } = useForm();

  const elements = useFetchSelect("elements");

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    const response = await get("components/" + id);
    setData(response.data);
  };

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const renderTitle = () => {
    let text;
    if (see) {
      text = "Ver";
    } else if (id) {
      text = "Editar";
    } else {
      text = "Crear";
    }

    return <h4 className="text-white">Componente / {text}</h4>;
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>{renderTitle()}</Col>
      </Row>
      <Row className="p-2">
        <AvForm onValidSubmit={() => sendForm("components", data, id)}>
          <fieldset>
            <h3>Detalle Componente</h3>
            <TextInput
              name="name"
              label="Componente"
              placeholder="-- Ingrese componente --"
              value={data.name}
              onChange={handleInput}
              error={errors.name}
              disabled={see}
            />
            <SelectInput
              name="element_id"
              label="Elemento"
              placeholder="-- Seleccione elemento --"
              options={elements}
              value={data.element_id}
              onChange={handleInput}
              error={errors.element_id}
              disabled={see}
            />
            <TextAreaInput
              name="description"
              label="Descripción"
              placeholder="-- Ingrese descripción --"
              value={data.description}
              onChange={handleInput}
              disabled={see}
              required={false}
            />
            {requestMessage && (
              <Row>
                <Alert color={errors ? "danger" : "success"}>
                  {requestMessage}
                </Alert>
              </Row>
            )}
            <Row>
              <Col>
                <Link to="/component" className="d-grid">
                  <Button color="info" size="lg">
                    Volver a la lista
                  </Button>
                </Link>
              </Col>
              {!see && (
                <Col className="d-grid">
                  <Button type="submit" size="lg" color="info">
                    Guardar
                  </Button>
                </Col>
              )}
            </Row>
          </fieldset>
        </AvForm>
      </Row>
    </React.Fragment>
  );
};

export default Content;
