import React from "react";
import NumberFormat from "react-number-format";

class CustomInputNumber extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: null };
  }

  getValue() {
    return this.state.value;
  }

  render() {
    const { value, onUpdate, ...rest } = this.props;
    return (
      <NumberFormat
        {...rest}
        thousandSeparator="."
        decimalSeparator=","
        isNumericString
        prefix="UF "
        decimalScale={3}
        fixedDecimalScale
        onValueChange={(e) => this.setState({ value: e.value })}
      />
    );
  }
}

export default CustomInputNumber;
