import React, { useState, useEffect } from "react";
import { Row, Col, Button, Alert } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { SelectInput } from "components/Form/";
import { useParams, Link } from "react-router-dom";
import { useFetchSelect, useForm } from "components/Form";
import { get } from "helpers/ApiHelper";

const Clasification = ({ data, handleInput }) => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const [helper, setHelper] = useState({});

  const families = useFetchSelect("families");
  const [classes, setClasses] = useState([]);
  const [subclasses, setSubclasses] = useState([]);
  const [elements, setElements] = useState([]);
  const [components, setComponents] = useState([]);

  const fetchData = (endpoint, setter) => {
    get(endpoint).then((response) => {
      if (response.status !== 200) {
        setter([]);
        return;
      }
      const sortedData = response.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setter(sortedData.map((item) => ({ label: item.name, value: item.id })));
    });
  };

  useEffect(() => {
    if (!data.component_id) return;

    get(`components/${data.component_id}?full=true`).then((response) => {
      if (response.status !== 200) return;

      const { data } = response;

      setHelper({
        family: data.element?.subclass?.class?.family?.id,
        class: data.element?.subclass?.class.id,
        subclass: data.element?.subclass?.id,
        element: data.element?.id,
      });
    });
  }, [data.component_id]);

  const { requestMessage, errors, sendForm } = useForm();

  useEffect(() => {
    if (!helper.family) return;
    fetchData("classes?family_id=" + helper.family, setClasses);
  }, [helper.family]);

  useEffect(() => {
    if (!helper.class) return;
    fetchData("subclasses?class_id=" + helper.class, setSubclasses);
  }, [helper.class]);

  useEffect(() => {
    if (!helper.subclass) return;
    fetchData("elements?subclass_id=" + helper.subclass, setElements);
  }, [helper.subclass]);

  useEffect(() => {
    if (!helper.element) return;
    fetchData("components?element_id=" + helper.element, setComponents);
  }, [helper.element]);

  const handleHelper = (name, value) => {
    setHelper((prevData) => {
      switch (name) {
        case "family":
          prevData.family = value;
          delete prevData.class;
          delete prevData.subclass;
          delete prevData.element;
          delete prevData.component;
          break;
        case "class":
          prevData.class = value;
          delete prevData.subclass;
          delete prevData.element;
          delete prevData.component;
          break;
        case "subclass":
          prevData.subclass = value;
          delete prevData.element;
          delete prevData.component;
          break;
        case "element":
          prevData.element = value;
          delete prevData.component;
          break;
        case "component":
          prevData.component = value;
          break;
        default:
          break;
      }
      return { ...prevData };
    });

    handleInput("component_id", null);
  };

  return (
    <AvForm onValidSubmit={() => sendForm("products-clasification", data, id)}>
      <fieldset>
        <h3 className="mb-4 ">Clasificación calidad - Postventa</h3>
        <SelectInput
          name="family"
          label="Familia"
          placeholder="-- Seleccione familia --"
          options={families}
          onChange={handleHelper}
          value={helper.family}
          error={""}
          disabled={see}
        />
        <SelectInput
          name="class"
          label="Clase"
          placeholder="-- Seleccione clase --"
          options={classes}
          onChange={handleHelper}
          value={helper.class}
          error={""}
          disabled={see}
        />
        <SelectInput
          name="subclass"
          label="Subclase"
          placeholder="-- Seleccione subclase --"
          options={subclasses}
          onChange={handleHelper}
          value={helper.subclass}
          error={""}
          disabled={see}
        />
        <SelectInput
          name="element"
          label="Elemento"
          placeholder="-- Seleccione elemento --"
          options={elements}
          onChange={handleHelper}
          value={helper.element}
          error={""}
          disabled={see}
        />
        <SelectInput
          name="component_id"
          label="Componente"
          placeholder="-- Seleccione estado --"
          options={components}
          onChange={handleInput}
          value={data.component_id}
          error={errors.component_id}
          disabled={see}
        />
        {requestMessage && (
          <Row>
            <Alert color={errors ? "danger" : "success"}>
              {requestMessage}
            </Alert>
          </Row>
        )}

        <Row>
          <Col className="d-grid">
            <Link to="/generic-product" className="d-grid">
              <Button color="info" size="lg">
                Volver a la lista
              </Button>
            </Link>
          </Col>
          {!see && (
            <Col className="d-grid">
              <Button type="submit" size="lg" color="info">
                Guardar
              </Button>
            </Col>
          )}
        </Row>
      </fieldset>
    </AvForm>
  );
};

export default Clasification;
