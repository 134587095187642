import React, { useCallback, useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert, FormGroup, Label } from "reactstrap";
import {
  TextInput,
  TextAreaInput,
  SelectInput,
  FileInput,
  DateInput,
} from "../../Form/";
import { get, API_URL } from "helpers/ApiHelper";
import { useForm, useFetchSelect } from "components/Form";
import { useParams, Link, useHistory } from "react-router-dom";
import FileItem from "./FileInputCard";

const Content = () => {
  const { id } = useParams();
  const history = useHistory();
  const see = window.location.pathname.includes("/see/");

  const { requestMessage, errors, sendForm } = useForm();
  const types = useFetchSelect("prototypetypes");
  const [data, setData] = useState({});
  const [version, setVersion] = useState(null);

  const fetchData = useCallback(async (id, version = 0) => {
    const response = await get("prototypes/" + id);

    if (!response) return;

    const { data } = response;

    data.prototypeversions.forEach((item) => {
      item.images = item.prototypeimages?.map((img) => ({
        ...img,
        preview: API_URL + img.link,
      }));
      item.files = item.prototypefiles;
    });

    if (version === 0) {
      setVersion(
        data.prototypeversions.find((item) => item.show_in_catalog) ??
          data.prototypeversions.at(0)
      );
    } else {
      setVersion(
        data.prototypeversions.at(version) ?? data.prototypeversions.at(0)
      );
    }

    setData(data);
  }, []);

  useEffect(() => {
    if (id) fetchData(id);
  }, [id, fetchData]);

  const encodeFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleVersionInput = (name, value) => {
    setVersion((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddImages = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    const hasImages =
      Array.isArray(version.images) &&
      version.images.length > 0 &&
      !version.images.every((img) => img.deleted);

    const images = await Promise.all(
      files.map(async (img, idx) => {
        const imgData = await encodeFile(img);
        const type = img.type.split("/")[1];
        return {
          ...img,
          type,
          data: imgData,
          new: true,
          principal: !hasImages && idx === 0 ? 1 : 0,
        };
      })
    );

    setVersion((prevData) => ({
      ...prevData,
      images: prevData.images ? prevData.images.concat(images) : images,
    }));
  };

  const handleRemoveImages = (removed_idx) => {
    setVersion((prevData) => {
      const img = prevData.images[removed_idx];

      if (img.principal && prevData.images.length) {
        img.principal = 0;
        prevData.images[0].principal = 1;
      }

      if (img.new) {
        // Local file, Remove from the array
        prevData.images = prevData.images.filter(
          (_, idx) => idx !== removed_idx
        );
      } else {
        // Remote file, Set flag to remove from the back
        img.deleted = true;
      }

      return {
        ...prevData,
      };
    });
  };

  const handleAcceptFile = async (files, idx) => {
    const fileData = await encodeFile(files[0]);
    const type = files[0].type.split("/")[1];
    const newFile = { type, data: fileData, new: true };
    setVersion((prevData) => {
      prevData.files[idx] = {
        ...prevData.files[idx],
        ...newFile,
      };

      return { ...prevData };
    });
  };

  const handleChangeFileInput = (idx, name, value) => {
    setVersion((prevData) => {
      prevData.files[idx] = {
        ...prevData.files[idx],
        [name]: value,
      };

      return { ...prevData };
    });
  };

  const handleDeleteFile = (idx) => {
    setVersion((prevData) => {
      prevData.files[idx] = {
        ...prevData.files[idx],
        deleted: true,
        // new: false,
      };

      return { ...prevData };
    });
  };

  const handleAddFile = () => {
    setVersion((prevData) => {
      prevData.files = [
        ...prevData.files,
        { new: true, name: "", description: "", link: "" },
      ];

      return { ...prevData };
    });
  };

  const handleAddVersion = async () => {
    const response = await sendForm("prototypes/versions", {
      prototype_id: id,
    });

    if (!response) return;

    fetchData(id, -1);
  };

  const handleSelectPrincipal = (idx) => {
    setVersion((prevData) => {
      prevData.images = prevData.images.map((img) => ({
        ...img,
        principal: 0,
      }));

      prevData.images[idx].principal = 1;

      return { ...prevData };
    });
  };

  const handleSubmit = async (show_in_catalog = false) => {
    let response;
    if (id) {
      delete version.show_in_catalog;
      response = await sendForm(
        "prototypes/versions",
        {
          ...version,
          ...(show_in_catalog === true && { show_in_catalog }),
          name: data.name,
        },
        version.id
      );
    } else {
      response = await sendForm("prototypes", data);
    }

    if (!response) return;

    if (id) {
      fetchData(id);
    } else {
      await new Promise((r) => setTimeout(r, 2000));
      history.push(`/prototypes/edit/${response.id}`);
    }
  };

  const renderTitle = () => {
    let text;
    if (see) {
      text = "Ver";
    } else if (id) {
      text = "Editar";
    } else {
      text = "Crear";
    }

    return <h4 className="text-white">Prototipo / {text}</h4>;
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>{renderTitle()}</Col>
      </Row>
      <Row className="p-2">
        <AvForm onValidSubmit={() => handleSubmit()}>
          <fieldset>
            <h3>Detalle Prototipo</h3>
            <TextInput
              name="name"
              label="Nombre"
              placeholder="-- Ingrese nombre --"
              value={data.name}
              onChange={handleInput}
              error={errors.name}
              disabled={see}
            />
            {data.prototypeversions?.length > 0 && (
              <Row className="align-items-center">
                <SelectInput
                  name="version"
                  label="Versión"
                  placeholder="-- Seleccione versión --"
                  options={
                    data.prototypeversions.map((item) => ({
                      label: item.version_number,
                      value: item.id,
                    })) ?? []
                  }
                  value={version ? version.id : null}
                  onChange={(_, value) =>
                    setVersion(
                      data.prototypeversions.find((item) => item.id === value)
                    )
                  }
                  disabled={see}
                  noRow
                />
                <Col lg="auto" className="d-grid">
                  <Button color="success" onClick={() => handleSubmit(true)}>
                    Utilizar esta versión en el catálogo
                  </Button>
                </Col>
                <Col lg="auto" className="d-grid">
                  <Button color="primary" onClick={handleAddVersion}>
                    Agregar versión
                  </Button>
                </Col>
              </Row>
            )}
            {version && (
              <>
                <fieldset>
                  <DateInput
                    name="versionDate"
                    label="Fecha"
                    placeholder="-- Ingrese Fecha --"
                    value={version.versionDate}
                    onChange={handleVersionInput}
                    error={errors.versionDate}
                    disabled={see}
                  />
                  <TextAreaInput
                    name="description"
                    label="Descripción"
                    placeholder="-- Ingrese Nombre --"
                    value={version.description}
                    onChange={handleVersionInput}
                    error={errors.description}
                    disabled={see}
                  />
                  <SelectInput
                    name="prototypetype_id"
                    label="Tipo"
                    placeholder="-- Ingrese tipo --"
                    options={types}
                    value={version.prototypetype_id}
                    onChange={handleVersionInput}
                    error={errors.prototypetype_id}
                    disabled={see}
                  />
                  <TextInput
                    name="video_url"
                    label="Video"
                    placeholder="-- Ingrese video --"
                    value={version.video_url}
                    onChange={handleVersionInput}
                    error={errors.video_url}
                    disabled={see}
                    required={false}
                  />

                  <FormGroup className="row align-items-center justify-content-between">
                    <Col xs={2}>
                      <Label>Archivos adjuntos</Label>
                    </Col>
                    <Col className="text-danger">{errors.productfiles}</Col>
                    <Col xs="3" className="d-grid">
                      <Button
                        color="btn btn-info"
                        onClick={() => handleAddFile()}
                      >
                        Agregar Documento
                      </Button>
                    </Col>
                  </FormGroup>

                  <Row className="align-items-center justify-content-between">
                    <Col xs={{ offset: 2, size: 10 }}>
                      <Row
                        xs="1"
                        xl="2"
                        className="align-items-center justify-content-between"
                      >
                        {version.files &&
                          version.files.map((item, idx) => (
                            <FileItem
                              key={idx}
                              idx={idx}
                              item={item}
                              see={see}
                              errors={errors}
                              methods={{
                                handleAcceptFile,
                                handleDeleteFile,
                                handleChangeFileInput,
                              }}
                            />
                          ))}
                      </Row>
                    </Col>
                  </Row>
                  <FileInput
                    label="Imágenes"
                    handleAddFiles={handleAddImages}
                    handleRemoveFile={handleRemoveImages}
                    handleSelectFile={handleSelectPrincipal}
                    files={version.images}
                    error={errors.images}
                    disabled={see}
                  />
                </fieldset>
              </>
            )}
            {requestMessage && (
              <Row>
                <Col>
                  <Alert
                    color={Object.keys(errors).length ? "danger" : "success"}
                  >
                    {requestMessage}
                  </Alert>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="d-grid">
                <Link to="/prototypes" className="d-grid">
                  <Button color="info" size="lg">
                    Volver a la lista
                  </Button>
                </Link>
              </Col>
              {!see && (
                <Col className="d-grid">
                  <Button type="submit" size="lg" color="info">
                    Guardar
                  </Button>
                </Col>
              )}
            </Row>
          </fieldset>
        </AvForm>
      </Row>
    </React.Fragment>
  );
};

export default Content;
