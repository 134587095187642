import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/es";
import {
  Row,
  Col,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from "reactstrap";

import ModalNotifications from "./ModalNotifications";
import NumberFormat from "react-number-format";
import TableBudgets from "./Table";
import { CustomProgressBar } from "components/ProgressBar/";
import { checkRole } from "helpers/getUser";
import { get } from "helpers/ApiHelper";
import { useForm } from "../../Form/";

import "react-step-progress-bar/styles.css";

const ModalBudget = ({ open, toggle, project_id }) => {
  moment.locale("es");
  const [data, setData] = useState();
  const [project, setProject] = useState({});
  const { requestMessage, errors, sendForm, clearMsg } = useForm();
  const [wait, setWait] = useState(true);
  const [loadind, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(0);

  const [showNotificationModal, setShowNotificationModalait] = useState(false);

  useEffect(() => {
    const fetchData = async (id) => {
      let response_proyect = await get("projects/" + id);

      const project = response_proyect.data;

      for (const [idx, item] of project.budgets.entries()) {
       let response = await get(`budgets/${item.id}/authorization`);

        project.budgets[idx].auth = response.data.resume.at(-1);
      }

      setProject(project);
      setData({
        project_id: response_proyect.data.id,
      });
      clearMsg();
    };

    if (project_id && open) {
      fetchData(project_id);
    } else {
      setProject({});
    }
    clearMsg();
  }, [project_id, open, clearMsg, updateData]);

  const sendData = async (endpoint, data, id) => {
    setLoading(true);
    await sendForm(endpoint, data, id);
    await update();
    setLoading(false);
  };

  const validateAddBudget = () => {
    const validRole = checkRole(["Administrador", "Inmobiliaria", "ODI"]);

    return project.budgets?.length < 3 && validRole;
  };

  const validateChangeNotification = () => {
    const validRole = checkRole(["Administrador", "Gerente Inmobiliaria"]);

    return validRole;
  };

  const update = async () => setUpdateData(new Date().now);

  if (!project_id || !project.id) return <></>;

  return (
    <Modal size="xl" isOpen={open} toggle={toggle} centered>
      {validateChangeNotification() && (
        <ModalNotifications
          project={project}
          open={showNotificationModal}
          toggle={() => setShowNotificationModalait((prev) => !prev)}
        />
      )}
      <ModalHeader toggle={toggle}>Presupuestos</ModalHeader>
      <ModalBody className="vstack gap-5">
        <Row className="align-items-center">
          <Col xs={6}>
            <h3>Datos Proyecto</h3>
          </Col>
        </Row>
        <Row xs={2}>
          <Col>
            <strong>Nombre: </strong>
            {project.name}
          </Col>
          <Col>
            <strong>Código: </strong>
            {project.code}
          </Col>
          <Col>
            <strong>Segmento: </strong>
            {project.segment?.name}
          </Col>
          <Col>
            <strong>Comuna: </strong>
            {project.commune?.name}
          </Col>
          <Col>
            <strong>Presupuesto: </strong>
            <NumberFormat
              thousandSeparator="."
              decimalSeparator=","
              prefix="$ "
              decimalScale={2}
              displayType="text"
              value={project.budget_amount}
            />
          </Col>
          <Col>
            <strong>Presupuesto UF: </strong>
            <NumberFormat
              thousandSeparator="."
              decimalSeparator=","
              prefix="UF "
              decimalScale={2}
              displayType="text"
              value={project.budget_amount_uf}
            />
          </Col>
          <Col>
            <strong>Tiempo de apertura: </strong>
            {moment(project.date).locale("es").fromNow()}
          </Col>
        </Row>
        <Row>
          <Col xs="6" className="d-grid">
            {validateChangeNotification() && (
              <Button
                color="primary"
                onClick={() => setShowNotificationModalait((prev) => !prev)}
              >
                Notificaciones
              </Button>
            )}
          </Col>
          <Col xs="6" className="d-grid">
            {validateAddBudget() && (
              <Button color="success" onClick={() => sendData("budgets", data)} disabled={loadind}>
                {loadind ? (
                  <>
                    <Spinner size="sm">
                      Creando presupuesto
                    </Spinner>
                    <span>
                      {' '}Creando presupuesto, Espere.
                    </span>
                  </>
                ) : (
                  "Agregar Presupuesto"
                )}
              </Button>
            )}
          </Col>
        </Row>
        {project.notificationCount === 0 && (
          <Row>
            <Col xs={6} className="text-danger text-center">
              No hay emails seleccionados para notificaciones.
            </Col>
          </Row>
        )}
        <Row>
          <TableBudgets
            data={project.budgets}
            sendForm={sendData}
            update={update}
          />
        </Row>
        {requestMessage && !wait && (
          <Row>
            <Alert color={Object.keys(errors).length ? "danger" : "success"}>
              {requestMessage}
            </Alert>
          </Row>
        )}

        <Row className="mb-3">
          <Col className="py-3 px-5">
            <CustomProgressBar
              currentStep={project.timeline.currentStep}
              steps={project.timeline.steps.map((item) => ({
                textTop: item.user,
                textBottom: item.date,
              }))}
            />
          </Col>
        </Row>

        <Row>
          <Col className="d-grid">
            <Button color="secondary" size="lg" onClick={toggle}>
              Cerrar
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ModalBudget;
