import React, { useState, useEffect } from "react";
import { SelectInput, TextAreaInput } from "components/Form/";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Button, Alert, Col } from "reactstrap";
import { useParams, Link } from "react-router-dom";
import { get } from "helpers/ApiHelper";
import { useFetchSelect, useForm } from "components/Form";
import { CheckInput } from "components/Form";
import { checkRole } from "helpers/getUser";

const RequestState = () => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const [data, setData] = useState({});

  const states = useFetchSelect("productrequests");
  const [typereviews, setTypereviews] = useState([]);
  const { requestMessage, errors, sendForm } = useForm();

  useEffect(() => {
    get("typereviews").then((response) => {
      if (response.status !== 200) return;

      const sortedData = response.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setTypereviews(
        sortedData
          .sort((a, b) => a.sortidx - b.sortidx)
          .map((item) => ({ label: item.name, value: item.id }))
      );
    });
  }, []);

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    const response = await get("products/" + id);
    setData({
      ...response.data,
      typereviews: response.data.typereviews.map(
        (item) => item.product_typereview.typereview_id
      ),
    });
  };

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleTypeReview = (_name, value) => {
    setData((prevData) => {
      prevData.typereviews = prevData.typereviews || [];

      if (prevData.typereviews.includes(value)) {
        // Already exist, remove
        prevData.typereviews = prevData.typereviews.filter(
          (item) => item !== value
        );
      } else {
        // Inlcude
        prevData.typereviews.push(value);
      }

      return {
        ...prevData,
      };
    });
  };

  if (!checkRole(["Administrador", "Calidad"])) return <></>;

  return (
    <AvForm onValidSubmit={() => sendForm("products-request", data, id)}>
      <fieldset>
        <h3 className="mb-4 ">Solicitud</h3>
        <CheckInput
          name="typereviews"
          label="Tipo de solicitud"
          inline
          content={typereviews}
          values={data.typereviews}
          onChange={handleTypeReview}
          disabled={see}
        />
        <SelectInput
          name="productrequest_id"
          label="Estado"
          placeholder="-- Seleccione estado --"
          onChange={handleInput}
          options={states}
          value={data.productrequest_id}
          error={errors.productrequest_id}
          disabled={see}
        />
        <TextAreaInput
          name="comment_status"
          label="Comentario"
          placeholder="Comentario del estado de la solicitud"
          onChange={handleInput}
          value={data.comment_status}
          error={errors.comment_status}
          disabled={see}
        />
        {requestMessage && (
          <Row>
            <Alert color={errors ? "danger" : "success"}>
              {requestMessage}
            </Alert>
          </Row>
        )}
        <Row>
          <Col className="d-grid">
            <Link to="/product" className="d-grid">
              <Button color="info" size="lg">
                Volver a la lista
              </Button>
            </Link>
          </Col>
          {!see && (
            <Col className="d-grid">
              <Button type="submit" size="lg" color="info">
                Guardar
              </Button>
            </Col>
          )}
        </Row>
      </fieldset>
    </AvForm>
  );
};

export default RequestState;
