import React, { useContext, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { DataContext } from "./Content";
import NumberFormat from "react-number-format";
import ProductModal from "./ProductModal";
import { checkRole } from "helpers/getUser";

import { CustomInputSelect } from "components/Table/Inputs/";

const NoData = () => {
  return <div className="text-center">No hay datos</div>;
};

const Table = ({ zone_idx, category_idx }) => {
  const roles = checkRole([
    "Administrador",
    "Inmobiliaria",
    "Gerencia Presupuesto",
    "Gerencia ODI",
    "ODI",
  ]);

  const see = window.location.pathname.includes("/see/");
  const {
    data,
    setData,
    segment_id,
    setProductFilters,
    generalattributes,
    specificattributes,
    toggleModal,
    modals,
    setCommentData,
  } = useContext(DataContext);
  const [showProductModal, setShowProductModal] = useState(-1);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showDeleteProductWarning, setShowDeleteProductWarning] =
    useState(false);
  const [keepOldValue, setKeepOldValue] = useState(false);

  const items =
    data[zone_idx].categoriesbudget[category_idx].elementtypesbudget;

  useEffect(() => {
    setData((prev) => {
      prev.zonesbudget[zone_idx].categoriesbudget[
        category_idx
      ].elementtypesbudget.forEach((item) => {
        const row = item.elementdetailsbudget;
        row.error = row.budget < row.totalUF;
      });

      return { ...prev };
    });
  }, [category_idx, setData, zone_idx]);

  if (!specificattributes.length || !generalattributes.length || !items)
    return <></>;

  const calculateTotal = (state) => {
    // Update totals

    // Categories
    state.zonesbudget[zone_idx].categoriesbudget[category_idx].total =
      state.zonesbudget[zone_idx].categoriesbudget[
        category_idx
      ].elementtypesbudget.reduce(
        (total, item) => total + item.elementdetailsbudget.total,
        0
      );

    state.zonesbudget[zone_idx].categoriesbudget[category_idx].totalUF =
      state.zonesbudget[zone_idx].categoriesbudget[
        category_idx
      ].elementtypesbudget.reduce(
        (total, item) => total + (item.elementdetailsbudget.totalUF || 0),
        0
      );

    // Zones
    state.zonesbudget[zone_idx].total = state.zonesbudget[
      zone_idx
    ].categoriesbudget.reduce((total, item) => total + item.total, 0);

    state.zonesbudget[zone_idx].totalUF = state.zonesbudget[
      zone_idx
    ].categoriesbudget.reduce((total, item) => total + (item.totalUF || 0), 0);

    // Global
    state.total = state.zonesbudget.reduce(
      (total, item) => total + item.total,
      0
    );

    state.totalUF = state.zonesbudget.reduce(
      (total, item) => total + (item.totalUF || 0),
      0
    );
  };

  const addProduct = (rowIdx, values) => {
    setData((prev) => {
      // clone the entire object and not reference to it
      // const save = JSON.parse(JSON.stringify(prev));

      const row =
        prev.zonesbudget[zone_idx].categoriesbudget[category_idx]
          .elementtypesbudget[rowIdx].elementdetailsbudget;

      let showError = row.budget < values.totalUF;

      prev.zonesbudget[zone_idx].categoriesbudget[
        category_idx
      ].elementtypesbudget[rowIdx].elementdetailsbudget = {
        ...prev.zonesbudget[zone_idx].categoriesbudget[category_idx]
          .elementtypesbudget[rowIdx].elementdetailsbudget,
        ...values,
        error: showError,
      };

      calculateTotal(prev);

      // if (prev.total < prev.project.budget_amount) {
      //   // this will create a new object and not a clone of the same
      //   // allowing react to propertly re render
      //   return { ...prev };
      // }

      // if (
      //   prev.project.budget_amount < prev.total &&
      //   prev.total < prev.project.budget_amount * 1.1
      // ) {
      //   setShowWarningModal(true);
      //   return { ...prev };
      // }

      return { ...prev };
    });

    setShowProductModal(-1);
  };

  const removeProduct = (rowIdx) => {
    setData((prev) => {
      prev.zonesbudget[zone_idx].categoriesbudget[
        category_idx
      ].elementtypesbudget[rowIdx].elementdetailsbudget = {
        ...prev.zonesbudget[zone_idx].categoriesbudget[category_idx]
          .elementtypesbudget[rowIdx].elementdetailsbudget,
        model: null,
        supplier: null,
        total: null,
        totalUF: null,
        product_id: null,
      };

      calculateTotal(prev);

      // this will create a new object and not a clone of the same
      // allowing react to propertly re render
      return { ...prev };
    });
  };

  const editRow = (oldValue, newValue, row, column, done) => {
    if (!newValue) return { async: true };
    const keys = column.dataField.split(".");
    let idx;

    setData((prev) => {
      idx = items.findIndex((item) => row.pos === item.pos);

      if (keys.length === 1) {
        prev.zonesbudget[zone_idx].categoriesbudget[
          category_idx
        ].elementtypesbudget[idx][keys[0]] = newValue;
      } else {
        prev.zonesbudget[zone_idx].categoriesbudget[
          category_idx
        ].elementtypesbudget[idx][keys[0]][keys[1]] = newValue;
      }

      done(false);

      return { ...prev };
    });

    if (
      ["generalattribute_id", "specificattribute_id"].includes(keys.at(1)) &&
      oldValue !== newValue
    ) {
      removeProduct(idx);
    }

    setKeepOldValue(false);

    return { async: true };
  };

  const disableCommentHightlightBtn = (btn) => {
    btn.classList.add("btn-info");
    btn.classList.remove("btn-danger");
  };

  const handleOpenProductModal = async (row, idx) => {
    setProductFilters({
      typeelement_id: row.typeelement_id,
      generalattribute_id: row.elementdetailsbudget.generalattribute_id,
      specificattribute_id: row.elementdetailsbudget.specificattribute_id,
      segment_id: segment_id,
    });
    await new Promise((r) => setTimeout(r, 400));
    setShowProductModal(idx);
  };

  const handleOpenDeleteProductModal = (setKeep) => {
    setShowDeleteProductWarning((prev) => !prev);

    if (typeof setKeep === "boolean") {
      setKeepOldValue(setKeep);
    }
  };

  const columns = [
    {
      dataField: "typeelement.name",
      text: "Tipo de Elemento",
      editable: false,
    },
    {
      dataField: "elementdetailsbudget.generalattribute_id",
      text: "Atributo General",
      editorRenderer: (editorProps, _value, row) => (
        <CustomInputSelect
          {...editorProps}
          name="general-attribute"
          options={generalattributes}
          filter={(item) =>
            row.typeelement_id && item.typeelement_id === row.typeelement_id
          }
          showConfirmation={handleOpenDeleteProductModal}
          keepOld={keepOldValue}
        />
      ),
      formatter: (cell) => {
        if (!cell) return "";
        if (!generalattributes.length) return "";

        return generalattributes.find((item) => item.value === cell).label;
      },
    },
    {
      dataField: "elementdetailsbudget.specificattribute_id",
      text: "Atributo Especifico",
      editorRenderer: (editorProps, _value, row) => (
        <CustomInputSelect
          {...editorProps}
          name="specific-attribute"
          options={specificattributes}
          filter={(item) =>
            row.elementdetailsbudget?.generalattribute_id &&
            item.generalattribute_id ===
            row.elementdetailsbudget?.generalattribute_id
          }
          showConfirmation={handleOpenDeleteProductModal}
          keepOld={keepOldValue}
        />
      ),
      formatter: (cell) => {
        if (!cell) return "";
        if (!specificattributes.length) return "";

        return specificattributes.find((item) => item.value === cell).label;
      },
    },
    {
      dataField: "elementdetailsbudget.details",
      text: "Detalles",
    },
    {
      dataField: "elementdetailsbudget.budget",
      text: "Presupuesto",
      customType: "number",
      formatter: (cell) => (
        <NumberFormat
          thousandSeparator="."
          decimalSeparator=","
          prefix="UF "
          value={cell}
          displayType="text"
        />
      ),
      editable: false,
    },
    {
      dataField: "elementdetailsbudget.unit_measurement",
      text: "Unidad",
      editable: false,
    },
    {
      dataField: "elementdetailsbudget.model",
      text: "Modelo",
      editable: false,
    },
    {
      dataField: "elementdetailsbudget.supplier",
      text: "Proveedor",
      editable: false,
    },
    {
      dataField: "elementdetailsbudget.totalUF",
      text: "Total",
      customType: "number",
      editable: false,
      formatter: (cell) => (
        <NumberFormat
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          prefix="UF "
          value={cell}
          displayType="text"
        />
      ),
    },
    {
      dataField: "asd",
      text: "Productos",
      hidden: see,
      editable: false,
      formatter: (_a, row, idx) => (
        <div className="hstack gap-1">
          <div className="">
            <Button
              size="sm"
              outline
              color="success"
              onClick={() => {
                handleOpenProductModal(row, idx);
              }}
            >
              <i className="fas fa-plus" />
            </Button>
          </div>
          <div className="">
            <Button
              size="sm"
              outline
              color="danger"
              onClick={() => removeProduct(idx)}
            >
              <i className="fas fa-minus" />
            </Button>
          </div>
        </div>
      ),
    },
    {
      dataField: "asdf",
      text: "Acciones",
      editable: false,
      formatter: (_a, row) => (
        <div className="hstack gap-1 justify-content-center">
          <div className="">
            <Button
              size="sm"
              color={row.paint ? "danger" : "info"}
              onClick={(e) => {
                disableCommentHightlightBtn(e.target);
                setCommentData({
                  typeelement: row.typeelement?.name,
                });
                toggleModal(row.id, modals.commentsElementType);
              }}
            >
              Comentarios
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Row className="m-0 align-items-end">
      <ProductModal
        elementId={showProductModal}
        toggle={() => setShowProductModal(-1)}
        addProduct={addProduct}
      />
      <Modal
        size="xl"
        centered
        color="danger"
        isOpen={showErrorModal}
        toggle={() => setShowErrorModal((prev) => !prev)}
      >
        <ModalHeader toggle={() => setShowErrorModal((prev) => !prev)}>
          Error
        </ModalHeader>
        <ModalBody className="vstack gap-3">
          El producto supera el presupuesto establecido!
          <Button onClick={() => setShowErrorModal((prev) => !prev)}>
            Cerrar
          </Button>
        </ModalBody>
      </Modal>
      <Modal
        size="xl"
        centered
        color="danger"
        isOpen={showWarningModal}
        toggle={() => setShowWarningModal((prev) => !prev)}
      >
        <ModalHeader toggle={() => setShowWarningModal((prev) => !prev)}>
          ¡Atención!
        </ModalHeader>
        <ModalBody className="vstack gap-3">
          El total supera el presupuesto establecido por menos de un 10%, el
          producto será agregado de todas maneras.
          <Button onClick={() => setShowWarningModal((prev) => !prev)}>
            Cerrar
          </Button>
        </ModalBody>
      </Modal>
      <Modal
        size="lg"
        centered
        color="danger"
        isOpen={showDeleteProductWarning}
      >
        <ModalHeader>¡Atención!</ModalHeader>
        <ModalBody className="vstack gap-3">
          Al modificar este atributo, el producto asociado será eliminado,
          ¿desea continuar?
          <br />
          Para cambiar el valor, seleccione nuevamente el atributo.
          <Row>
            <Col className="d-grid">
              <Button onClick={() => handleOpenDeleteProductModal(true)}>
                Cancelar
              </Button>
            </Col>
            <Col className="d-grid">
              <Button
                color="success"
                onClick={() => setShowDeleteProductWarning((prev) => !prev)}
              >
                Continuar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Col className="p-0">
        <BootstrapTable
          keyField="pos"
          sort={{ dataField: "pos", order: "asc" }}
          wrapperClasses="w-100 h-100"
          classes="m-0"
          headerWrapperClasses="text-center text-nowrap"
          rowClasses={(row) =>
            row.elementdetailsbudget.error && "array-bg-danger text-white"
          }
          data={items}
          columns={columns}
          hover
          condensed
          noDataIndication={NoData}
          {...{
            ...(roles && {
              cellEdit: cellEditFactory({
                mode: "click",
                blurToSave: true,
                beforeSaveCell: editRow,
              }),
            }),
          }}
        />
      </Col>
    </Row>
  );
};

export default Table;
