import React, { useEffect, useState, createContext } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Button, Alert } from "reactstrap";
import { get, put, API_URL, TOKEN } from "helpers/ApiHelper";
import Zone from "./Zone";
import Summary from "./Summary";
import JsFileDownloader from "js-file-downloader";
import { checkRole } from "helpers/getUser";
import { useFetchSelect } from "components/Form";
import ModalCommentsTypeElement from "./ModalComments";
import ModalCommentsBudget from "../Projects/ModalComments";
import { Link } from "react-router-dom";

export const DataContext = createContext();

const Content = () => {
  const modals = {
    none: "",
    commentsBudget: "commentsBudget",
    commentsElementType: "commentsElementType",
  };

  const see = window.location.pathname.includes("/see/");
  const { id } = useParams();

  const [data, setData] = useState();
  const [products, setProducts] = useState([]);

  const [message, setMessage] = useState();
  const [error, setError] = useState();
  const [productFilters, setProductFilters] = useState(null);
  const [openModal, setOpenModal] = useState(modals.none);
  const [selectedModalId, setSelectedModalId] = useState(-1);
  const [commentData, setCommentData] = useState({});

  const generalattributes = useFetchSelect("generalattributes");
  const specificattributes = useFetchSelect("specificattributes");

  useEffect(() => {
    const fetch = async () => {
      const query = new URLSearchParams({ ...productFilters, generic: "all" });
      const response = await get("products/filtered?" + query.toString());
      setProducts(response.status === 200 ? response.data.products : []);
    };

    fetch();
  }, [productFilters]);

  useEffect(() => {
    const fetchData = async () => {
      setMessage("");
      const request = await get("budgets/" + id);

      if (request.status === 200) {
        setData(request.data);
      }
    };

    fetchData();
  }, [id]);

  const sendForm = async () => {
    const response = await put("budgets/" + id, data);

    setMessage(response.message);
    setError(response.status !== 200);
  };

  const downloadFile = async (type) => {
    if (!see) {
      await sendForm();
    }

    const filename = type === "pdf" ? "presupuesto.pdf" : "presupuesto.xlsx";

    await new JsFileDownloader({
      url: `${API_URL}export/${type}/budget/${id}`,
      headers: [{ name: "Authorization", value: `Bearer ${TOKEN}` }],
      contentTypeDetermination: "header",
      filename: filename,
    });
  };

  const toggleModal = async (id, modal) => {
    if (isNaN(id)) {
      setOpenModal("");
      await new Promise((r) => setTimeout(r, 200));
      setSelectedModalId(-1);
      return;
    }

    setSelectedModalId(id);
    setOpenModal(modal);
  };

  if (!data) return <></>;

  return (
    <React.Fragment>
      <div className="align-items-center mb-3 hstack gap-3 justify-content-end">
        <section className="hstack gap-3 me-auto">
          <h4 className="text-white">{see ? "Ver" : "Editar"} Presupuesto</h4>
        </section>
        <Link
          to={`/projects?project_id=${data.project?.id}`}
          className="d-grid"
        >
          <Button
            color="info"
            size="lg"
            onClick={() => toggleModal(id, modals.commentsBudget)}
          >
            Volver al proyecto
          </Button>
        </Link>
        <Button
          color="info"
          size="lg"
          onClick={() => toggleModal(id, modals.commentsBudget)}
        >
          Comentarios
        </Button>
        <Button color="info" size="lg" onClick={() => downloadFile("pdf")}>
          Exportar a PDF
        </Button>
        {checkRole([
          "Administrador",
          "Inmobiliaria",
          "Gerencia Presupuesto",
          "Gerencia ODI",
          "ODI",
        ]) && (
            <Button color="info" size="lg" onClick={() => downloadFile("excel")}>
              Exportar a Excel
            </Button>
          )}
      </div>
      <ModalCommentsTypeElement
        open={openModal === modals.commentsElementType}
        toggle={toggleModal}
        elementtype_id={selectedModalId}
        extraData={commentData}
      />
      <ModalCommentsBudget
        open={openModal === modals.commentsBudget}
        toggle={toggleModal}
        budget_id={selectedModalId}
      />
      <Summary
        data={{
          ...data.project,
          array_version: data.array_version,
          array_date: data.array_date,
          budget_version: data.version,
          budget_date: data.updatedAt,
          total: data.total,
          totalUF: data.totalUF,
          validationIMB: data.validationIMB,
          validationODI: data.validationODI,
          validationGPR: data.validationGPR,
          budgetstatus: data.budgetstatus.status,
        }}
      />
      <fieldset className="pb-4">
        <Row className="fs-5 mx-0 mb-3 text-center">
          <Col xs={3} className="text-white border border-dark bg-secondary">
            Recinto
          </Col>
          <Col
            xs={5}
            className="text-white border border-start-0 border-dark bg-secondary"
          >
            Atributo inmobiliario fijo
          </Col>
          <Col className="text-white border border-start-0 border-dark bg-secondary">
            EETT ODI Variable
          </Col>
        </Row>
        <DataContext.Provider
          value={{
            data: data.zonesbudget,
            segment_id: data?.project.segment_id,
            setData,
            products,
            setProductFilters,
            generalattributes,
            specificattributes,
            toggleModal,
            modals,
            setCommentData,
          }}
        >
          <>
            {data.zonesbudget?.length > 0 &&
              data.zonesbudget.map((item, idx) => (
                <Zone key={idx} zone_idx={idx} data={item} />
              ))}
          </>
        </DataContext.Provider>
        {message && (
          <Row>
            <Alert color={error ? "danger" : "success"}>{message}</Alert>
          </Row>
        )}
        {!see && (
          <Row>
            <Col className="d-grid">
              <Button color="success" onClick={sendForm}>
                Guardar
              </Button>
            </Col>
          </Row>
        )}
      </fieldset>
    </React.Fragment>
  );
};

export default Content;
