import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import { Table } from "../../Table/";
import { get, del } from "helpers/ApiHelper";
import { checkRole } from "helpers/getUser";

const Content = () => {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const request = await get("products?discontinued=true&inactive=true");
      setData(request.status === 200 ? request.data.products : []);
    };

    fetchData();
  }, [update]);

  const columns = [
    {
      dataField: "id",
      text: "ID de creación",
      customType: "number",
    },
    {
      dataField: "name",
      text: "Nombre",
      customType: "string",
    },
    {
      dataField: "model.brand.suppliers",
      text: "Proveedor",
      customType: "string",
      formatter: (cell) => cell.map((item) => item.name).join(" - "),
      filterValue: (cell) => cell.map((item) => item.name).join(" - "),
      sortValue: (cell) => cell.map((item) => item.name).join(" - "),
    },
    {
      dataField: "model.brand.name",
      text: "Marca",
      customType: "string",
    },
    {
      dataField: "model.name",
      text: "Modelo",
      customType: "string",
    },
    {
      dataField: "code_supplier",
      text: "Código",
      customType: "string",
    },
    {
      dataField: "icomponent",
      text: "Código IC",
      customType: "string",
      formatter: (cell) => cell.map((item) => item.icode).join(" - "),
      filterValue: (cell) => cell.map((item) => item.icode).join(" - "),
    },
    {
      dataField: "productresult.name",
      text: "Estado",
      customType: "string",
    },
    {
      dataField: "commercialstatus.name",
      text: "ODI - Estado comercial",
      customType: "string",
    },
    {
      dataField: "segment",
      text: "Segmentos",
      customType: "string",
      formatter: (_, row) => row.segments.map((item) => item.name).join("/"),
      sortValue: (_, row) => row.segments.map((item) => item.name).join("/"),
      filterValue: (_, row) => row.segments.map((item) => item.name).join("/"),
    },
    {
      dataField: "actions",
      text: "Acciones",
      customType: "actions-full",
    },
  ];

  const toggleFunction = async (id) => {
    await del("products/" + id);
    setUpdate(new Date().getTime());
  };

  return (
    <React.Fragment>
      <Row className="align-items-center mb-3">
        <Col sm={4} lx="auto" className="me-auto">
          <h4 className="text-white">Producto / Listar</h4>
        </Col>
        {checkRole(["Administrador", "ODI", "Calidad"]) && (
          <Col sm={4} lx={2}>
            <Link to="/product/create" className="d-grid">
              <Button color="info" size="lg">
                Crear Producto
              </Button>
            </Link>
          </Col>
        )}
      </Row>
      <Row className="p-2">
        <Table
          headers={columns}
          data={data}
          toggle={{ statusProperty: "productstate_id", toggleFunction }}
          roles={["Administrador", "ODI", "Calidad"]}
        />
      </Row>
    </React.Fragment>
  );
};

export default Content;
