import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import PropTypes from "prop-types";

const CustomProgressBar = ({ currentStep, steps }) => {
  const percentage = (currentStep / steps.length) * 100;
  return (
    <ProgressBar
      percent={Math.ceil(percentage)}
      filledBackground="$primary"
      hasStepZero={false}
    >
      {steps.map((item, idx) => (
        <Step key={idx} transition="scale">
          {({ accomplished }) => (
            <div className="progress-bar-item">
              <div className="progress-bar-top">{item.textTop || <br />}</div>
              <div
                className={`progress-bar-circle ${
                  accomplished ? "bg-primary" : "bg-light"
                }`}
              />
              <div className="progress-bar-bottom">
                {item.textBottom || <br />}
              </div>
            </div>
          )}
        </Step>
      ))}
    </ProgressBar>
  );
};

CustomProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      textTop: PropTypes.string,
      textBottom: PropTypes.string,
    })
  ).isRequired,
};

export default CustomProgressBar;
