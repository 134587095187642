import React, { useState, useEffect } from "react";
import { FileInput, TextInput } from "components/Form/";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, FormGroup, Label, Button, Alert } from "reactstrap";
import { useParams, Link } from "react-router-dom";
import { get, post, put, API_URL } from "helpers/ApiHelper";
import { TextAreaInput } from "components/Form";
import FileItem from "./FileItem";

const ProductData = () => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const [data, setData] = useState({ name: "", productfiles: [] });
  const [requestMessage, setRequestMessage] = useState("");
  const [requestOk, setRequestOk] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    const response = await get("products/" + id);
    setRequestMessage("");

    if (response.status !== 200) return;

    setData({
      ...response.data,
      images: response.data.images.map((img) => ({
        ...img,
        preview: API_URL + img.link,
      })),
      productfiles: response.data.productfiles || [],
    });
  };

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const sendForm = async () => {
    setRequestMessage("");
    const response = id
      ? await put("products-data/" + id, data)
      : await post("products", { ...data, generic: 1 });

    setRequestOk(response.status === 200);
    setErrors(response.status !== 200 ? response.data : {});
    setRequestMessage(response.message);
  };

  const encodeFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleAcceptFile = async (files, idx) => {
    const fileData = await encodeFile(files[0]);
    const type = files[0].type.split("/")[1];
    const newFile = { type, data: fileData, new: true };
    setData((prevData) => {
      prevData.productfiles[idx] = {
        ...prevData.productfiles[idx],
        ...newFile,
      };

      return { ...prevData };
    });
  };

  const handleChangeFileInput = (idx, name, value) => {
    setData((prevData) => {
      prevData.productfiles[idx] = {
        ...prevData.productfiles[idx],
        [name]: value,
      };

      return { ...prevData };
    });
  };

  const handleDeleteFile = (idx) => {
    setData((prevData) => {
      prevData.productfiles[idx] = {
        ...prevData.productfiles[idx],
        deleted: true,
        new: false,
      };

      return { ...prevData };
    });
  };

  const handleAddFile = () => {
    setData((prevData) => {
      prevData.productfiles = [
        ...prevData.productfiles,
        { new: true, name: "", description: "", link: "" },
      ];

      return { ...prevData };
    });
  };

  const handleAddImages = async (files) => {
    console.log({ files });
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    const images = await Promise.all(
      files.map(async (img) => {
        const data = await encodeFile(img);
        const type = img.type.split("/")[1];
        return { ...img, type, data, new: true };
      })
    );

    setData((prevData) => ({
      ...prevData,
      images: prevData.images ? prevData.images.concat(images) : images,
    }));
  };

  const handleRemoveImages = (removed_idx) => {
    setData((prevData) => {
      const img = prevData.images[removed_idx];

      if (img.new) {
        // Local file, Remove from the array
        prevData.images = prevData.images.filter(
          (_, idx) => idx !== removed_idx
        );
      } else {
        // Remote file, Set flag to remove from the back
        img.deleted = true;
      }

      return {
        ...prevData,
      };
    });
  };

  return (
    <AvForm onValidSubmit={sendForm}>
      <fieldset>
        <h3>Información del producto generico</h3>
        <TextInput
          name="name"
          label="Nombre"
          placeholder="-- ingrese nombre --"
          onChange={handleInput}
          error={errors.name}
          value={data.name}
          disabled={see}
        />
        <FormGroup className="row align-items-center justify-content-between">
          <Col xs={2}>
            <Label>Archivos adjuntos</Label>
          </Col>
          <Col xs="3" className="d-grid">
            <Button color="btn btn-info" onClick={handleAddFile}>
              Agregar Documento
            </Button>
          </Col>
        </FormGroup>

        <Row className="align-items-center justify-content-between">
          <Col xs={{ offset: 2, size: 10 }}>
            <Row
              xs="1"
              xl="2"
              className="align-items-center justify-content-between"
            >
              {data.productfiles.map((item, idx) => (
                <FileItem
                  key={idx}
                  idx={idx}
                  item={item}
                  see={see}
                  errors={errors}
                  methods={{
                    handleAcceptFile,
                    handleDeleteFile,
                    handleChangeFileInput,
                  }}
                />
              ))}
            </Row>
          </Col>
        </Row>

        <TextAreaInput
          name="details"
          label="Detalles"
          placeholder="-- ingrese detalles --"
          onChange={handleInput}
          error={errors.details}
          value={data.details}
          disabled={see}
        />
        <FileInput
          label="Imágenes"
          handleAddFiles={handleAddImages}
          handleRemoveFile={handleRemoveImages}
          files={data.images}
          error={errors.images}
          disabled={see}
        />
        {requestMessage && (
          <Row>
            <Alert color={requestOk ? "success" : "danger"}>
              {requestMessage}
            </Alert>
          </Row>
        )}
        <Row>
          <Col className="d-grid">
            <Link to="/generic-product" className="d-grid">
              <Button color="info" size="lg">
                Volver a la lista
              </Button>
            </Link>
          </Col>
          {!see && (
            <Col className="d-grid">
              <Button type="submit" size="lg" color="info">
                Guardar
              </Button>
            </Col>
          )}
        </Row>
      </fieldset>
    </AvForm>
  );
};

export default ProductData;
