import React, { Component } from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import Content from "../../components/Sections/Products/DetailsProducts";

class DetailsProducts extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{process.env.REACT_APP_PAGE_TITLE} | Crear Producto</title>
          </MetaTags>
          <Container fluid>
            <Content />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default DetailsProducts;
