import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import {
  TextInput,
  SelectInput,
  useForm,
  useFetchSelect,
  NumberInput,
} from "../../Form/";
import { get } from "helpers/ApiHelper";
import FormNotifications from "./FormNotifications";

const ModalForm = ({ open, toggle, project, update }) => {
  const [data, setData] = useState({});
  const [helper, setHelper] = useState({});
  const { requestMessage, errors, sendForm, clearMsg } = useForm();

  const segments = useFetchSelect("segments");
  const regions = useFetchSelect("address/regions");
  const [communes, setCommunes] = useState([]);

  useEffect(() => {
    if (project) {
      setData(project);

      setHelper({
        region: project.commune.region.id,
      });
    }

    if (!open) {
      setData({});

      setHelper({});

      clearMsg();
    }
  }, [open, project, clearMsg]);

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const fetchData = (endpoint, setter) => {
    console.log("Calling: ", endpoint);
    get(endpoint).then((response) => {
      setter(
        response.status === 200
          ? response.data.map((item) => ({ label: item.name, value: item.id }))
          : []
      );
    });
  };

  useEffect(() => {
    if (!helper.region) return;
    fetchData(`address/regions/${helper.region}/communes`, setCommunes);
  }, [helper.region]);

  useEffect(() => {
    if (!data.segment_id) return;

    get(`arrays/${data.segment_id}/budgettotal`).then((res) => {
      handleInput("budget_amount", res.status === 200 ? res.data.clp : null);
      handleInput("budget_amount_uf", res.status === 200 ? res.data.uf : null);
    });
  }, [data.segment_id]);

  const handleHelper = (name, value) => {
    setHelper((prevData) => {
      switch (name) {
        case "region":
          prevData.region = value;
          break;
        default:
          break;
      }
      return { ...prevData };
    });

    handleInput("commune_id", null);
  };

  const handleSubmit = async () => {
    update();
    const response = await sendForm("projects", data, data.id);

    if (!response) return;

    setData((prevData) => ({ ...prevData, id: response.id }));
  };

  const closeButton = (
    <Col className="d-grid">
      <Button color="secondary" size="lg" onClick={toggle}>
        Cerrar
      </Button>
    </Col>
  );

  return (
    <Modal size="lg" isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        {data.id ? "Editar" : "Crear"} Proyecto
      </ModalHeader>
      <ModalBody>
        <AvForm className="vstack gap-3">
          <TextInput
            name="name"
            label="Nombre"
            placeholder="-- Ingrese nombre --"
            value={data.name}
            onChange={handleInput}
            error={errors.name}
          />
          <TextInput
            name="owner"
            label="Creador"
            placeholder="-- Ingrese nombre --"
            value={data.owner}
            onChange={handleInput}
            error={errors.owner}
          />
          <TextInput
            name="code"
            label="Codigo"
            placeholder="-- Ingrese codigo --"
            value={data.code}
            onChange={handleInput}
            error={errors.code}
          />
          <SelectInput
            name="segment_id"
            label="Segmento"
            placeholder="-- Ingrese Segmento --"
            value={data.segment_id}
            onChange={handleInput}
            options={segments}
            error={errors.segment_id}
          />
          <SelectInput
            name="region"
            label="Region"
            placeholder="-- Ingrese region --"
            value={helper.region}
            options={regions}
            onChange={handleHelper}
            error={errors.region}
          />
          <SelectInput
            name="commune_id"
            label="Comuna"
            placeholder="-- Ingrese comuna --"
            value={data.commune_id}
            options={communes}
            onChange={handleInput}
            error={errors.commune_id}
          />
          <NumberInput
            name="budget_amount"
            label="Presupuesto"
            placeholder="-- Ingrese presupuesto --"
            value={data.budget_amount}
            error={errors.budget_amount}
            onChange={handleInput}
            disabled={true}
            prefix="$"
          />
          <NumberInput
            name="budget_amount_uf"
            label="Presupuesto UF"
            placeholder="-- Ingrese presupuesto --"
            value={data.budget_amount_uf}
            error={errors.budget_amount_uf}
            onChange={handleInput}
            disabled={true}
            prefix="UF"
          />
          {requestMessage && (
            <Row>
              <Alert color={Object.keys(errors).length ? "danger" : "success"}>
                {requestMessage}
              </Alert>
            </Row>
          )}
          <Row>
            {!data.id && closeButton}
            <Col className="d-grid">
              <Button
                type="submit"
                size="lg"
                color="info"
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            </Col>
          </Row>

          <FormNotifications project_id={data.id} closeButton={closeButton} />
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default ModalForm;
