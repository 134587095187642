import { API_URL } from "helpers/ApiHelper";
import useModal from "hooks/useModal";
import React, { useMemo, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Select from "react-select";
import {
  Row,
  Col,
  Alert,
  Modal as ModalBS,
  ModalBody,
  ModalHeader,
} from "reactstrap";

export default function Modal() {
  const [selectedVersion, setSelectedVersion] = useState({
    label: 1,
    value: 0,
  });
  const modalFicha = useModal("ficha");
  const modalImages = useModal("images");

  useEffect(() => {
    if (!modalFicha.data?.prototypeversions) {
      return;
    }

    const index = modalFicha.data?.prototypeversions.findIndex(
      (item) => item.show_in_catalog
    );

    setSelectedVersion({ label: index + 1, value: index });
  }, [modalFicha.data]);

  const version =
    modalFicha.data?.prototypeversions?.at(selectedVersion.value) ?? {};

  const versions = useMemo(() => {
    if (!modalFicha.data?.prototypeversions) {
      return [];
    }

    return modalFicha.data.prototypeversions.map((item, idx) => ({
      label: item.version_number,
      value: idx,
    }));
  }, [modalFicha.data]);

  return (
    <ModalBS
      centered
      size="xl"
      isOpen={modalFicha.isOpen}
      toggle={() => modalFicha.toggleModal()}
    >
      <ModalHeader
        tag="h3"
        className="text-center"
        toggle={() => modalFicha.toggleModal()}
      >
        Ficha de Prototipo
      </ModalHeader>

      <ModalBody className="row justify-content-between">
        <section className="col-12 col-lg-5">
          <div className="image-collection images">
            {version &&
              version.prototypeimages?.map((item, idx) => (
                <div
                  key={idx}
                  onClick={() => modalImages.toggleModal(item.link)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="thumbnail-scale">
                    <img src={API_URL + item.link} alt="img" />
                  </div>
                </div>
              ))}
          </div>
        </section>
        <section className="col-12 col-lg-7 vstack gap-3">
          <div id="header">
            <section className="hstack gap-2 justify-content-between">
              <h3 className="me-auto mb-0">{modalFicha.data?.name}</h3>

              {!version.show_in_catalog && (
                <span className="px-3 align-self-stretch bg-danger text-white rounded center-content">
                  Versión no aprobada
                </span>
              )}

              <label className="visually-hidden" htmlFor="version">
                Versión
              </label>
              <span style={{ minWidth: 200 }}>
                <Select
                  id="version"
                  name="version"
                  options={versions}
                  value={selectedVersion}
                  placeholder="Seleccione versión..."
                  onChange={(value) => setSelectedVersion(value)}
                />
              </span>
            </section>
            <hr className="w-100" />
          </div>

          <div className="content">
            <div id="description" className="mb-3">
              <section className="hstack justify-content-between">
                <h4>Descripción</h4>
              </section>
              <section>{version.description}</section>
            </div>

            <hr className="w-100 mb-3" />

            <div id="info" className="mb-3">
              <h4>Información del Prototipo</h4>
              <Row>
                <Col xs={3}>Tipo:</Col>
                <Col>{version.prototypetype?.name}</Col>
              </Row>
            </div>

            <hr className="w-100 mb-3" />

            <div id="file mb-3">
              <section className="vstack gap-2">
                <Row className="align-items-center">
                  <Col xs={3}>Ficha técnica:</Col>
                </Row>
                <div className="vstack gap-3">
                  {version.prototypefiles?.map((item, idx) => (
                    <Alert color="secondary" key={idx} className="mb-0">
                      <Row className="align-items-center">
                        <Col xs="3">
                          <strong>Nombre del archivo:</strong>
                        </Col>
                        <Col>{item.name}</Col>
                        <Col xs="2">
                          <a
                            href={item.link ? API_URL + item.link : ""}
                            target="_blank"
                            download
                            rel="noreferrer"
                          >
                            <i className="fas fa-download fs-3"></i>
                          </a>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs="3">
                          <strong>Descripción:</strong>
                        </Col>
                        <Col>{item.description}</Col>
                      </Row>
                    </Alert>
                  ))}
                </div>
              </section>
            </div>

            <hr className="w-100 mb-3" />

            <div id="video" className="mb-3">
              <h4>Vídeo</h4>
              {version.video_url ? (
                <ReactPlayer light width="100%" url={version.video_url} id="modal-video" controls
                playing/>
              ) : (
                <Alert color="secondary" className="mt-3">
                  El producto no posee vídeo
                </Alert>
              )}
            </div>

            <hr className="w-100 mb-3" />
          </div>
        </section>
      </ModalBody>
    </ModalBS>
  );
}
