import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import {
  authProtectedRoutes,
  publicRoutes,
  authProtectedRoutesAdmin,
  authProtectedRoutesGerenciaInmoviliaria,
  authProtectedRoutesInmoviliaria,
  authProtectedRoutesGerenciaPresupuesto,
  authProtectedRoutesOdi,
  authProtectedRoutesGerenciaOdi,
  authProtectedRoutesCalidad,
  authProtectedRoutesGerencia,
  authProtectedRoutesArquitectura,
  authProtectedRoutesConstruccion,
  authProtectedRoutesGeneral,
} from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/Piloto_Go/scss/style.scss";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { checkRole } from "helpers/getUser";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {checkRole(["Administrador"]) &&
              authProtectedRoutesAdmin.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  roles={["Administrador"]}
                  exact
                />
              ))}

            {checkRole(["Gerente Inmobiliaria"]) &&
              authProtectedRoutesGerenciaInmoviliaria.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  roles={["Gerente Inmobiliaria"]}
                  exact
                />
              ))}

            {checkRole(["Inmobiliaria"]) &&
              authProtectedRoutesInmoviliaria.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  roles={["Inmobiliaria"]}
                  exact
                />
              ))}

            {checkRole(["Gerencia Presupuesto"]) &&
              authProtectedRoutesGerenciaPresupuesto.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  roles={["Gerencia Presupuesto"]}
                  exact
                />
              ))}

            {checkRole(["Gerencia ODI"]) &&
              authProtectedRoutesGerenciaOdi.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  roles={["Gerencia ODI"]}
                  exact
                />
              ))}

            {checkRole(["ODI"]) &&
              authProtectedRoutesOdi.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  roles={["ODI"]}
                  exact
                />
              ))}

            {checkRole(["Calidad"]) &&
              authProtectedRoutesCalidad.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  roles={["Calidad"]}
                  exact
                />
              ))}
            {checkRole(["Gerencia"]) &&
              authProtectedRoutesGerencia.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  roles={["Gerencia"]}
                  exact
                />
              ))}
            {checkRole(["Arquitectura"]) &&
              authProtectedRoutesArquitectura.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  roles={["Arquitectura"]}
                  exact
                />
              ))}
            {checkRole(["Construcción"]) &&
              authProtectedRoutesConstruccion.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  roles={["Construcción"]}
                  exact
                />
              ))}
            {checkRole(["General"]) &&
              authProtectedRoutesGeneral.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  roles={["General"]}
                  exact
                />
              ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
                roles={[]}
              />
            ))}

            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                roles={[]}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

App.propTypes = {
  layout: PropTypes.object,
};

export default connect(mapStateToProps, null)(App);
