import { checkRole } from "helpers/getUser";
import React, { useContext, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { AvForm } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { useForm } from "../../Form/";
import { get } from "helpers/ApiHelper";

const ModalSign = ({ open, toggle, budget_id, update }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [signs, setSigns] = useState({});
  const { requestMessage, status, sendForm, clearMsg } = useForm();

  const [additionalEmails, setAdditionalEmails] = useState([]);

  useEffect(() => {
    if (!open) {
      clearMsg();
    }
  }, [open, clearMsg]);

  useEffect(() => {
    if (!budget_id || budget_id === -1) return;

    fetchData(budget_id);
  }, [budget_id]);

  const fetchData = async (id) => {
    const response = await get("budgets/" + id);

    setSigns({
      inmobiliaria: response.data.validationIMB,
      odi: response.data.validationODI,
      presupuesto: response.data.validationGPR,
    });
  };

  const sendData = async () => {
    toggleConfirmationModal();
    const response = await sendForm(
      "budgets/validation",
      { emails: additionalEmails },
      budget_id
    );

    if (!response) return;

    setSigns({
      inmobiliaria: response.validationIMB,
      odi: response.validationODI,
      presupuesto: response.validationGPR,
    });
    await update();
  };

  const toggleConfirmationModal = () => {
    setShowConfirmationModal((prev) => !prev);
  };

  if (!budget_id || budget_id === -1) return <></>;

  return (
    <Modal size="lg" isOpen={open} toggle={toggle} centered>
      <Modal
        isOpen={!!showConfirmationModal}
        toggle={toggleConfirmationModal}
        centered
      >
        <ModalHeader toggle={toggleConfirmationModal}>Atención</ModalHeader>
        <ModalBody className="vstack gap-3">
          <p>
            Con esta acción se aprueba y se dá término al proceso de elaboración
            de un presupuesto. ¿Está de acuerdo con lo anterior?
          </p>
          <Row>
            <Col className="d-grid">
              <Button color="secondary" onClick={toggleConfirmationModal}>
                Cancelar
              </Button>
            </Col>
            <Col className="d-grid">
              <Button color="success" onClick={sendData}>
                Estoy de acuerdo
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <ModalHeader toggle={toggle}>Firmas</ModalHeader>
      <ModalBody className="vstack gap-3">
        <Row className="align-items-center">
          <Col xs="3">Gerente ODI</Col>
          <Col className="text-center">
            {signs.odi ? new Date(signs.odi).toLocaleString() : "En espera"}
          </Col>
          <Col xs={{ offset: 1, size: 2 }} className="text-end d-grid">
            <Button
              color={!!signs.odi ? "success" : "danger"}
              disabled={!checkRole(["Gerencia ODI"]) || !!signs.odi}
              onClick={toggleConfirmationModal}
            >
              {!!signs.odi ? "Firmado" : "Firmar"}
            </Button>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col xs="3">Gerente Presupuesto</Col>
          <Col className="text-center">
            {signs.presupuesto
              ? new Date(signs.presupuesto).toLocaleString()
              : "En espera"}
          </Col>
          <Col xs={{ offset: 1, size: 2 }} className="text-end d-grid">
            <Button
              color={!!signs.presupuesto ? "success" : "danger"}
              disabled={
                !checkRole(["Gerencia Presupuesto"]) || !!signs.presupuesto
              }
              onClick={toggleConfirmationModal}
            >
              {!!signs.presupuesto ? "Firmado" : "Firmar"}
            </Button>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col xs="3">Gerente Inmobiliaria</Col>
          <Col className="text-center">
            {signs.inmobiliaria
              ? new Date(signs.inmobiliaria).toLocaleString()
              : "En espera"}
          </Col>
          <Col xs={{ offset: 1, size: 2 }} className="text-end d-grid">
            <Button
              color={!!signs.inmobiliaria ? "success" : "danger"}
              onClick={toggleConfirmationModal}
              disabled={
                !checkRole(["Gerente Inmobiliaria"]) ||
                !!signs.inmobiliaria ||
                !signs.odi ||
                !signs.presupuesto
              }
            >
              {!!signs.inmobiliaria ? "Firmado" : "Firmar"}
            </Button>
          </Col>
        </Row>

        {checkRole(["Gerente Inmobiliaria"]) && (
          <AvForm onValidSubmit={sendData} className="vstack gap-3">
            <Row className="align-items-center">
              <Col sm={3}>Emails Adicionales</Col>
              <Col>
                <CreatableSelect
                  name="emails"
                  value={additionalEmails}
                  isMulti
                  onChange={(value) => setAdditionalEmails(value)}
                  placeholder="Ingrese un emails..."
                  formatCreateLabel={(value) => `Añadir ${value}`}
                />
              </Col>
            </Row>
          </AvForm>
        )}

        {requestMessage && (
          <Row>
            <Alert color={status !== 200 ? "danger" : "success"}>
              {requestMessage}
            </Alert>
          </Row>
        )}
        <Row>
          <Col className="d-grid">
            <Button color="secondary" size="lg" onClick={toggle}>
              Cerrar
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ModalSign;
