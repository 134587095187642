/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  FormFeedback,
} from "reactstrap";
import Dropzone from "react-dropzone";

function FileInput(props) {
  return (
    <React.Fragment>
      <FormGroup className="row align-items-center">
        <Col sm={2}>
          <Label>{props.label}</Label>
        </Col>
        <Col>
          <div
            className={
              props.error
                ? "form-control border border-danger rounded is-invalid"
                : "form-control"
            }
          >
            <Dropzone onDrop={props.handleAddFiles} disabled={props.disabled}>
              {({ getRootProps, getInputProps }) => (
                <div
                  className="dropzone text-center d-flex flex-column justify-content-center"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <i className="fs-1 text-muted bx bxs-cloud-upload" />
                  <h4>
                    {props.placeholder ||
                      "Arrastre el archivo aquí o haga click para buscar"}
                  </h4>
                </div>
              )}
            </Dropzone>
          </div>
          <FormFeedback invalid>
            {props.error || "Este campo es necesario"}
          </FormFeedback>
        </Col>
      </FormGroup>
      {props.files?.length > 0 && (
        <Row>
          <Col md={{ offset: 2, size: 10 }}>
            <Row
              xs={1}
              md={3}
              className="dropzone-previews g-3"
              id="file-previews"
            >
              {props.files.map((file, idx) => {
                if (file.deleted) return <></>;

                return (
                  <Col key={"file-" + idx}>
                    <Card className="h-100">
                      <div className="thumbnail-fill position-relative">
                        <img alt={file.name} src={file.preview} />
                        <Button
                          close
                          className="position-absolute end-0 top-0 m-3 fw-bold bg-secondary rounded-border"
                          onClick={() => props.handleRemoveFile(idx)}
                          disabled={props.disabled}
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="text-muted font-weight-bold">
                          {`Archivo ${idx + 1}`}
                          {props.handleSelectFile && (
                            <>
                              <br />
                              <input
                                type="radio"
                                id={`${props.name}-radio-${idx}`}
                                name="selected-file"
                                checked={Boolean(file.principal)}
                                disabled={props.disabled}
                                onClick={() => props.handleSelectFile(idx)}
                              />{" "}
                              <label htmlFor={`${props.name}-radio-${idx}`}>
                                ¿es principal?
                              </label>
                            </>
                          )}
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}

FileInput.propTypes = {
  //match: PropTypes.object.isRequired,
  //location: PropTypes.object.isRequired,
  //history: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  error: PropTypes.array,
  errorMessage: PropTypes.string,
  files: PropTypes.array,
  handleAddFiles: PropTypes.func,
  handleRemoveFile: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FileInput;
