import { API_URL } from "helpers/ApiHelper";
import useModal from "hooks/useModal";
import React from "react";
import { Modal as ModalBS, ModalBody, ModalHeader } from "reactstrap";

export default function ModalImage() {
  const modalImages = useModal("images");
  const modalFicha = useModal("ficha");

  return (
    <ModalBS
      id="image-modal"
      centered
      size="xl"
      toggle={() => modalFicha.toggleModal()}
      isOpen={modalImages.isOpen}
    >
      <ModalHeader
        tag="h3"
        className="text-center"
        toggle={() => modalFicha.toggleModal()}
      ></ModalHeader>
      <ModalBody>
        <img className="w-100" src={API_URL + modalImages.data} alt="img" />
      </ModalBody>
    </ModalBS>
  );
}
