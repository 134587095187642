// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ChangedPassword from "pages/Authentication/ChangedPassword";
import ResetPassword from "pages/Authentication/ResetPassword";
import Forbidden from "../pages/Forbidden/index";

// Producs
import ListProducts from "pages/Products/ListProducts";
import DetailsProducts from "pages/Products/DetailsProducts";
import listProductsMantainer from "pages/Products/ListProductsMantainer";

// Generic Producs
import ListGenericProducts from "pages/GenericProducts/ListProducts";
import DetailsGenericProducts from "pages/GenericProducts/DetailsProducts";

// Prototypes
import PrototypeCatalog from "pages/Prototypes/Catalog";
import Prototypelist from "pages/Prototypes/List";
import PrototypeForm from "pages/Prototypes/Form";

// Users
import UserList from "pages/Users/UserList";
import UserForm from "pages/Users/UserForm";

// Maintainer Lists
import MaintainerListBrand from "pages/Maintainers/Lists/Brand";
import MaintainerListClass from "pages/Maintainers/Lists/Class";
import MaintainerListComponent from "pages/Maintainers/Lists/Component";
import MaintainerListElement from "pages/Maintainers/Lists/Element";
import MaintainerListElementType from "pages/Maintainers/Lists/ElementType";
import MaintainerListFamily from "pages/Maintainers/Lists/Family";
import MaintainerListGeneralAttribute from "pages/Maintainers/Lists/GeneralAttribute";
import MaintainerListModel from "pages/Maintainers/Lists/Model";
import MaintainerListProcutState from "pages/Maintainers/Lists/ProductState";
import MaintainerListPrototypeType from "pages/Maintainers/Lists/PrototypeType";
import MaintainerListSpecificAttribute from "pages/Maintainers/Lists/SpecificAttribute";
import MaintainerListSubclass from "pages/Maintainers/Lists/Subclass";
import MaintainerListSupplier from "pages/Maintainers/Lists/Supplier";
import MaintainerListWorks from "pages/Maintainers/Lists/Works";

// Maintainer Forms
import MaintainerFormBrand from "pages/Maintainers/Forms/Brand";
import MaintainerFormClass from "pages/Maintainers/Forms/Class";
import MaintainerFormComponent from "pages/Maintainers/Forms/Component";
import MaintainerFormElement from "pages/Maintainers/Forms/Element";
import MaintainerFormElementType from "pages/Maintainers/Forms/ElementType";
import MaintainerFormFamily from "pages/Maintainers/Forms/Family";
import MaintainerFormGeneralAttribute from "pages/Maintainers/Forms/GeneralAttribute";
import MaintainerFormModel from "pages/Maintainers/Forms/Model";
import MaintainerFormPrototypeType from "pages/Maintainers/Forms/PrototypeType";
import MaintainerFormSpecificAttribute from "pages/Maintainers/Forms/SpecificAttribute";
import MaintainerFormState from "pages/Maintainers/Forms/ProductState";
import MaintainerFormSubclass from "pages/Maintainers/Forms/Subclass";
import MaintainerFormSupplier from "pages/Maintainers/Forms/Supplier";
import MaintainerFormWorks from "pages/Maintainers/Forms/Works";

// Matrices
import Matrices from "pages/Matrices/List";

// Projects
import Projects from "pages/Projects/List";

// Budgets
import Budget from "pages/Budgets/Form";

// History
import History from "pages/History/List";

const authProtectedRoutesAdmin = [
  // { path: "/admin", component: Dashboard.apply },

  { path: "/product-list", exact: true, component: ListProducts },
  { path: "/product/create", exact: true, component: DetailsProducts },
  { path: "/product/edit/:id", exact: true, component: DetailsProducts },
  { path: "/product/see/:id", exact: true, component: DetailsProducts },
  { path: "/product", exact: true, component: listProductsMantainer },

  {
    path: "/generic-product/create",
    exact: true,
    component: DetailsGenericProducts,
  },
  {
    path: "/generic-product/edit/:id",
    exact: true,
    component: DetailsGenericProducts,
  },
  {
    path: "/generic-product/see/:id",
    exact: true,
    component: DetailsGenericProducts,
  },
  { path: "/generic-product", exact: true, component: ListGenericProducts },

  { path: "/prototypes/create", exact: true, component: PrototypeForm },
  { path: "/prototypes/edit/:id", exact: true, component: PrototypeForm },
  { path: "/prototypes/see/:id", exact: true, component: PrototypeForm },
  { path: "/prototypes/catalog", exact: true, component: PrototypeCatalog },
  { path: "/prototypes", exact: true, component: Prototypelist },

  { path: "/users/create", exact: true, component: UserForm },
  { path: "/users/edit/:id", exact: true, component: UserForm },
  { path: "/users/see/:id", exact: true, component: UserForm },
  { path: "/users", exact: true, component: UserList },

  { path: "/brand/create", exact: true, component: MaintainerFormBrand },
  { path: "/brand/edit/:id", exact: true, component: MaintainerFormBrand },
  { path: "/brand/see/:id", exact: true, component: MaintainerFormBrand },
  { path: "/brand", exact: true, component: MaintainerListBrand },

  { path: "/class/create", exact: true, component: MaintainerFormClass },
  { path: "/class/edit/:id", exact: true, component: MaintainerFormClass },
  { path: "/class/see/:id", exact: true, component: MaintainerFormClass },
  { path: "/class", exact: true, component: MaintainerListClass },

  { path: "/family/create", exact: true, component: MaintainerFormFamily },
  { path: "/family/edit/:id", exact: true, component: MaintainerFormFamily },
  { path: "/family/see/:id", exact: true, component: MaintainerFormFamily },
  { path: "/family", exact: true, component: MaintainerListFamily },

  { path: "/model/create", exact: true, component: MaintainerFormModel },
  { path: "/model/edit/:id", exact: true, component: MaintainerFormModel },
  { path: "/model/see/:id", exact: true, component: MaintainerFormModel },
  { path: "/model", exact: true, component: MaintainerListModel },

  { path: "/state/create", exact: true, component: MaintainerFormState },
  { path: "/state/edit/:id", exact: true, component: MaintainerFormState },
  { path: "/state/see/:id", exact: true, component: MaintainerFormState },
  { path: "/state", exact: true, component: MaintainerListProcutState },

  { path: "/subclass/create", exact: true, component: MaintainerFormSubclass },
  {
    path: "/subclass/edit/:id",
    exact: true,
    component: MaintainerFormSubclass,
  },
  { path: "/subclass/see/:id", exact: true, component: MaintainerFormSubclass },
  { path: "/subclass", exact: true, component: MaintainerListSubclass },

  { path: "/supplier/create", exact: true, component: MaintainerFormSupplier },
  {
    path: "/supplier/edit/:id",
    exact: true,
    component: MaintainerFormSupplier,
  },
  { path: "/supplier/see/:id", exact: true, component: MaintainerFormSupplier },
  { path: "/supplier", exact: true, component: MaintainerListSupplier },

  { path: "/element/create", exact: true, component: MaintainerFormElement },
  { path: "/element/edit/:id", exact: true, component: MaintainerFormElement },
  { path: "/element/see/:id", exact: true, component: MaintainerFormElement },
  { path: "/element", exact: true, component: MaintainerListElement },

  {
    path: "/element-type/create",
    exact: true,
    component: MaintainerFormElementType,
  },
  {
    path: "/element-type/edit/:id",
    exact: true,
    component: MaintainerFormElementType,
  },
  {
    path: "/element-type/see/:id",
    exact: true,
    component: MaintainerFormElementType,
  },
  { path: "/element-type", exact: true, component: MaintainerListElementType },

  {
    path: "/prototype-type/create",
    exact: true,
    component: MaintainerFormPrototypeType,
  },
  {
    path: "/prototype-type/edit/:id",
    exact: true,
    component: MaintainerFormPrototypeType,
  },
  {
    path: "/prototype-type/see/:id",
    exact: true,
    component: MaintainerFormPrototypeType,
  },
  {
    path: "/prototype-type",
    exact: true,
    component: MaintainerListPrototypeType,
  },

  {
    path: "/general-attribute/create",
    exact: true,
    component: MaintainerFormGeneralAttribute,
  },
  {
    path: "/general-attribute/edit/:id",
    exact: true,
    component: MaintainerFormGeneralAttribute,
  },
  {
    path: "/general-attribute/see/:id",
    exact: true,
    component: MaintainerFormGeneralAttribute,
  },
  {
    path: "/general-attribute",
    exact: true,
    component: MaintainerListGeneralAttribute,
  },

  {
    path: "/specific-attribute/create",
    exact: true,
    component: MaintainerFormSpecificAttribute,
  },
  {
    path: "/specific-attribute/edit/:id",
    exact: true,
    component: MaintainerFormSpecificAttribute,
  },
  {
    path: "/specific-attribute/see/:id",
    exact: true,
    component: MaintainerFormSpecificAttribute,
  },
  {
    path: "/specific-attribute",
    exact: true,
    component: MaintainerListSpecificAttribute,
  },

  {
    path: "/component/create",
    exact: true,
    component: MaintainerFormComponent,
  },
  {
    path: "/component/edit/:id",
    exact: true,
    component: MaintainerFormComponent,
  },
  {
    path: "/component/see/:id",
    exact: true,
    component: MaintainerFormComponent,
  },
  { path: "/component", exact: true, component: MaintainerListComponent },

  { path: "/matrices/hight", exact: true, component: Matrices },
  { path: "/matrices/middle-hight", exact: true, component: Matrices },
  { path: "/matrices/middle", exact: true, component: Matrices },
  { path: "/matrices/middle-low", exact: true, component: Matrices },
  { path: "/matrices/low", exact: true, component: Matrices },

  { path: "/projects", exact: true, component: Projects },

  { path: "/budget/edit/:id", exact: true, component: Budget },
  { path: "/budget/see/:id", exact: true, component: Budget },

  { path: "/history", exact: true, component: History },
  { path: "/works/toggle", exact: true, component: MaintainerFormWorks },
  { path: "/works", exact: true, component: MaintainerListWorks },
];

// Common routes for everyone
const authProtectedRoutes = [
  { path: "/changed-password", exact: true, component: ChangedPassword },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: ListProducts },
];

export const authProtectedRoutesGerenciaInmoviliaria = [
  { path: "/product-list", exact: true, component: ListProducts },
  { path: "/product/see/:id", exact: true, component: DetailsProducts },
  { path: "/product", exact: true, component: listProductsMantainer },

  {
    path: "/generic-product/see/:id",
    exact: true,
    component: DetailsGenericProducts,
  },
  { path: "/generic-product", exact: true, component: ListGenericProducts },

  { path: "/prototypes/catalog", exact: true, component: PrototypeCatalog },

  { path: "/projects", exact: true, component: Projects },

  { path: "/budget/edit/:id", exact: true, component: Budget },
  { path: "/budget/see/:id", exact: true, component: Budget },

  { path: "/matrices/hight", exact: true, component: Matrices },
  { path: "/matrices/middle-hight", exact: true, component: Matrices },
  { path: "/matrices/middle", exact: true, component: Matrices },
  { path: "/matrices/middle-low", exact: true, component: Matrices },
  { path: "/matrices/low", exact: true, component: Matrices },
];

export const authProtectedRoutesInmoviliaria = [
  { path: "/product-list", exact: true, component: ListProducts },
  { path: "/product/see/:id", exact: true, component: DetailsProducts },
  { path: "/product", exact: true, component: listProductsMantainer },

  {
    path: "/generic-product/see/:id",
    exact: true,
    component: DetailsGenericProducts,
  },
  { path: "/generic-product", exact: true, component: ListGenericProducts },

  { path: "/prototypes/catalog", exact: true, component: PrototypeCatalog },

  { path: "/projects", exact: true, component: Projects },

  { path: "/budget/edit/:id", exact: true, component: Budget },
  { path: "/budget/see/:id", exact: true, component: Budget },

  { path: "/matrices/hight", exact: true, component: Matrices },
  { path: "/matrices/middle-hight", exact: true, component: Matrices },
  { path: "/matrices/middle", exact: true, component: Matrices },
  { path: "/matrices/middle-low", exact: true, component: Matrices },
  { path: "/matrices/low", exact: true, component: Matrices },
];

export const authProtectedRoutesGerenciaPresupuesto = [
  { path: "/product-list", exact: true, component: ListProducts },
  { path: "/product/see/:id", exact: true, component: DetailsProducts },
  { path: "/product", exact: true, component: listProductsMantainer },

  {
    path: "/generic-product/see/:id",
    exact: true,
    component: DetailsGenericProducts,
  },
  { path: "/generic-product", exact: true, component: ListGenericProducts },

  { path: "/prototypes/catalog", exact: true, component: PrototypeCatalog },

  { path: "/projects", exact: true, component: Projects },

  { path: "/budget/edit/:id", exact: true, component: Budget },
  { path: "/budget/see/:id", exact: true, component: Budget },

  { path: "/matrices/hight", exact: true, component: Matrices },
  { path: "/matrices/middle-hight", exact: true, component: Matrices },
  { path: "/matrices/middle", exact: true, component: Matrices },
  { path: "/matrices/middle-low", exact: true, component: Matrices },
  { path: "/matrices/low", exact: true, component: Matrices },
];

export const authProtectedRoutesGerenciaOdi = [
  { path: "/product-list", exact: true, component: ListProducts },
  { path: "/product/see/:id", exact: true, component: DetailsProducts },
  { path: "/product", exact: true, component: listProductsMantainer },

  {
    path: "/generic-product/see/:id",
    exact: true,
    component: DetailsGenericProducts,
  },
  { path: "/generic-product", exact: true, component: ListGenericProducts },

  { path: "/prototypes/catalog", exact: true, component: PrototypeCatalog },

  { path: "/projects", exact: true, component: Projects },

  { path: "/budget/edit/:id", exact: true, component: Budget },
  { path: "/budget/see/:id", exact: true, component: Budget },

  { path: "/matrices/hight", exact: true, component: Matrices },
  { path: "/matrices/middle-hight", exact: true, component: Matrices },
  { path: "/matrices/middle", exact: true, component: Matrices },
  { path: "/matrices/middle-low", exact: true, component: Matrices },
  { path: "/matrices/low", exact: true, component: Matrices },
];

export const authProtectedRoutesOdi = [
  { path: "/product-list", exact: true, component: ListProducts },
  { path: "/product/create", exact: true, component: DetailsProducts },
  { path: "/product/edit/:id", exact: true, component: DetailsProducts },
  { path: "/product/see/:id", exact: true, component: DetailsProducts },
  { path: "/product", exact: true, component: listProductsMantainer },

  {
    path: "/generic-product/create",
    exact: true,
    component: DetailsGenericProducts,
  },
  {
    path: "/generic-product/edit/:id",
    exact: true,
    component: DetailsGenericProducts,
  },
  {
    path: "/generic-product/see/:id",
    exact: true,
    component: DetailsGenericProducts,
  },
  { path: "/generic-product", exact: true, component: ListGenericProducts },

  { path: "/prototypes/catalog", exact: true, component: PrototypeCatalog },

  { path: "/projects", exact: true, component: Projects },

  { path: "/budget/edit/:id", exact: true, component: Budget },
  { path: "/budget/see/:id", exact: true, component: Budget },

  { path: "/matrices/hight", exact: true, component: Matrices },
  { path: "/matrices/middle-hight", exact: true, component: Matrices },
  { path: "/matrices/middle", exact: true, component: Matrices },
  { path: "/matrices/middle-low", exact: true, component: Matrices },
  { path: "/matrices/low", exact: true, component: Matrices },
];

export const authProtectedRoutesCalidad = [
  { path: "/product-list", exact: true, component: ListProducts },
  { path: "/product/create", exact: true, component: DetailsProducts },
  { path: "/product/edit/:id", exact: true, component: DetailsProducts },
  { path: "/product/see/:id", exact: true, component: DetailsProducts },
  { path: "/product", exact: true, component: listProductsMantainer },

  {
    path: "/generic-product/create",
    exact: true,
    component: DetailsGenericProducts,
  },
  {
    path: "/generic-product/edit/:id",
    exact: true,
    component: DetailsGenericProducts,
  },
  {
    path: "/generic-product/see/:id",
    exact: true,
    component: DetailsGenericProducts,
  },
  { path: "/generic-product", exact: true, component: ListGenericProducts },

  { path: "/prototypes/catalog", exact: true, component: PrototypeCatalog },

  { path: "/projects", exact: true, component: Projects },

  { path: "/budget/see/:id", exact: true, component: Budget },

  { path: "/matrices/hight", exact: true, component: Matrices },
  { path: "/matrices/middle-hight", exact: true, component: Matrices },
  { path: "/matrices/middle", exact: true, component: Matrices },
  { path: "/matrices/middle-low", exact: true, component: Matrices },
  { path: "/matrices/low", exact: true, component: Matrices },

  { path: "/supplier/create", exact: true, component: MaintainerFormSupplier },
  {
    path: "/supplier/edit/:id",
    exact: true,
    component: MaintainerFormSupplier,
  },
  { path: "/supplier/see/:id", exact: true, component: MaintainerFormSupplier },
  { path: "/supplier", exact: true, component: MaintainerListSupplier },

  { path: "/brand/create", exact: true, component: MaintainerFormBrand },
  { path: "/brand/edit/:id", exact: true, component: MaintainerFormBrand },
  { path: "/brand/see/:id", exact: true, component: MaintainerFormBrand },
  { path: "/brand", exact: true, component: MaintainerListBrand },

  { path: "/model/create", exact: true, component: MaintainerFormModel },
  { path: "/model/edit/:id", exact: true, component: MaintainerFormModel },
  { path: "/model/see/:id", exact: true, component: MaintainerFormModel },
  { path: "/model", exact: true, component: MaintainerListModel },

  { path: "/state/create", exact: true, component: MaintainerFormState },
  { path: "/state/edit/:id", exact: true, component: MaintainerFormState },
  { path: "/state/see/:id", exact: true, component: MaintainerFormState },
  { path: "/state", exact: true, component: MaintainerListProcutState },

  { path: "/works/toggle", exact: true, component: MaintainerFormWorks },
  { path: "/works", exact: true, component: MaintainerListWorks },

  {
    path: "/element-type/create",
    exact: true,
    component: MaintainerFormElementType,
  },
  {
    path: "/element-type/edit/:id",
    exact: true,
    component: MaintainerFormElementType,
  },
  {
    path: "/element-type/see/:id",
    exact: true,
    component: MaintainerFormElementType,
  },
  { path: "/element-type", exact: true, component: MaintainerListElementType },

  {
    path: "/general-attribute/create",
    exact: true,
    component: MaintainerFormGeneralAttribute,
  },
  {
    path: "/general-attribute/edit/:id",
    exact: true,
    component: MaintainerFormGeneralAttribute,
  },
  {
    path: "/general-attribute/see/:id",
    exact: true,
    component: MaintainerFormGeneralAttribute,
  },
  {
    path: "/general-attribute",
    exact: true,
    component: MaintainerListGeneralAttribute,
  },

  {
    path: "/specific-attribute/create",
    exact: true,
    component: MaintainerFormSpecificAttribute,
  },
  {
    path: "/specific-attribute/edit/:id",
    exact: true,
    component: MaintainerFormSpecificAttribute,
  },
  {
    path: "/specific-attribute/see/:id",
    exact: true,
    component: MaintainerFormSpecificAttribute,
  },
  {
    path: "/specific-attribute",
    exact: true,
    component: MaintainerListSpecificAttribute,
  },

  { path: "/family/create", exact: true, component: MaintainerFormFamily },
  { path: "/family/edit/:id", exact: true, component: MaintainerFormFamily },
  { path: "/family/see/:id", exact: true, component: MaintainerFormFamily },
  { path: "/family", exact: true, component: MaintainerListFamily },

  { path: "/class/create", exact: true, component: MaintainerFormClass },
  { path: "/class/edit/:id", exact: true, component: MaintainerFormClass },
  { path: "/class/see/:id", exact: true, component: MaintainerFormClass },
  { path: "/class", exact: true, component: MaintainerListClass },

  { path: "/subclass/create", exact: true, component: MaintainerFormSubclass },
  {
    path: "/subclass/edit/:id",
    exact: true,
    component: MaintainerFormSubclass,
  },
  { path: "/subclass/see/:id", exact: true, component: MaintainerFormSubclass },
  { path: "/subclass", exact: true, component: MaintainerListSubclass },

  { path: "/element/create", exact: true, component: MaintainerFormElement },
  { path: "/element/edit/:id", exact: true, component: MaintainerFormElement },
  { path: "/element/see/:id", exact: true, component: MaintainerFormElement },
  { path: "/element", exact: true, component: MaintainerListElement },

  {
    path: "/component/create",
    exact: true,
    component: MaintainerFormComponent,
  },
  {
    path: "/component/edit/:id",
    exact: true,
    component: MaintainerFormComponent,
  },
  {
    path: "/component/see/:id",
    exact: true,
    component: MaintainerFormComponent,
  },
  { path: "/component", exact: true, component: MaintainerListComponent },
];

export const authProtectedRoutesGerencia = [
  { path: "/projects", exact: true, component: Projects },

  { path: "/budget/see/:id", exact: true, component: Budget },
];

export const authProtectedRoutesArquitectura = [
  { path: "/projects", exact: true, component: Projects },

  { path: "/budget/see/:id", exact: true, component: Budget },

  { path: "/matrices/hight", exact: true, component: Matrices },
  { path: "/matrices/middle-hight", exact: true, component: Matrices },
  { path: "/matrices/middle", exact: true, component: Matrices },
  { path: "/matrices/middle-low", exact: true, component: Matrices },
  { path: "/matrices/low", exact: true, component: Matrices },
];

export const authProtectedRoutesConstruccion = [
  { path: "/projects", exact: true, component: Projects },

  { path: "/budget/see/:id", exact: true, component: Budget },
];

export const authProtectedRoutesGeneral = [
  { path: "/prototypes/catalog", exact: true, component: PrototypeCatalog },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:code", component: ResetPassword },
  { path: "/forbidden", component: Forbidden },
];

export { authProtectedRoutes, publicRoutes, authProtectedRoutesAdmin };
