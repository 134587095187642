import { AvForm } from "availity-reactstrap-validation";
import { SelectInput, TextInput, useFetchSelect } from "components/Form";
import usePagination from "hooks/usePagination";
import React from "react";
import { Button, Col, Input, Row, UncontrolledCollapse } from "reactstrap";

export default function SearchBar() {
  const prototypetypes = useFetchSelect("prototypetypes");

  const { filters, updateFilters, fetchItems } = usePagination();

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchItems();
  };

  return (
    <>
      <Row>
        <Col className="mb-3 bg-primary rounded-border p-3">
          <form
            onSubmit={handleSubmit}
            className="d-flex flex-wrap flex-md-nowrap gap-3"
          >
            <Input
              name="quick_search"
              placeholder="Búsqueda rápida"
              onChange={(e) => updateFilters("quick_search", e.target.value)}
              value={filters.quick_search}
            />
            <Button type="submit">Buscar</Button>
          </form>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="d-grid">
            <Button id="filter-toggle">Filtros</Button>
          </div>
          <UncontrolledCollapse toggler="#filter-toggle" defaultOpen={true}>
            <AvForm id="filters" className="" onSubmit={fetchItems}>
              <Row xs={1} md={4} className="gy-3">
                <Col>
                  <SelectInput
                    name="type"
                    placeholder="Tipo de prototipo"
                    inline
                    noLabel
                    isMultiple
                    options={prototypetypes}
                    onChange={updateFilters}
                    value={filters.type}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <div className="">
                  <div className="d-grid">
                    <Button color="info" type="submit">
                      Filtrar
                    </Button>
                  </div>
                </div>
              </Row>
            </AvForm>
          </UncontrolledCollapse>
        </Col>
      </Row>

      {/* <Row className="mb-3 gap-3 align-items-center"> */}
      {/*   <Col */}
      {/*     sm={{ offset: 3, size: 9 }} */}
      {/*     className="text-white d-flex justify-content-between align-items-center" */}
      {/*   > */}
      {/*     <div className="ms-auto"> */}
      {/*       <Input type="select" onChange={() => {}} defaultValue={2}> */}
      {/*         <option>Ordenar por...</option> */}
      {/*         <option value={0}>Mayor a Menor Precio</option> */}
      {/*         <option value={1}>Menor a Mayor Precio</option> */}
      {/*         <option value={2}>Más Reciente</option> */}
      {/*         <option value={3}>Más Antiguo</option> */}
      {/*         <option value={4}>Alfabetico Ascendente</option> */}
      {/*         <option value={5}>Alfabetico Decendente</option> */}
      {/*       </Input> */}
      {/*     </div> */}
      {/*   </Col> */}
      {/* </Row> */}
    </>
  );
}
