import Select from "react-select";
import React from "react";

class CustomInputSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: null };
  }

  getValue() {
    if (this.props.keepOld) {
      return this.props.defaultValue;
    }
    return this.state.value;
  }

  componentDidMount() {
    if (this.props.showConfirmation === undefined) {
      return;
    }

    if (this.props.defaultValue === null) {
      return;
    }

    this.props.showConfirmation();
  }

  componentDidUpdate() {
    if (this.props.keepOld === true) {
      this.props.onUpdate(this.props.defaultValue);
    }
  }

  render() {
    const { value, onUpdate, options, filter, ...rest } = this.props;
    return (
      <Select
        {...rest}
        onChange={(e) => this.setState({ value: e.value })}
        value={value}
        options={filter ? options.filter(filter) : options}
        isClearable
        placeholder={"Ingrese un valor..."}
      />
    );
  }
}

export default CustomInputSelect;
