import React, { useEffect, useState } from "react";
import { get } from "helpers/ApiHelper";
import { Row, Col, Button, Alert } from "reactstrap";
import { useForm } from "../../Form/";
import DualListBox from "components/Form/DualListBox";

const FormNotifications = ({ project_id, closeButton }) => {
  const [data, setData] = useState({});
  const { requestMessage, errors, sendForm, clearMsg } = useForm();

  useEffect(() => {
    if (project_id) {
      fetchData(project_id);
    } else {
      clearMsg();
    }
  }, [project_id, clearMsg]);

  const fetchData = async (project_id) => {
    let response = await get("notifications/" + project_id);

    if (!response) return;

    const users = response.data;

    Object.values(users).forEach((value) => {
      value.included = value.included.map((item) => ({
        value: item.id,
        label: `${item.name} ${item.lastname}`,
      }));

      value.notIncluded = value.notIncluded.map((item) => ({
        value: item.id,
        label: `${item.name} ${item.lastname}`,
      }));

      value.all = [...value.included, ...value.notIncluded];

      value.included = value.included.map((item) => item.value);
    });
    setData({
      ...users,
    });
  };

  const sendData = async () => {
    const formattedData = {
      project_id: project_id,
      users_ids: Object.values(data).flatMap((value) => value.included),
    };

    await sendForm("notifications", formattedData);
  };

  const handleInput = (name, value) => {
    setData((prevData) => {
      const item = prevData[name];
      item.included = value;

      return {
        ...prevData,
      };
    });
  };

  if (!project_id) return <></>;

  return (
    <div className="vstack gap-3">
      <DualListBox
        name="gerInm"
        label="Gerente Inmobiliaria"
        options={data.gerInm?.all}
        onChange={handleInput}
        value={data.gerInm?.included}
        error={null}
      />
      <DualListBox
        name="gerODI"
        label="Gerente ODI"
        options={data.gerODI?.all}
        onChange={handleInput}
        value={data.gerODI?.included}
        error={null}
      />
      <DualListBox
        name="gerPRS"
        label="Gerente Presupuesto"
        options={data.gerPRS?.all}
        onChange={handleInput}
        value={data.gerPRS?.included}
        error={null}
      />
      <DualListBox
        name="userODI"
        label="Usuario ODI"
        options={data.userODI?.all}
        onChange={handleInput}
        value={data.userODI?.included}
        error={null}
      />
      {requestMessage && (
        <Row>
          <Alert color={Object.keys(errors).length ? "danger" : "success"}>
            {requestMessage}
          </Alert>
        </Row>
      )}
      <Row>
        <Col className="d-grid">{closeButton}</Col>
        {project_id && (
          <Col className="d-grid">
            <Button color="info" size="lg" onClick={sendData}>
              Guardar
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default FormNotifications;
