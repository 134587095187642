import React, { useEffect, useState } from "react";
import { API_URL, get } from "helpers/ApiHelper";
import ReactPlayer from "react-player/youtube";
import NumberFormat from "react-number-format";
import {
  Alert,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";

const ProductModal = ({ toggle, isOpen, product, typereviews }) => {
  const [component, setComponent] = useState(null);
  const [orders, setOrders] = useState(null);

  const [imageModal, setImageModal] = useState();

  useEffect(() => {
    setOrders(null);
    setComponent(null);

    if (!isOpen) return;

    product.icomponent.forEach((item) => {
      get(`components/${item.icode}/purchase-history`).then((response) => {
        if (response.status !== 200) return;

        setOrders((prev) => ({ ...prev, [item.icode]: response.data }));
      });
    });

    if (!product.component_id) return;

    get(`components/${product.component_id}?full=true`).then((response) =>
      setComponent(response.status === 200 ? response.data : {})
    );
  }, [product, isOpen]);

  if (!product) return <></>;

  const sections = [
    { name: "description", label: "Descripción" },
    { name: "file", label: "Ficha" },
    { name: "history", label: "Historial de Compras" },
    { name: "request", label: "Solicitud" },
  ];

  const priceFormatter = (value, currency, componets) => {
    return componets.every((item) => item.original_currency === currency) ? (
      <span className="bg-secondary text-white ms-1 px-1 rounded">
        <NumberFormat
          prefix=""
          value={value}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={currency === "CLP" ? 0 : 2}
        />
      </span>
    ) : (
      <NumberFormat
        prefix=" "
        value={value}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={currency === "CLP" ? 0 : 2}
      />
    );
  };

  const toggleImageModal = (link) => {
    setImageModal((prev) => (prev ? "" : link));
  };

  return (
    <Modal
      id="product-modal"
      centered
      size="xl"
      toggle={toggle}
      isOpen={isOpen}
    >
      <Modal
        id="image-modal"
        centered
        size="xl"
        toggle={toggleImageModal}
        isOpen={imageModal}
      >
        <ModalHeader
          tag="h3"
          className="text-center"
          toggle={toggleImageModal}
        ></ModalHeader>
        <ModalBody>
          <img className="w-100" src={API_URL + imageModal} alt="img" />
        </ModalBody>
      </Modal>
      <ModalHeader tag="h3" className="text-center" toggle={toggle}>
        Ficha de Producto
      </ModalHeader>
      <ModalBody className="row justify-content-between">
        <section className="col-12 col-lg-5">
          <div className="image-collection images">
            {product &&
              product.images.map((item, idx) => (
                <div
                  key={idx}
                  onClick={() => toggleImageModal(item.link)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="thumbnail-scale">
                    <img src={API_URL + item.link} alt="img" />
                  </div>
                </div>
              ))}
          </div>
        </section>
        <section className="col-12 col-lg-7 vstack gap-3">
          <div id="header">
            <section className="hstack gap-2 justify-content-between">
              <h3 className="me-auto">{product.name}</h3>
              <span
                className={
                  "result-" +
                  product.productresult?.name.toLowerCase().replace(" ", "-")
                }
              >
                {product.productresult?.name}
              </span>
              <span
                className={
                  "commercialstatus-" +
                  product.commercialstatus?.name.toLowerCase().replace(" ", "-")
                }
              >
                {product.commercialstatus?.name}
              </span>
            </section>
            <section className="hstack gap-3">
              <h4>
                CLP:
                {priceFormatter(product.price_clp, "CLP", product.icomponent)}
              </h4>
              <h4>
                UF:
                {priceFormatter(product.price_uf, "UF", product.icomponent)}
              </h4>
              <h4>
                USD:
                {priceFormatter(product.price_usd, "USD", product.icomponent)}
              </h4>
              <h4>
                EUR:
                {priceFormatter(product.price_eur, "EUR", product.icomponent)}
              </h4>
            </section>
            <h4 className="mt-3">Segmentos</h4>
            <Row
              xs={6}
              tag="section"
              className="gap-2 ms-0 text-white text-center"
            >
              {product.segments.map((item, idx) => (
                <span key={idx} className="bg-secondary rounded p-2">
                  {item.name}
                </span>
              ))}
            </Row>
            <hr className="w-100" />
            <section>
              <Nav
                justified
                pills
                className="flex-nowrap overflow-auto text-nowrap"
              >
                {sections.map((item, idx) => (
                  <NavItem key={idx}>
                    <NavLink href={"#" + item.name}>{item.label}</NavLink>
                  </NavItem>
                ))}
              </Nav>
            </section>
          </div>

          <div className="content">
            <div id="commercial-status">
              <section className="hstack justify-content-between">
                <h4>Estado Comercial</h4>
              </section>
              <section>{product.commercialstatus.description}</section>
            </div>

            <hr className="w-100" />

            <div id="description">
              <h4>Información de Producto</h4>
              <Row>
                <Col xs={3}>Modelo:</Col>
                <Col>{product.model.name}</Col>
              </Row>
              <Row>
                <Col xs={3}>Código:</Col>
                <Col>{product.code_supplier}</Col>
              </Row>
              <Row>
                <Col xs={3}>Marca:</Col>
                <Col>{product.model?.brand.name}</Col>
              </Row>
              <Row>
                <Col xs={3}>Proveedores:</Col>
                <Col>
                  {product.model?.brand?.suppliers
                    .map((item) => item.name)
                    .join(" - ")}
                </Col>
              </Row>
              <h4 className="mt-3">Descripción</h4>
              <section>{product.details}</section>
            </div>

            <hr className="w-100" />

            <div id="file">
              <section className="vstack gap-2">
                <Row>
                  <Col xs={3}>Página web: </Col>
                  <Col>
                    <a href={product.url} target="_blank" rel="noreferrer">
                      {product.url}
                    </a>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs={3}>Ficha técnica:</Col>
                </Row>
                <div className="vstack gap-3">
                  {product.productfiles.map((item, idx) => (
                    <Alert
                      color="secondary"
                      key={idx}
                      className="mb-0"
                    >
                      <Row className="align-items-center">
                        <Col xs="3">
                          <strong>Nombre del archivo:</strong>
                        </Col>
                        <Col>{item.name}</Col>
                        <Col xs="2">
                          <a
                            href={item.link ? API_URL + item.link : ""}
                            target="_blank"
                            download
                            rel="noreferrer"
                          >
                            <i className="fas fa-download fs-3"></i>
                          </a>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs="3">
                          <strong>Descripción:</strong>
                        </Col>
                        <Col>{item.description}</Col>
                      </Row>
                    </Alert>
                  ))}
                </div>
              </section>
            </div>
            <hr className="w-100" />
            <div id="video">
              <h4>Vídeo</h4>
              {product.url_video ? (
                <ReactPlayer light width="100%" url={product.url_video} />
              ) : (
                <Alert color="secondary" className="mt-3">
                  El producto no posee vídeo
                </Alert>
              )}
            </div>

            <hr className="w-100" />

            <div id="history">
              <h4>Historial de Compra</h4>
              {orders !== null ? (
                Object.entries(orders).map(([key, value]) => (
                  <div key={key} className="mb-2">
                    <h5>Código Iconstruye: {key}</h5>
                    <h6>
                      Nombre Iconstruye: {value.lastPurchase.NombreArticulo}
                    </h6>
                    <div className="w-100 d-flex flex-wrap">
                      <h6 className="me-auto">
                        Último proyecto:{" "}
                        {value.lastPurchase.NombreCentroGestion}
                      </h6>
                      <h6>
                        Fecha última compra:{" "}
                        {value.lastPurchase.FechaCreacionOC}
                      </h6>
                    </div>
                    <small>{value.workNames.join(", ")}</small>
                  </div>
                ))
              ) : (
                <Alert color="secondary" className="mt-3">
                  Producto nuevo. No tiene historial de compra.
                </Alert>
              )}
            </div>

            <hr className="w-100" />

            <div id="classification">
              <h4>Clasificación de Producto</h4>
              {component && (
                <>
                  <Row>
                    <Col xs={3} className="fw-bold">
                      Familia
                    </Col>
                    <Col>
                      {component.element?.subclass?.class?.family?.name}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3} className="fw-bold">
                      Clase
                    </Col>
                    <Col>{component?.element?.subclass?.class?.name}</Col>
                  </Row>
                  <Row>
                    <Col xs={3} className="fw-bold">
                      Subclase
                    </Col>
                    <Col>{component?.element?.subclass?.name}</Col>
                  </Row>
                  <Row>
                    <Col xs={3} className="fw-bold">
                      Elemento
                    </Col>
                    <Col>{component?.element?.name}</Col>
                  </Row>
                  <Row>
                    <Col xs={3} className="fw-bold">
                      Componente
                    </Col>
                    <Col>{component.name}</Col>
                  </Row>
                </>
              )}
            </div>

            <hr className="w-100" />

            <div id="result">
              <section className="hstack justify-content-between">
                <h4>Resultado de Solicitud a Calidad</h4>
              </section>
              <section>{product.comment_result}</section>
            </div>

            <hr className="w-100" />

            <div id="request">
              <section className="hstack justify-content-between">
                <h4>Revisión de Calidad</h4>
              </section>
              <section className="vstack ">
                {typereviews.map((item, idx) => (
                  <FormGroup key={idx} check>
                    <Input
                      type="checkbox"
                      readOnly
                      checked={product.typereviews
                        .map((item) => item.id)
                        .includes(item.value)}
                    />
                    <Label>{item.label}</Label>
                  </FormGroup>
                ))}
              </section>

              <section>{product.comment_status}</section>
            </div>
          </div>
        </section>
      </ModalBody>
    </Modal>
  );
};

export default ProductModal;
