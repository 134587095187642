import React, { useContext } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { Row, Col, Button } from "reactstrap";
import { DataContext } from "./List";
import { checkRole } from "helpers/getUser";

import { CustomInputNumber, CustomInputSelect } from "components/Table/Inputs/";
import NumberFormat from "react-number-format";

const NoData = () => {
  return <div className="text-center">No hay datos</div>;
};

const Table = ({ zone_idx, category_idx }) => {
  const {
    data,
    setData,
    unitmeasurements,
    typeelements,
    generalattributes,
    specificattributes,
  } = useContext(DataContext);

  const items = data[zone_idx].categories[category_idx].elementtypes;

  if (!items || !generalattributes || !specificattributes) return <></>;

  const roles = checkRole([
    "Administrador",
    "Inmobiliaria",
    "Gerencia Presupuesto",
    "Gerencia ODI",
    "ODI",
  ]);

  const appendRow = () => {
    setData((prev) => {
      const lastElement =
        prev[zone_idx].categories[category_idx].elementtypes.at(-1);
      prev[zone_idx].categories[category_idx].elementtypes.push({
        name: "",
        pos: lastElement.pos + 1,
        typeelement: {
          name: "",
        },
        elementdetail: {
          general_attribute: "",
          specific_attribute: "",
          unit_measurement: "",
          budged: -1,
        },
      });
      return [...prev];
    });
  };

  const removeRow = (idx) => {
    setData((prev) => {
      prev[zone_idx].categories[category_idx].elementtypes.splice(idx, 1);

      prev[zone_idx].categories[category_idx].elementtypes = prev[
        zone_idx
      ].categories[category_idx].elementtypes.map((item, idx) => ({
        ...item,
        pos: idx + 1,
      }));

      return [...prev];
    });
  };

  const editRow = (_oldValue, newValue, row, column, done) => {
    if (!newValue) return { async: true };
    setData((prev) => {
      const idx = items.findIndex((item) => row.pos === item.pos);

      const keys = column.dataField.split(".");

      if (column.dataField === "typeelement_id") {
        const match = typeelements.find((item) => item.value === newValue);

        prev[zone_idx].categories[category_idx].elementtypes[
          idx
        ].typeelement_id = match.value;
      } else if (column.dataField === "elementdetail.unit_measurement") {
        const match = unitmeasurements.find(
          (item) => Number(item.value) === Number(newValue)
        );

        prev[zone_idx].categories[category_idx].elementtypes[
          idx
        ].elementdetail.unit_measurement = match.label;
      } else if (keys.length === 1) {
        prev[zone_idx].categories[category_idx].elementtypes[idx][keys[0]] =
          newValue;
      } else {
        prev[zone_idx].categories[category_idx].elementtypes[idx][keys[0]][
          keys[1]
        ] = newValue;
      }

      done(false);

      return [...prev];
    });

    return { async: true };
  };

  const changePosition = (row, value) => {
    if (row.pos <= 1 && value < 0) return;
    if (row.pos >= items.length && value > 0) return;

    setData((prev) => {
      const idx = items.findIndex((item) => row.pos === item.pos);

      if (value === 1) {
        prev[zone_idx].categories[category_idx].elementtypes[idx].pos += 1;
        prev[zone_idx].categories[category_idx].elementtypes[idx + 1].pos -= 1;
      } else if (value === -1) {
        prev[zone_idx].categories[category_idx].elementtypes[idx].pos -= 1;
        prev[zone_idx].categories[category_idx].elementtypes[idx - 1].pos += 1;
      }

      prev[zone_idx].categories[category_idx].elementtypes.sort(
        (a, b) => a.pos - b.pos
      );

      // Fix position missing a place
      prev[zone_idx].categories[category_idx].elementtypes = prev[
        zone_idx
      ].categories[category_idx].elementtypes.map((item, idx) => ({
        ...item,
        pos: idx + 1,
      }));

      return [...prev];
    });
  };

  const columns = [
    {
      dataField: "typeelement_id",
      text: "Tipo de Elemento",
      editorRenderer: (editorProps) => (
        <CustomInputSelect
          {...editorProps}
          name="specific-attribute"
          options={typeelements}
        />
      ),
      formatter: (cell) => {
        if (!cell) return "";
        if (!typeelements.length) return "";

        return typeelements.find((item) => item.value === cell).label;
      },
    },
    {
      dataField: "elementdetail.generalattribute_id",
      editorRenderer: (editorProps, _value, row) => (
        <CustomInputSelect
          {...editorProps}
          name="general-attribute"
          options={generalattributes}
          filter={(item) =>
            row.typeelement_id && item.typeelement_id === row.typeelement_id
          }
        />
      ),
      formatter: (cell) => {
        if (!cell) return "";
        if (!generalattributes.length) return "";

        return generalattributes.find((item) => item.value === cell).label;
      },
      text: "Atributo General",
    },
    {
      dataField: "elementdetail.specificattribute_id",
      editorRenderer: (editorProps, _value, row) => (
        <CustomInputSelect
          {...editorProps}
          name="specific-attribute"
          options={specificattributes}
          filter={(item) =>
            row.elementdetail?.generalattribute_id &&
            item.generalattribute_id === row.elementdetail?.generalattribute_id
          }
        />
      ),
      formatter: (cell) => {
        if (!cell) return "";
        if (!specificattributes.length) return "";

        return specificattributes.find((item) => item.value === cell).label;
      },
      text: "Atributo Especifico",
    },
    {
      dataField: "elementdetail.budget",
      text: "Presupuesto",
      customType: "number",
      editorRenderer: (editorProps) => <CustomInputNumber {...editorProps} />,
      formatter: (cell) => (
        <NumberFormat
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={3}
          fixedDecimalScale
          isNumericString
          prefix="UF "
          value={cell}
          displayType="text"
        />
      ),
    },
    {
      dataField: "elementdetail.unit_measurement",
      text: "Unidad",
      editor: {
        type: Type.SELECT,
        options: unitmeasurements,
      },
    },
    {
      dataField: "pos",
      text: "Posición",
      editable: false,
      hidden: !roles,
      formatter: (_, row) => (
        <div className="vstack gap-1">
          <Button
            outline
            color="dark"
            size="sm"
            onClick={() => changePosition(row, -1)}
          >
            {" "}
            <i className="fas fa-angle-up" />{" "}
          </Button>
          <Button
            outline
            color="dark"
            size="sm"
            onClick={() => changePosition(row, 1)}
          >
            {" "}
            <i className="fas fa-angle-down" />{" "}
          </Button>
        </div>
      ),
    },
    {
      dataField: "asd",
      text: "",
      editable: false,
      hidden: !roles,
      formatter: (_a, _b, idx) => (
        <div className="">
          <Button
            size="sm"
            outline
            color="danger"
            onClick={() => removeRow(idx)}
          >
            <i className="fas fa-minus" />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Row className="m-0 align-items-end">
      <Col className="p-0">
        <BootstrapTable
          keyField="pos"
          sort={{ dataField: "pos", order: "asc" }}
          wrapperClasses="w-100 h-100"
          classes="m-0"
          headerWrapperClasses="text-center text-nowrap"
          data={items}
          columns={columns}
          hover
          condensed
          rowClasses={(row) => row.error && "array-bg-danger text-white"}
          noDataIndication={NoData}
          {...{
            ...(roles && {
              cellEdit: cellEditFactory({
                mode: "click",
                blurToSave: true,
                beforeSaveCell: editRow,
              }),
            }),
          }}
        />
      </Col>
      <Col xs={1} className="p-1 hstack gap-2 justify-content-center mt-auto">
        {roles && (
          <Button size="sm" outline color="success" onClick={appendRow}>
            <i className="fas fa-plus"></i>
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default Table;
