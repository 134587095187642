import React from "react";
import { Button, Row, Col } from "reactstrap";

const Popover = ({ id, status, values, typereviews }) => {
  const small_screen = window.innerWidth < 992;

  ++id;

  let id_type;

  if (id % 3 === 0) {
    // right aligned
    id_type = 3;
  } else if ((id + 2) % 3 === 0) {
    // right aligned
    id_type = 1;
  } else {
    // center
    id_type = 2;
  }

  return (
    <Button
      className={
        "tooltip-hover result-" + status.toLowerCase().replace(" ", "-")
      }
      size="sm"
    >
      {status}
      <Row
        lg={5}
        className={`flex-nowrap tooltiptext-hover id-${id_type} p-2 text-start text-lg-center lh-sm`}
      >
        {typereviews.map((item, idx) => (
          <React.Fragment key={idx}>
            <Col
              className={
                small_screen
                  ? "h-100 hstack justify-content-between"
                  : "h-100 vstack justify-content-between"
              }
            >
              <span className="text-wrap fw-normal">
                {item.label.toUpperCase()}
              </span>
              <div className="hstack position-relative">
                {values.includes(item.value) ? (
                  <div className="icon border-info">
                    <i className="fas fa-check text-info" />
                  </div>
                ) : (
                  <div className="icon">
                    <i className="fas fa-times" />
                  </div>
                )}
                {idx !== typereviews.length - 1 && !small_screen && (
                  <div className="vr" />
                )}
              </div>
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </Button>
  );
};

export default Popover;
