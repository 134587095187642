import { useCallback, useState } from "react";
import CheckInput from "./CheckInput";
import FileInput from "./FileInput";
import NumberInput from "./NumberInput";
import SelectInput, { useFetchSelect } from "./SelectInput";
import TextAreaInput from "./TextAreaInput";
import TextInput from "./TextInput";
import DualListBox from "./DualListBox";
import DateInput from "./DateInput";
import { post, put } from "../../helpers/ApiHelper";

const useForm = () => {
  const [requestMessage, setRequestMessage] = useState("");
  const [errors, setErrors] = useState(false);
  const [status, setStatus] = useState(0);

  const clearMsg = useCallback(() => {
    if (requestMessage) setRequestMessage("");
  }, [requestMessage]);

  const sendForm = async (endpoint, data, id) => {
    console.log(data);
    setRequestMessage("");
    const response =
      id !== undefined
        ? await put(endpoint + "/" + id, data)
        : await post(endpoint, data);

    setErrors(response.status !== 200 ? response.data : false);
    setStatus(response.status);
    setRequestMessage(response.message);

    return response.status === 200 ? response.data : null;
  };

  return { requestMessage, status, errors, sendForm, clearMsg };
};

export {
  CheckInput,
  DualListBox,
  FileInput,
  NumberInput,
  SelectInput,
  TextAreaInput,
  TextInput,
  DateInput,
  useFetchSelect,
  useForm,
};
