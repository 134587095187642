import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import FormNotifications from "./FormNotifications";

const ModalNotifications = ({ open, toggle, project }) => {
  if (!project) return <></>;

  const closeButton = (
    <Button color="secondary" size="lg" onClick={toggle}>
      Cerrar
    </Button>
  );

  return (
    <Modal size="lg" isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Lista de Notificaciones</ModalHeader>
      <ModalBody className="">
        <FormNotifications project_id={project.id} closeButton={closeButton} />
      </ModalBody>
    </Modal>
  );
};

export default ModalNotifications;
