import { AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { TextAreaInput, useForm } from "../../Form/";
import { get } from "helpers/ApiHelper";

const ModalComments = ({ open, toggle, budget_id, can_send = true }) => {
  const [data, setData] = useState([]);
  const [comment, setComment] = useState("");
  const { requestMessage, errors, sendForm, clearMsg } = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (budget_id) {
      fetchData(budget_id);
    } else {
      clearMsg();
    }
  }, [budget_id, clearMsg]);

  const fetchData = async (id) => {
    let response = await get(`budgets/${id}/comments`);
    setData(response.data);
  };

  const sendData = async () => {
    setLoading(true);
    const user = JSON.parse(window.localStorage.getItem("authUser"));
    const response = await sendForm("budgets/comments", {
      id: budget_id,
      user_id: user.id,
      comment,
    });

    if (!response) {
      setLoading(false);
      return;
    }
      
    setComment(null);
    setLoading(false);

    await fetchData(budget_id);
  };

  if (!budget_id) return <></>;

  return (
    <Modal size="lg" isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Comentarios 1</ModalHeader>
      <ModalBody className="vstack gap-3">
        <div className="comments-box">
          {data.map((item, idx) => (
            <div className="comment-item" key={idx}>
              <div className="comment-info">
                <span>{`${item.user.name} ${item.user.lastname}`}</span>
                <span>{new Date(item.createdAt).toLocaleString()}</span>
              </div>
              <p className="comment-content">{item.comment}</p>
            </div>
          ))}
        </div>
        <AvForm onValidSubmit={sendData}>
          <Row className="align-items-center">
            <Col>
              <TextAreaInput
                name="comment"
                value={comment}
                onChange={(_, value) => setComment(value)}
                placeholder="Ingrese un comentario..."
                error={errors.comment}
                required={false}
                noLabel
                noRow
              />
            </Col>
            <Col xs="2" className="">
              <Button
                color="info"
                type="submit"
                className="w-100"
                disables={can_send || loading}
                disabled={loading}
              >
                Enviar
              </Button>
            </Col>
          </Row>
        </AvForm>
        {requestMessage && (
          <Row>
            <Col>
              <Alert color={Object.keys(errors).length ? "danger" : "success"}>
                {requestMessage}
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="d-grid">
            <Button color="secondary" size="lg" onClick={toggle}>
              Cerrar
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ModalComments;
