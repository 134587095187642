import React, { useContext } from "react";
import { Col, Row } from "reactstrap";
import Table from "./Table";
import { DataContext } from "./Content";

const Category = ({ zone_idx, category_idx }) => {
  const { data } = useContext(DataContext);

  const item = data[zone_idx].categoriesbudget[category_idx];

  return (
    <>
      <Row className="fw-bold fs-5 m-0 ">
        <Col className="bg-secondary text-white border border-top-0 border-dark">
          {item.name}
        </Col>
      </Row>
      <Table
        zone_idx={zone_idx}
        category_idx={category_idx}
        data={item.elementtypesbudgets}
      />
    </>
  );
};

export default Category;
