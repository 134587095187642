import React, { Component } from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import Content from "../../components/Sections/Products/ListProducts";
import { Link } from "react-router-dom";

class ListProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    this.setState({
      user: JSON.parse(window.localStorage.getItem("authUser")),
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className={this.state.user ? "page-content" : "page-content pt-3"}>
          <MetaTags>
            <title>
              {process.env.REACT_APP_PAGE_TITLE} | Lista de Productos
            </title>
          </MetaTags>
          <Container fluid>
            <div className="home-btn">
              <Link to="/login" className="text-dark">
                <i className="bx bx-user h2" />
              </Link>
            </div>
            <Content />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ListProducts;
