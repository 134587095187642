import React, { useState, useEffect } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert } from "reactstrap";
import { TextAreaInput, TextInput, SelectInput } from "../../../Form/";
import { get, post, put } from "helpers/ApiHelper";
import { useParams, Link } from "react-router-dom";
import { useFetchSelect } from "components/Form";

const Content = () => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const [data, setData] = useState({});
  const families = useFetchSelect("families");
  const [requestMessage, setRequestMessage] = useState("");
  const [requestOk, setRequestOk] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    const response = await get("classes/" + id);
    setData(response.data);
    setRequestMessage("");
  };

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const sendForm = async () => {
    setRequestMessage("");
    console.table(data);
    const response = id
      ? await put("classes/" + id, data)
      : await post("classes", data);

    setRequestOk(response.status === 200);
    setErrors(response.status !== 200 ? response.data : {});
    setRequestMessage(response.message);
  };

  const renderTitle = () => {
    let text;
    if (see) {
      text = "Ver";
    } else if (id) {
      text = "Editar";
    } else {
      text = "Crear";
    }

    return <h4 className="text-white">Clases / {text}</h4>;
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>{renderTitle()}</Col>
      </Row>
      <Row className="p-2">
        <AvForm onValidSubmit={sendForm}>
          <fieldset>
            <h3>Detalle clases</h3>
            <TextInput
              name="name"
              label="Nombre"
              placeholder="-- Ingrese nombre --"
              onChange={handleInput}
              value={data.name}
              error={errors.name}
              disabled={see}
            />
            <SelectInput
              name="family_id"
              label="Familia"
              placeholder="-- Ingrese familia --"
              options={families}
              onChange={handleInput}
              value={data.family_id}
              error={errors.family_id}
              disabled={see}
            />
            <TextAreaInput
              name="description"
              label="Descripción"
              placeholder="-- Ingrese descripción --"
              onChange={handleInput}
              value={data.description}
              error={errors.description}
              disabled={see}
              required={false}
            />
            {requestMessage && (
              <Row>
                <Alert color={requestOk ? "success" : "danger"}>
                  {requestMessage}
                </Alert>
              </Row>
            )}
            <Row>
              <Col className="d-grid">
                <Link to="/class" className="d-grid">
                  <Button color="info" size="lg">
                    Volver a la lista
                  </Button>
                </Link>
              </Col>
              {!see && (
                <Col className="d-grid">
                  <Button type="submit" size="lg" color="info">
                    Guardar
                  </Button>
                </Col>
              )}
            </Row>
          </fieldset>
        </AvForm>
      </Row>
    </React.Fragment>
  );
};

export default Content;
