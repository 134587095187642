import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { NumberInput } from "components/Form";
import { TextInput } from "components/Form";
import React from "react";
import { Row } from "reactstrap";

const Summary = ({ data }) => {
  if (!data) return;

  const formatValidations = () => {
    let string = "";

    string += data.validationGPR ? "GPR" : "";
    string += data.validationODI ? "-ODI" : "";
    string += data.validationIMB ? "-IMB" : "";

    return string;
  };

  return (
    <fieldset className="pb-3">
      <AvForm className="vstack gap-3">
        <Row className="align-items-center">
          <TextInput
            name="name"
            label="Nombre proyecto"
            value={data.name}
            disabled={true}
            noRow
          />
          <TextInput
            name="code"
            label="Código proyecto"
            value={data.code}
            disabled={true}
            noRow
          />
        </Row>
        <Row className="align-items-center">
          <TextInput
            name="segment"
            label="Segmento"
            value={data.segment?.name}
            disabled={true}
            noRow
          />
          <TextInput
            name="comune"
            label="Comuna"
            value={data.commune?.name}
            disabled={true}
            noRow
          />
        </Row>
        <Row className="align-items-center">
          <TextInput
            name="array_version"
            label="Versión matriz"
            value={data.array_version}
            disabled={true}
            noRow
          />
          <TextInput
            name="code"
            label="Fecha matriz"
            value={new Date(data.array_date).toLocaleDateString()}
            disabled={true}
            noRow
          />
        </Row>
        <Row className="align-items-center">
          <TextInput
            name="budget_version"
            label="Versión presupuesto"
            value={data.budget_version}
            disabled={true}
            noRow
          />
          <TextInput
            name="budget_date"
            label="Fecha presupuesto"
            value={new Date(data.budget_date).toLocaleDateString()}
            disabled={true}
            noRow
          />
        </Row>
        <Row className="align-items-center">
          <NumberInput
            name="budget"
            label="Presupuesto"
            prefix="$"
            value={data.budget_amount}
            disabled={true}
            onChange={() => { }}
            noRow
          />
          <NumberInput
            name="total"
            label="Total"
            prefix="$"
            value={data.total}
            onChange={() => { }}
            disabled={true}
            noRow
          />
        </Row>
        <Row className="align-items-center">
          <NumberInput
            name="budgetUF"
            label="Presupuesto UF"
            prefix="UF"
            value={data.budget_amount_uf}
            disabled={true}
            onChange={() => { }}
            noRow
          />
          <NumberInput
            name="totalUF"
            label="Total UF"
            prefix="UF"
            value={data.totalUF}
            decimals={2}
            onChange={() => { }}
            disabled={true}
            noRow
          />
        </Row>
      </AvForm>
    </fieldset>
  );
};

export default Summary;
