import { AvForm } from "availity-reactstrap-validation";
import { checkRole } from "helpers/getUser";
import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { SelectInput, TextAreaInput, useForm } from "../../Form/";

const ModalState = ({ open, toggle, budget_id, update }) => {
  const [data, setData] = useState({});
  const { requestMessage, errors, sendForm, clearMsg } = useForm();

  const CanSelectState = checkRole([
    "Gerente Inmobiliaria",
    "Gerencia Presupuesto",
    "Gerencia ODI",
    "Administrador",
  ]);

  useEffect(() => {
    if (!open) {
      setData({});
      clearMsg();
    }
  }, [open, clearMsg]);

  const sendData = async () => {
    await sendForm(
      "budgets/statecontrol",
      {
        ...data,
        state_id: data.state_id || 2,
        id: budget_id,
      },
      ""
    );
    await update();
    await toggle();
  };

  const renderTitle = () => {
    return "Enviar presupuesto";
  };

  const handleChangeInput = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  if (!budget_id) return <></>;

  return (
    <Modal size="lg" isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{renderTitle()}</ModalHeader>
      <ModalBody className="">
        <AvForm onValidSubmit={sendData} className="vstack gap-3">
          {CanSelectState && (
            <SelectInput
              name="state_id"
              label="Estado"
              onChange={handleChangeInput}
              value={data.state_id}
              error={errors.state_id}
              options={[
                { label: "Aprobado", value: 2 },
                { label: "Rechazado", value: 3 },
              ]}
            />
          )}
          <TextAreaInput
            name="comment"
            value={data.comment}
            onChange={handleChangeInput}
            error={errors.comment}
            label="Comentario:"
            placeholder="Ingrese un comentario..."
            noRow
            rows={6}
          />
          {requestMessage && (
            <Row>
              <Alert color={Object.keys(errors).length ? "danger" : "success"}>
                {requestMessage}
              </Alert>
            </Row>
          )}
          <Row>
            <Col className="d-grid">
              <Button color="secondary" size="lg" onClick={toggle}>
                Cerrar
              </Button>
            </Col>
            <Col className="">
              <Button color="success" type="submit" className="w-100">
                Enviar
              </Button>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default ModalState;
