import React, { useState, useEffect } from "react";
import { SelectInput, NumberInput, DualListBox } from "components/Form/";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert, Label, Collapse } from "reactstrap";
import { useParams, Link } from "react-router-dom";
import { get } from "helpers/ApiHelper";
import { useFetchSelect, useForm } from "components/Form";

const Odi = () => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const [data, setData] = useState({});
  const [ufValue, setUfValue] = useState(0);

  const segments = useFetchSelect("segments");
  const typeElements = useFetchSelect("typeelements");
  const generalAttributes = useFetchSelect("generalattributes");
  const specificAttributes = useFetchSelect("specificattributes");
  const commercialStatuses = useFetchSelect("commercialstatuses");
  const { requestMessage, errors, sendForm } = useForm();

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    let response = await get("products/" + id);
    setData({
      ...response.data,
      segments: response.data.segments.map((item) => item.id),
    });

    response = await get("currencies/" + 1);

    if (response.data.currencyvalues.length) {
      setUfValue(response.data.currencyvalues[0].value);
    }
  };

  const addProductAttribute = (e) => {
    e.preventDefault();
    setData((prevData) => ({
      ...prevData,
      productattributes:
        prevData.productattributes.length > 0
          ? [...prevData.productattributes, {}]
          : [{}],
    }));
  };

  const deleteProductAttribute = async (e, idx) => {
    e.stopPropagation();
    e.preventDefault();

    await new Promise((r) => setTimeout(r, 100));
    setData((prevData) => {
      prevData.productattributes.splice(idx, 1);

      prevData.productattributes.forEach((item) => (item.isOpen = false));
      return {
        ...prevData,
      };
    });
  };

  const handleProductAttribute = (idx, name, value) => {
    setData((prevData) => {
      const item = prevData.productattributes[idx];

      switch (name) {
        case "typeelement_id":
          item.typeelement_id = value;
          delete item.generalattribute_id;
          delete item.specificattributes;
          break;
        case "generalattribute_id":
          item.generalattribute_id = value;
          delete item.specificattributes;
          break;
        case "specificattributes":
          item.specificattributes = value;
          break;
        default:
          break;
      }

      return {
        ...prevData,
      };
    });
  };

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const toggleItem = (idx) => {
    setData((prevData) => {
      prevData.productattributes.forEach(
        (item, idx2) => (item.isOpen = idx === idx2 && !item.isOpen)
      );

      return {
        ...prevData,
      };
    });
  };

  return (
    <AvForm onValidSubmit={() => sendForm("products-odi", data, id)}>
      <fieldset>
        <h3 className="mb-4 ">ODI</h3>
        <Row className="align-items-center">
          <Col sm="2">
            <Label className="mb-0">Precio</Label>
          </Col>
          <NumberInput
            name="price_list"
            label="Precio"
            placeholder="-- ingrese precio --"
            prefix="$"
            onChange={handleInput}
            error={errors.price_list}
            value={data.price_list}
            disabled={see}
            required={false}
            noRow
            noLabel
          />
          <Col sm="1">
            <Label className="mb-0">Equivalente UF</Label>
          </Col>
          <NumberInput
            name="uf_equivalent"
            placeholder="-- equivalente en UF --"
            prefix="UF"
            value={data ? data.price_list / ufValue : 0}
            decimals={3}
            disabled={true}
            noRow
            noLabel
          />
          <Col sm="1">
            <Label className="mb-0">Valor UF</Label>
          </Col>
          <NumberInput
            name="uf_value"
            placeholder="-- valor UF --"
            prefix="$"
            value={ufValue}
            disabled={true}
            noRow
            noLabel
          />
        </Row>
        <SelectInput
          name="segments"
          label="Segmentos"
          placeholder="-- Seleccione estado --"
          isMultiple
          onChange={handleInput}
          options={segments}
          value={data.segments}
          error={errors.segments}
          disabled={see}
        />
        <SelectInput
          name="commercialstatus_id"
          label="Estado comercial"
          placeholder="-- Seleccione estado --"
          onChange={handleInput}
          options={commercialStatuses}
          value={data.commercialstatus_id}
          error={errors.commercialstatus_id}
          disabled={see}
        />
        <Row className="align-items-center justify-content-between">
          <Col xs={2} className="d-grid">
            <Label>Atributos del producto</Label>
          </Col>
          <Col xs="3" className="d-grid">
            <Button size="lg" color="info" onClick={addProductAttribute}>
              Agregar
            </Button>
          </Col>
        </Row>

        <Row>
          <Col sm={{ offset: 2, size: 10 }} className="vstack gap-3">
            {data.productattributes &&
              data.productattributes.map((item, idx) => (
                <div key={idx} className="">
                  <div className="hstack gap-3 mb-3">
                    <button
                      className="btn btn-outline-primary flex-fill"
                      onClick={() => toggleItem(idx)}
                      type="button"
                    >
                      Tipo de elemento {idx + 1}
                    </button>
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={(e) => deleteProductAttribute(e, idx)}
                    >
                      Eliminar
                    </button>
                  </div>
                  <Collapse className="vstack gap-3" isOpen={item.isOpen}>
                    <SelectInput
                      name="typeelement_id"
                      label="Tipo de elemento"
                      placeholder="-- Seleccione tipo de elemento --"
                      onChange={(name, value) =>
                        handleProductAttribute(idx, name, value)
                      }
                      options={typeElements}
                      value={item.typeelement_id}
                      error={errors.typeelement_id}
                      disabled={see}
                    />
                    <SelectInput
                      name="generalattribute_id"
                      label="Atributo General"
                      placeholder="-- Seleccione atributo general --"
                      onChange={(name, value) =>
                        handleProductAttribute(idx, name, value)
                      }
                      options={generalAttributes.filter(
                        (attr) => item.typeelement_id === attr.typeelement_id
                      )}
                      value={item.generalattribute_id}
                      error={errors.generalattibute_id}
                      disabled={see}
                    />
                    <DualListBox
                      name="specificattributes"
                      label="Atributos especifico"
                      options={specificAttributes.filter(
                        (attr) =>
                          item.generalattribute_id === attr.generalattribute_id
                      )}
                      value={item.specificattributes}
                      onChange={(name, value) =>
                        handleProductAttribute(idx, name, value)
                      }
                    />
                  </Collapse>
                </div>
              ))}
          </Col>
        </Row>

        {requestMessage && (
          <Row>
            <Alert color={errors ? "danger" : "success"}>
              {requestMessage}
            </Alert>
          </Row>
        )}
        <Row>
          <Col className="d-grid">
            <Link to="/product" className="d-grid">
              <Button color="info" size="lg">
                Volver a la lista
              </Button>
            </Link>
          </Col>
          {!see && (
            <Col className="d-grid">
              <Button type="submit" size="lg" color="info">
                Guardar
              </Button>
            </Col>
          )}
        </Row>
      </fieldset>
    </AvForm>
  );
};

export default Odi;
