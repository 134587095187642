/** @format */

import axios from "axios";

//apply base url for axios
export const API_URL =
  process.env.REACT_APP_BUILD_VER === "prod"
    ? `https://api.pilotogo.cl:8443/v1/`
    : `https://pilotogo-api-dev.usercode.cl:1006/v1/`;

export const TOKEN = localStorage.getItem("token");

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${TOKEN}`,
  },
  validateStatus: function (status) {
    return [200, 400].includes(status); // default
  },
});

axiosApi.interceptors.response.use(
  (response) => {
    if (response.status === 403) {
      localStorage.clear();
      window.location = "/login";
    }
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      localStorage.clear();
      window.location = "/login";
    }
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  // document.getElementById("main-content").classList.add("loading");
  const controller = new AbortController();
  return await axiosApi
    .get(
      url,
      { ...config, signal: controller.signal },
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    )
    .then((response) => {
      // document.getElementById("main-content").classList.remove("loading");
      return response.data;
    })
    .catch((error) => {
      // document.getElementById("main-content").classList.remove("loading");
      return error.response.data;
    });

  // eslint-disable-next-line
  controller.abort();
}

export async function post(url, data, config = {}) {
  //   document.getElementById("main-content").classList.add("loading");
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => {
      //   document.getElementById("main-content").classList.remove("loading");
      return response.data;
    })
    .catch((error) => {
      //   document.getElementById("main-content").classList.remove("loading");
      return error.response.data;
    });
}

export async function put(url, data, config = {}) {
  // document.getElementById("main-content").classList.add("loading");
  return axiosApi
    .put(url, data, { ...config })
    .then((response) => {
      // document.getElementById("main-content").classList.remove("loading");
      return response.data;
    })
    .catch((error) => {
      // document.getElementById("main-content").classList.remove("loading");
      return error.response.data;
    });
}

export async function del(url, config = {}) {
  // document.getElementById("main-content").classList.add("loading");
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => {
      // document.getElementById("main-content").classList.remove("loading");
      return response.data;
    })
    .catch((error) => {
      // document.getElementById("main-content").classList.remove("loading");
      return error.response.data;
    });
}
