import React, { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert } from "reactstrap";
import { TextAreaInput, TextInput } from "../../../Form/";
import { get, post, put } from "helpers/ApiHelper";
import { useParams, Link } from "react-router-dom";
import { SelectInput } from "components/Form";
import { useFetchSelect } from "components/Form";

const Content = () => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const [data, setData] = useState({});

  const suppliers = useFetchSelect("suppliers");

  const [requestMessage, setRequestMessage] = useState("");
  const [requestOk, setRequestOk] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    const response = await get("brands/" + id);
    setData({
      ...response.data,
      suppliers: response.data.suppliers.map((item) => item.id),
    });
    setRequestMessage("");
  };

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const sendForm = async () => {
    setRequestMessage("");
    const response = id
      ? await put("brands/" + id, data)
      : await post("brands", data);

    setRequestOk(response.status === 200);
    setErrors(response.status !== 200 ? response.data : {});
    setRequestMessage(response.message);
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>
          <h4 className="text-white">Marca / {id ? "Editar" : "Crear"}</h4>
        </Col>
      </Row>
      <Row className="p-2">
        <AvForm onValidSubmit={sendForm}>
          <fieldset>
            <h3>Detalle marca</h3>
            <TextInput
              name="name"
              label="Nombre"
              placeholder="-- Ingrese nombre --"
              value={data.name}
              onChange={handleInput}
              error={errors.name}
              disabled={see}
            />
            <SelectInput
              name="suppliers"
              label="Proveedores"
              placeholder="-- Seleccione proveedores --"
              value={data.suppliers}
              isMultiple
              options={suppliers}
              onChange={handleInput}
              error={errors.suppliers}
              disabled={see}
            />
            <TextAreaInput
              name="description"
              label="Descripción"
              placeholder="-- Ingrese descripción --"
              value={data.description}
              onChange={handleInput}
              error={errors.description}
              disabled={see}
              required={false}
            />
            {requestMessage && (
              <Row>
                <Alert color={requestOk ? "success" : "danger"}>
                  {requestMessage}
                </Alert>
              </Row>
            )}
            <Row>
              <Col className="d-grid">
                <Link to="/brand" className="d-grid">
                  <Button color="info" size="lg">
                    Volver a la lista
                  </Button>
                </Link>
              </Col>
              {!see && (
                <Col className="d-grid">
                  <Button type="submit" size="lg" color="info">
                    Guardar
                  </Button>
                </Col>
              )}
            </Row>
          </fieldset>
        </AvForm>
      </Row>
    </React.Fragment>
  );
};

export default Content;
