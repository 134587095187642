import React, { useState, useEffect } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert } from "reactstrap";
import { SelectInput, TextInput } from "../../../Form/";
import { get } from "helpers/ApiHelper";
import { useParams, Link } from "react-router-dom";
import { useFetchSelect, useForm } from "components/Form";

const Content = () => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const [data, setData] = useState({});
  const generalAttributes = useFetchSelect("generalattributes");
  const { requestMessage, errors, sendForm } = useForm();

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    const response = await get("specificattributes/" + id);
    setData(response.data);
  };

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const renderTitle = () => {
    let text;
    if (see) {
      text = "Ver";
    } else if (id) {
      text = "Editar";
    } else {
      text = "Crear";
    }

    return <h4 className="text-white">Atributo Especifico / {text}</h4>;
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>{renderTitle()}</Col>
      </Row>
      <Row className="p-2">
        <AvForm onValidSubmit={() => sendForm("specificattributes", data, id)}>
          <fieldset>
            <h3>Detalle Atributo Especifico</h3>
            <TextInput
              name="name"
              label="Nombre"
              placeholder="-- Ingrese nombre --"
              value={data.name}
              onChange={handleInput}
              error={errors.name}
              disabled={see}
              required={false}
            />
            <SelectInput
              name="generalattribute_id"
              label="Atributo General"
              placeholder="-- Ingrese atributo general --"
              options={generalAttributes}
              onChange={handleInput}
              value={data.generalattribute_id}
              error={errors.generalattribute_id}
              disabled={see}
            />
            {requestMessage && (
              <Row>
                <Alert
                  color={Object.keys(errors).length ? "danger" : "success"}
                >
                  {requestMessage}
                </Alert>
              </Row>
            )}
            <Row>
              <Col className="d-grid">
                <Link to="/specific-attribute" className="d-grid">
                  <Button color="info" size="lg">
                    Volver a la lista
                  </Button>
                </Link>
              </Col>
              {!see && (
                <Col className="d-grid">
                  <Button type="submit" size="lg" color="info">
                    Guardar
                  </Button>
                </Col>
              )}
            </Row>
          </fieldset>
        </AvForm>
      </Row>
    </React.Fragment>
  );
};

export default Content;
