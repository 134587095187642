import React, { createContext, useContext, useReducer } from "react";

const ModalContext = createContext();

export default function useModal(modalName) {
  const [state, dispatch] = useContext(ModalContext);

  const updateData = (name, value) => {
    dispatch({ type: "update-data", modalName, name, value });
  };

  const setData = (data) => {
    dispatch({ type: "set-data", modalName, data });
  };

  const toggleModal = (values) => {
    if (values) {
      setData(values);
    }

    dispatch({
      type: "update",
      name: "openModal",
      value: state.openModal === modalName ? null : modalName,
    });
  };

  return {
    updateData,
    setData,
    toggleModal,
    isOpen: state.openModal === modalName,
    data: state.data[modalName],
  };
}

export function ModalProvider({ children }) {
  const reducer = (state, action) => {
    switch (action.type) {
      case "update":
        return {
          ...state,
          [action.name]: action.value,
        };
      case "update-data":
        state.data[action.modalName][action.name] = action.value;
        return { ...state };
      case "set-data":
        state.data[action.modalName] = action.data;
        return { ...state };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    openModal: null,
    data: {},
  });

  return (
    <ModalContext.Provider value={[state, dispatch]}>
      {children}
    </ModalContext.Provider>
  );
}
