import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import NumberFormat from "react-number-format";
import { checkRole } from "helpers/getUser";
import ModalComments from "./ModalComments";
import ModalState from "./ModalState";
import ModalSigns from "./ModalSigns";
import ModalAuthorization from "./ModalAuthorization";

const NoData = () => {
  return <div className="text-center">No hay datos</div>;
};

const TableBudgets = ({ data, update }) => {
  const modals = {
    none: "",
    comments: "comments",
    states: "states",
    signs: "signs",
    auth: "auth",
  };

  const [selectedBudgetId, setSelectedBudgetId] = useState(-1);
  const [openModal, setOpenModal] = useState(modals.none);

  const toggleModal = async (budget, modal) => {
    if (typeof budget !== "number") {
      setOpenModal("");
      await new Promise((r) => setTimeout(r, 200));
      setSelectedBudgetId(-1);
      return;
    }

    setSelectedBudgetId(budget);
    setOpenModal(modal);
  };

  const formatButtons = (_cell, row) => {
    const canSign =
      row.budgetprocess_id === 3 &&
      checkRole([
        "Gerencia ODI",
        "Gerencia Presupuesto",
        "Gerente Inmobiliaria",
      ]);

    const canChangeStatus = (row) => {
      if (!checkRole([row.budgetactor])) return false;

      if (row.budgetstatus_id !== 1) return false;

      if (row.iteration >= 4 && row.auth?.actual_state_id !== 2) return false;

      return true;
    };

    const canEdit = (row) => {
      if (!checkRole(["Administrador", "ODI"])) return false;

      // this override everthing else
      if (row.auth?.actual_state_id === 2) return true;

      if (row.iteration >= 4) return false;

      return true;
    };

    const canAuth = (row) => {
      if (!checkRole(["Administrador", "ODI", "Gerente Inmobiliaria"]))
        return false;

      // have auth active
      if (checkRole(["ODI"]) && row.auth && row.auth.actual_state_id === 1)
        return false;

      if (row.auth && row.auth.actual_state_id >= 2) return false;

      if (row.iteration < 4) return false;

      return true;
    };

    return (
      <Row className="g-1 flex-lg-nowrap">
        <Col className="d-grid">
          <Button href={`budget/see/${row.id}`} size="sm" color="info">
            Ver
          </Button>
        </Col>
        {canEdit(row) && (
          <Col className="d-grid">
            <Button href={`budget/edit/${row.id}`} size="sm" color="secondary">
              Editar
            </Button>
          </Col>
        )}
        {canChangeStatus(row) && (
          <Col className="d-grid">
            <Button
              size="sm"
              color="success"
              onClick={() => toggleModal(row.id, modals.states)}
            >
              Enviar
            </Button>
          </Col>
        )}
        <Col className="d-grid">
          <Button
            size="sm"
            color="primary"
            onClick={() => toggleModal(row.id, modals.comments)}
          >
            Comentarios
          </Button>
        </Col>
        {canSign && (
          <Col className="d-grid">
            <Button
              size="sm"
              color="success"
              onClick={() => toggleModal(row.id, modals.signs)}
            >
              Firmas
            </Button>
          </Col>
        )}
        {canAuth(row) && (
          <Col className="d-grid">
            <Button
              size="sm"
              color="success"
              onClick={() => toggleModal(row.id, modals.auth)}
            >
              {checkRole(["ODI"]) ? "Solicitar" : "Aprobar solicitud"}
            </Button>
          </Col>
        )}
      </Row>
    );
  };

  const formatIterations = (cell, row, idx) => {
    const message = ["La iteración del presupuesto ha cambiado."];
    let color = "";

    // aprobado
    if (row.auth?.actual_state_id === 2) {
      message.push("La autorización fue aprobada");
    }

    // rechazado
    if (row.auth?.actual_state_id === 3) {
      message.push("La autorización fue aprobada");
      color = "text-danger";
    }

    return (
      <div className="d-flex align-items-center justify-content-center gap-1">
        <span>{cell}</span>
        {cell !== 1 && (
          <>
            <span>
              <i
                className={`fas fa-info-circle fs-5 ${color}`}
                id={`iteration-${idx}`}
              />
            </span>
            <UncontrolledTooltip placement="right" target={`iteration-${idx}`}>
              <ul className="list-unstyled mb-0">
                {message.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </UncontrolledTooltip>
          </>
        )}
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "Product ID",
      customType: "number",
      hidden: true,
      align: "center",
      headerAlign: "center",
      classes: "align-middle",
    },
    {
      dataField: "updatedAt",
      text: "Fecha",
      formatter: (cell) => new Date(cell).toLocaleDateString(),
      align: "center",
      headerAlign: "center",
      classes: "align-middle",
    },
    {
      dataField: "iteration",
      text: "Iteración",
      align: "center",
      headerAlign: "center",
      classes: "align-middle",
      formatter: formatIterations,
    },
    {
      dataField: "total",
      text: "Monto CLP",
      formatter: (cell) => (
        <NumberFormat
          thousandSeparator="."
          decimalSeparator=","
          prefix="$ "
          decimalScale={2}
          value={cell}
          displayType="text"
        />
      ),
      align: "center",
      headerAlign: "center",
      classes: "align-middle",
    },
    {
      dataField: "totalUF",
      text: "Monto UF",
      formatter: (cell) => (
        <NumberFormat
          decimalScale={3}
          thousandSeparator="."
          decimalSeparator=","
          prefix="UF "
          value={cell}
          displayType="text"
        />
      ),
      align: "center",
      headerAlign: "center",
      classes: "align-middle",
    },
    {
      dataField: "budgetactor",
      text: "Actor",
      align: "center",
      headerAlign: "center",
      classes: "align-middle",
    },
    {
      dataField: "budgetprocess.name",
      text: "Proceso",
      align: "center",
      headerAlign: "center",
      classes: "align-middle",
    },
    {
      dataField: "other",
      text: "Acciones",
      formatter: formatButtons,
      align: "center",
      headerAlign: "center",
      classes: "align-middle",
    },
  ];

  return (
    <>
      <ModalComments
        open={openModal === modals.comments}
        toggle={toggleModal}
        budget_id={selectedBudgetId}
        update={update}
      />
      <ModalState
        open={openModal === modals.states}
        toggle={toggleModal}
        budget_id={selectedBudgetId}
        update={update}
      />
      <ModalSigns
        open={openModal === modals.signs}
        toggle={toggleModal}
        budget_id={selectedBudgetId}
        update={update}
      />
      <ModalAuthorization
        open={openModal === modals.auth}
        toggle={toggleModal}
        budget_id={selectedBudgetId}
        update={update}
      />
      <BootstrapTable
        keyField="id"
        wrapperClasses="table-responsive"
        data={data}
        columns={columns}
        bordered={false}
        hover
        condensed
        noDataIndication={NoData}
      />
    </>
  );
};

export default TableBudgets;
