import React, { useContext } from "react";
import { Row, Col, Button, Input } from "reactstrap";
import Category from "./Category";
import { DataContext } from "./List";
import { checkRole } from "helpers/getUser";

const Zone = ({ zone_idx }) => {
  const { data, setData } = useContext(DataContext);

  const roles = checkRole([
    "Administrador",
    "Inmobiliaria",
    "Gerencia Presupuesto",
    "Gerencia ODI",
    "ODI",
  ]);

  const item = data[zone_idx];

  const appendRow = () => {
    setData((prev) => {
      prev.push({
        name: " - ",
        pos: data.length + 1,
        categories: [
          {
            name: " - ",
            pos: 1,
            elementtypes: [
              {
                name: "",
                pos: 1,
                typeelement: {
                  name: "",
                },
                elementdetail: {
                  name: "",
                  general_attribute: "",
                  specific_attribute: "",
                  unit_measurement: "",
                },
              },
            ],
          },
        ],
      });
      return [...prev];
    });
  };

  const removeRow = () => {
    setData((prev) => {
      prev.splice(zone_idx, 1);
      return [...prev];
    });
  };

  const appendCategory = () => {
    setData((prev) => {
      prev[zone_idx].categories.push({
        name: " - ",
        pos: data[zone_idx].categories.length + 1,
        elementtypes: [
          {
            name: "",
            pos: 1,
            typeelement: {
              name: "",
            },
            elementdetail: {
              name: "",
              general_attribute: "",
              specific_attribute: "",
              unit_measurement: "",
            },
          },
        ],
      });
      return [...prev];
    });
  };

  const changeName = (e) => {
    setData((prev) => {
      prev[zone_idx].name = e.target.value;
      return [...prev];
    });
  };

  const changePosition = (value) => {
    if (zone_idx < 1 && value < 0) return;
    if (zone_idx >= data.length - 1 && value > 0) return;

    setData((prev) => {
      if (value === 1) {
        prev[zone_idx].pos += 1;
        prev[zone_idx + 1].pos -= 1;
      } else if (value === -1) {
        prev[zone_idx].pos -= 1;
        prev[zone_idx - 1].pos += 1;
      }

      prev.sort((a, b) => a.pos - b.pos);

      return [...prev];
    });
  };

  return (
    <div className="mb-3">
      <Row className="fs-4 m-0">
        <Col
          className={
            item.error
              ? "array-bg-danger border border-dark"
              : "bg-secondary border border-dark"
          }
        >
          <div className="d-flex h-100 align-items-center my-auto">
            <Input
              className="text-warning"
              onChange={changeName}
              plaintext
              value={item.name}
              disabled={!roles}
            ></Input>
            {roles && (
              <div className="vstack py-1 gap-1">
                <Button
                  outline
                  color="dark"
                  size="sm"
                  onClick={() => changePosition(-1)}
                >
                  {" "}
                  <i className="fas fa-angle-up" />{" "}
                </Button>
                <Button
                  outline
                  color="dark"
                  size="sm"
                  onClick={() => changePosition(1)}
                >
                  {" "}
                  <i className="fas fa-angle-down" />{" "}
                </Button>
              </div>
            )}
          </div>
        </Col>
        <Col xs={1} className="p-1 hstack gap-2 justify-content-center">
          {roles && (
            <>
              <Button size="sm" color="success" outline onClick={appendRow}>
                <i className="fas fa-plus"></i>
              </Button>
              <Button size="sm" color="danger" outline onClick={removeRow}>
                <i className="fas fa-minus"></i>
              </Button>
            </>
          )}
        </Col>
      </Row>
      {item.categories.length ? (
        item.categories.map((_, idx) => (
          <Category key={idx} zone_idx={zone_idx} category_idx={idx} />
        ))
      ) : (
        <div className="mt-3">
          <Button size="sm" color="success" outline onClick={appendCategory}>
            Añadir categoría
          </Button>
        </div>
      )}
    </div>
  );
};

export default Zone;
