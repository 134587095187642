import React from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import Content from "../../components/Sections/Products/ListProductsMantainer";

const Class = () => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>{process.env.REACT_APP_PAGE_TITLE} | Mantenedor de Marca</title>
      </MetaTags>
      <Container fluid>
        <Content />
      </Container>
    </div>
  );
};

export default Class;
