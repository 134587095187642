/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { AvField } from "availity-reactstrap-validation";
import { Col, FormGroup, Label } from "reactstrap";

class EmailInput extends Component {
  static propTypes = {
    //match: PropTypes.object.isRequired,
    //location: PropTypes.object.isRequired,
    //history: PropTypes.object.isRequired,
    handleChangeTextInput: PropTypes.func,
    edit: PropTypes.bool,
    error: PropTypes.array,
    data: PropTypes.array,
  };

  render() {
    return (
      <React.Fragment>
        <FormGroup className="row mb-4">
          <Label className="col-sm-2 col-form-label" htmlFor="email">
            Email
          </Label>
          <Col className="col-sm-10">
            <AvField
              name="email"
              onChange={(e) => this.props.handleChangeTextInput(e)}
              value={this.props.data?.value ? this.props.data.value : null}
              className="form-control"
              errorMessage="Ingrese email"
              placeholder="Ingrese su email"
              type="email"
              //Invested securities
              disabled={
                this.props.edit === true || this.props.edit === undefined
                  ? false
                  : true
              }
              //Normal values
              required={
                this.props.edit === true || this.props.edit === undefined
                  ? true
                  : false
              }
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

export default EmailInput;
