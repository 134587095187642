import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Table } from "../../Table/";
import { get } from "helpers/ApiHelper";

const Content = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const request = await get("history");
      setData(request.status === 200 ? request.data : []);
    };

    fetchData();
  }, []);

  const columns = [
    {
      dataField: "id",
      text: "Product ID",
      customType: "number",
      hidden: true,
    },
    {
      dataField: "updatedAt",
      text: "Fecha",
      customType: "date",
      formatter: (cell) => new Date(cell).toLocaleDateString(),
    },
    {
      dataField: "updatedAt",
      text: "Hora",
      customType: "string",
      formatter: (cell) => new Date(cell).toLocaleTimeString(),
    },
    {
      dataField: "user",
      text: "Usuario",
      customType: "string",
      formatter: (cell) => `${cell.name} ${cell.lastname}`,
    },
    {
      dataField: "user.roles",
      text: "Perfil",
      customType: "string",
      formatter: (cell) => cell.map((item) => item.role).join(" - "),
    },
    {
      dataField: "module",
      text: "Modulo",
      customType: "string",
    },
    {
      dataField: "action",
      text: "Acción",
      customType: "string",
    },
    {
      dataField: "detail",
      text: "Detalle",
      customType: "string",
    },
  ];

  return (
    <React.Fragment>
      <Row className="align-items-center mb-3">
        <Col sm={4} lx="auto" className="me-auto">
          <h4 className="text-white">Historial</h4>
        </Col>
      </Row>
      <Row className="p-2">
        <Table headers={columns} data={data} />
      </Row>
    </React.Fragment>
  );
};

export default Content;
