import React from "react";
import { Button, Input } from "reactstrap";

const Search = ({ handleInput, setOffset }) => {
  const onSubmit = (e) => {
    e.preventDefault();
    const value = e.target.elements.quick_search.value;

    setOffset(0);
    handleInput("quick_search", value);
  };

  return (
    <form onSubmit={onSubmit} className="d-flex flex-wrap flex-md-nowrap gap-3">
      <Input name="quick_search" placeholder="Búsqueda rápida" />
      <Button type="submit">Buscar</Button>
    </form>
  );
};

export default Search;
