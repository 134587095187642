import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  dateFilter,
  numberFilter,
  Comparator,
  selectFilter,
} from "react-bootstrap-table2-filter";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { isNumber } from "lodash";
import { checkRole } from "helpers/getUser";

const NoData = () => {
  return <div className="text-center">No hay datos</div>;
};

const Wrapper = (props) => {
  return props.noFieldset ? (
    <>{props.children}</>
  ) : (
    <fieldset>{props.children}</fieldset>
  );
};

const Table = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const getCustomAttributes = (type, options) => {
    if (type === "string")
      return {
        filter: textFilter({ placeholder: "Ingrese un valor..." }),
        type: "string",
      };

    if (type === "number")
      return {
        type: "number",
        filter: numberFilter({
          placeholder: "Ingrese un valor...",
          defaultValue: { comparator: Comparator.GT },
        }),
      };

    if (type === "price")
      return {
        type: "number",
        filter: numberFilter({
          placeholder: "Ingrese un valor...",
          defaultValue: { comparator: Comparator.GT },
          numberClassName: "table-input",
        }),
        formatter: (cell) => (
          <NumberFormat
            prefix="$ "
            thousandSeparator="."
            decimalSeparator=","
            value={cell}
            displayType="text"
          />
        ),
      };

    if (type === "select")
      return {
        filter: selectFilter({
          placeholder: "Ingrese un valor...",
          options,
        }),
      };

    if (type === "date")
      return {
        filter: dateFilter({
          placeholder: "Ingrese un valor...",
          defaultValue: { comparator: Comparator.EQ },
          dateClassName: "table-input",
        }),
        type: "date",
        formatter: (cell) => new Date(cell).toLocaleDateString(),
      };

    if (type === "actions-full")
      return {
        formatter: ActionsButtonsFull,
        sort: false,
      };

    if (type === "actions-no-deactivate")
      return {
        formatter: ActionsButtonsNoDeactivate,
        sort: false,
      };

    if (type === "actions-delete")
      return {
        formatter: ActionsButtonsDelete,
        sort: false,
      };

    return {
      sort: false,
      headerClasses: "align-top",
    };
  };

  const ActionsButtonsFull = (_cell, row, _rowIndex, _formatExtraData) => {
    const activate = row[props.toggle?.statusProperty] === 2;
    return (
      <Row xs={1} lg={3} className="g-1 align-items-start flex-lg-nowrap">
        <Col>
          <Link
            className="d-grid"
            to={`${window.location.pathname}/see/${row.id}`}
          >
            <Button size="sm" color="info">
              Ver
            </Button>
          </Link>
        </Col>
        {checkRole(props.roles) && (
          <Col>
            <Link
              className="d-grid"
              to={`${window.location.pathname}/edit/${row.id}`}
            >
              <Button size="sm" color="secondary">
                Editar
              </Button>
            </Link>
          </Col>
        )}
        {checkRole(props.roles) && (
          <Col className="d-grid">
            <Button
              size="sm"
              color={activate ? "info" : "danger"}
              onClick={() => toggleModal(row.id)}
            >
              {activate ? "Activar" : "Desactivar"}
            </Button>
          </Col>
        )}
      </Row>
    );
  };

  const ActionsButtonsNoDeactivate = (
    _cell,
    row,
    _rowIndex,
    _formatExtraData
  ) => {
    return (
      <Row xs={1} lg={2} className="g-1 align-items-start flex-nowrap">
        <Col>
          <Link
            className="d-grid"
            to={`${window.location.pathname}/see/${row.id}`}
          >
            <Button size="sm" color="info">
              Ver
            </Button>
          </Link>
        </Col>
        {checkRole(props.roles) && (
          <Col>
            <Link
              className="d-grid"
              to={`${window.location.pathname}/edit/${row.id}`}
            >
              <Button size="sm" color="secondary">
                Editar
              </Button>
            </Link>
          </Col>
        )}
      </Row>
    );
  };

  const ActionsButtonsDelete = (_cell, row, _rowIndex, _formatExtraData) => {
    return (
      <Row xs={1} lg={3} className="g-1 align-items-start flex-lg-nowrap">
        <Col>
          <Link
            className="d-grid"
            to={`${window.location.pathname}/see/${row.id}`}
          >
            <Button size="sm" color="info">
              Ver
            </Button>
          </Link>
        </Col>
        {checkRole(props.roles) && (
          <Col>
            <Link
              className="d-grid"
              to={`${window.location.pathname}/edit/${row.id}`}
            >
              <Button size="sm" color="secondary">
                Editar
              </Button>
            </Link>
          </Col>
        )}
        {checkRole(props.roles) && (
          <Col className="d-grid">
            <Button
              size="sm"
              color="danger"
              onClick={() => toggleModal(row.id)}
            >
              Eliminar
            </Button>
          </Col>
        )}
      </Row>
    );
  };

  const columns = props.headers.map((item) => ({
    // TODO: align headers to the top
    sort: true,
    align: "center",
    headerAlign: "center",
    classes: "align-middle",
    ...getCustomAttributes(item.customType, item.options),
    ...item,
  }));

  const toggleModal = (id) => {
    setShowModal((prev) => !prev);
    setModalId(isNumber(id) ? id : 0);
    setTimeout(() => setErrorMessage(""), 500);
  };

  // TODO: improve this so always show error messages
  const runToggleFunction = async () => {
    if (props.toggle) {
      await props.toggle?.toggleFunction(modalId);
      toggleModal();
    }

    if (props.delete) {
      const response = await props.delete(modalId);

      console.log(response);

      if (response) {
        setErrorMessage(response);
      } else {
        toggleModal();
      }
    }
  };

  return (
    <Wrapper {...props}>
      <Modal centered toggle={toggleModal} isOpen={showModal}>
        <ModalHeader className="justify-content-center">
          ¿Esta seguro?
        </ModalHeader>
        <ModalBody className="">
          {errorMessage && (
            <div>
              <Alert color="danger">{errorMessage}</Alert>
            </div>
          )}
          <section className="hstack justify-content-around">
            <div className="d-grid">
              <Button size="lg" onClick={toggleModal}>
                Cancelar
              </Button>
            </div>
            <div className="d-grid">
              <Button color="info" size="lg" onClick={runToggleFunction}>
                Aceptar
              </Button>
            </div>
          </section>
        </ModalBody>
      </Modal>
      <BootstrapTable
        keyField="id"
        data={props.data}
        columns={columns}
        bootstrap4
        wrapperClasses="table-responsive"
        pagination={paginationFactory()}
        filter={filterFactory()}
        bordered={false}
        hover
        noDataIndication={NoData}
        {...props.baseProps}
        {...props}
      />
    </Wrapper>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string,
      text: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  noPagination: PropTypes.bool,
  roles: PropTypes.array,
};

export default Table;
