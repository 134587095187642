import React from "react";
import { Row, Button, Alert, Col } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { SelectInput, TextAreaInput } from "components/Form/";
import { useParams, Link } from "react-router-dom";
import { useFetchSelect, useForm } from "components/Form";
import { checkRole } from "helpers/getUser";

const ProductResult = ({ data, handleInput }) => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const results = useFetchSelect("productresults");
  const { requestMessage, errors, sendForm } = useForm();

  if (!checkRole(["Administrador", "Calidad"])) return <></>;

  return (
    <AvForm onValidSubmit={() => sendForm("products-result", data, id)}>
      <fieldset>
        <h3 className="mb-4 ">Resultado del proceso</h3>
        <SelectInput
          name="productresult_id"
          label="Estado"
          placeholder="-- Seleccione estado --"
          options={results}
          onChange={handleInput}
          value={data.productresult_id}
          error={errors.productresult_id}
          disabled={see}
        />
        <TextAreaInput
          name="comment_result"
          label="Comentario"
          placeholder="Comentario de estado"
          value={data.comment_result}
          onChange={handleInput}
          error={errors.comment_result}
          disabled={see}
        />
        {requestMessage && (
          <Row>
            <Alert color={errors ? "danger" : "success"}>
              {requestMessage}
            </Alert>
          </Row>
        )}
        <Row>
          <Col className="d-grid">
            <Link to="/product" className="d-grid">
              <Button color="info" size="lg">
                Volver a la lista
              </Button>
            </Link>
          </Col>
          {!see && (
            <Col className="d-grid">
              <Button type="submit" size="lg" color="info">
                Guardar
              </Button>
            </Col>
          )}
        </Row>
      </fieldset>
    </AvForm>
  );
};

export default ProductResult;
