import React, { Component } from "react";
import { Row, Col } from "reactstrap";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container-fluid">
            <Row>
              <Col sm={6}>
                {new Date().getFullYear()} ©{" "}
                <a href="https://usercode.cl/">UserCodeSpA.</a>
              </Col>
              <Col sm={6}>
                <div className="text-sm-end d-none d-sm-block">
                  Diseñado & desarrollado por{" "}
                  <a href="https://usercode.cl/">UserCodeSpA.</a>
                </div>
              </Col>
            </Row>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
