import { AvField } from "availity-reactstrap-validation";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { post } from "helpers/ApiHelper";
import React, { Component } from "react";
import { MetaTags } from "react-meta-tags";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";

class ChangedPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_data: [],
      response_data: [],
      form_errors: [],
    };
    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  //Changed value inputs
  handleChangeTextInput = async (event) => {
    // create errors outputs
    await this.setState((state) => ({
      form_data: {
        ...state.form_data,
        [event.target.name]: event.target.value,
      },
      form_errors: {
        ...state.form_errors,
        ["message_error_" + event.target.name]: "",
      },
    }));
    console.log(this.state.form_data);
  };

  //send data
  async handleValidSubmit(event, _values) {
    event.preventDefault();
    // create errors outputs
    this.setState({ form_errors: {}, message_error: "", message_success: "" });
    const data = {
      ...this.state.form_data,
    };
    let response = await post(`users/password/change`, data);

    if (response.status === 200) {
      this.setState({ message_success: response.message });
    } else {
      this.setState({
        form_errors: response.data,
        message_error: response.message,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Perfil / Cambiar contraseña</title>
          </MetaTags>
          <Container fluid={true}>
            <Row className="mb-3">
              <Col>
                <h4 className="text-white">Perfil / Cambiar Contraseña</h4>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {this.state.errorMessage && (
                      <Alert color="danger">{this.state.errorMessage}</Alert>
                    )}
                    {this.state.response_data.message && (
                      <Alert
                        color="danger"
                        response_data={this.state.response_data}
                      />
                    )}
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={this.handleValidSubmit}
                    >
                      <div className="mb-3">
                        <AvField
                          name="old_password"
                          label="Password antigua"
                          type="password"
                          required
                          placeholder="Ingrese antigua password"
                          invalid={this.state.form_errors["old_password"]}
                          errorMessage="Ingrese antigua password"
                          onChange={(e) => this.handleChangeTextInput(e)}
                        />
                        {this.state.form_errors["old_password"] !== null && (
                          <p style={{ color: "red" }}>
                            {this.state.form_errors["old_password"]}
                          </p>
                        )}
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="new_password"
                          label="Password nueva"
                          type="password"
                          required
                          className="form-control"
                          placeholder="Ingrese nueva password"
                          invalid={this.state.form_errors["new_password"]}
                          errorMessage="Ingrese nueva password"
                          onChange={(e) => this.handleChangeTextInput(e)}
                        />
                        {this.state.form_errors["new_password"] !== null && (
                          <p style={{ color: "red" }}>
                            {this.state.form_errors["new_password"]}
                          </p>
                        )}
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="new_password_repeat"
                          label="Confirmación password nueva"
                          type="password"
                          validate={{
                            required: { value: true },
                            match: { value: "new_password" },
                          }}
                          invalid={
                            this.state.form_errors["new_password_repeat"]
                          }
                          placeholder="Confirme nueva password"
                          errorMessage="Confirme nueva password"
                          onChange={(e) => this.handleChangeTextInput(e)}
                        />
                        {this.state.form_errors["new_passwor_drepeat"] !==
                          null && (
                          <p style={{ color: "red" }}>
                            {this.state.form_errors["new_password_repeat"]}
                          </p>
                        )}
                      </div>
                      <Col className="col-12">
                        <div className="mt-3 d-grid">
                          {this.state.message_error && (
                            <Alert color="danger">
                              {this.state.message_error}
                            </Alert>
                          )}
                          {this.state.message_success && (
                            <Alert color="success">
                              {this.state.message_success}
                            </Alert>
                          )}
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            // onClick={() => this.cambiarContrasena()}
                          >
                            Cambiar contraseña
                          </button>
                        </div>
                      </Col>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ChangedPassword;
