import React from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import Content from "../../../components/Sections/Maintainers/Forms/ProductState";

const State = () => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>{process.env.REACT_APP_PAGE_TITLE} | Mantenedor de Estado</title>
      </MetaTags>
      <Container fluid>
        <Content />
      </Container>
    </div>
  );
};

export default State;
