import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import { Table } from "../../../Table/";
import { get } from "helpers/ApiHelper";

const Content = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const request = await get("elements");
      setData(request.status === 200 ? request.data : []);
    };

    fetchData();
  }, []);

  const columns = [
    {
      dataField: "id",
      text: "Product ID",
      customType: "number",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Elemento",
      customType: "string",
    },
    {
      dataField: "subclass.name",
      text: "Subclase",
      customType: "string",
    },
    {
      dataField: "description",
      text: "Descripción",
      customType: "string",
    },
    {
      dataField: "actions",
      text: "Acciones",
      customType: "actions-no-deactivate",
    },
  ];

  return (
    <React.Fragment>
      <Row className="align-items-center mb-3">
        <Col sm={4} lx="auto" className="me-auto">
          <h4 className="text-white">Elemento / Listar</h4>
        </Col>
        <Col sm={4} lx={2}>
          <Link to="/element/create" className="d-grid">
            <Button color="info" size="lg">
              Crear Elemento
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="p-2">
        <Table headers={columns} data={data} />
      </Row>
    </React.Fragment>
  );
};

export default Content;
