import React, { useContext } from "react";
import { Row, Col } from "reactstrap";
import Category from "./Category";
import { DataContext } from "./Content";

const Zone = ({ zone_idx }) => {
  const { data } = useContext(DataContext);

  const item = data[zone_idx];

  return (
    <div className="mb-3">
      <Row className="fs-4 m-0">
        <Col className="bg-secondary border border-dark">
          <div className="text-warning d-flex h-100 align-items-center my-auto">
            {item.name}
          </div>
        </Col>
      </Row>
      {item.categoriesbudget.length > 0 &&
        item.categoriesbudget.map((_, idx) => (
          <Category key={idx} zone_idx={zone_idx} category_idx={idx} />
        ))}
    </div>
  );
};

export default Zone;
