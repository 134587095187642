import AvForm from "availity-reactstrap-validation/lib/AvForm";
import React, { useState, useEffect } from "react";
import { Alert, Row, Col, Button } from "reactstrap";
import { TextInput } from "../../../Form/";
import { useParams, Link } from "react-router-dom";
import { Table } from "../../../Table/";
import { get } from "helpers/ApiHelper";
import NumberFormat from "react-number-format";
import { useForm } from "components/Form";

const Iconstruye = (props) => {
  const see = window.location.pathname.includes("/see/");
  const { id } = useParams();
  const [iCode, setICode] = useState("");
  const [icomponents, setIComponents] = useState([]);
  const { requestMessage, errors, sendForm } = useForm();
  const [update, setUpdate] = useState(0);
  const [productIcomponents, setProductIcomponents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const request = await get("products/" + id);
      setProductIcomponents(
        request.status === 200 ? request.data.icomponent : []
      );
    };

    fetchData();
  }, [update, id]);

  const fetchData = async () => {
    const request = await get("icomponents?icode=" + iCode);
    setIComponents(request.status === 200 ? request.data : []);
    setUpdate(new Date().getTime());
  };

  const toggleIComponent = async (icomponent_id) => {
    await sendForm("products-icomponent", { icomponent_id }, id);
    setUpdate(new Date().getTime());
  };

  const priceFormatter = (value, currency, originalCurrency) => {
    let prefix;
    switch (currency) {
      case "CLP":
        prefix = "$ ";
        break;
      case "UF":
        prefix = "UF ";
        break;
      case "USD":
        prefix = "$ ";
        break;
      case "EUR":
        prefix = "$ ";
        break;

      default:
        break;
    }

    return currency === originalCurrency ? (
      <strong>
        <NumberFormat
          prefix={prefix}
          value={value}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
        />
      </strong>
    ) : (
      <NumberFormat
        prefix={prefix}
        value={value}
        displayType="text"
        thousandSeparator="."
        decimalSeparator=","
      />
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "Product ID",
      type: "number",
      hidden: true,
      headerClasses: "text-center text-nowrap",
    },
    {
      dataField: "icode",
      text: "Código",
      type: "string",
      headerClasses: "text-center text-nowrap",
    },
    {
      dataField: "iname",
      text: "Nombre de componente",
      type: "string",
      headerClasses: "text-center text-nowrap",
    },
    {
      dataField: "cg",
      text: "CG",
      type: "string",
      formatter: (_, row) => row.work.map((item) => item.name).join(" - "),
      headerClasses: "text-center text-nowrap",
    },
    {
      dataField: "price_clp_last",
      text: "Precio CLP",
      type: "number",
      headerClasses: "text-center text-nowrap",
      formatter: (_, row) =>
        priceFormatter(row.price_clp_last, "CLP", row.original_currency),
    },
    {
      dataField: "price_uf_last",
      text: "Precio UF",
      type: "number",
      headerClasses: "text-center text-nowrap",
      formatter: (_, row) =>
        priceFormatter(row.price_uf_last, "UF", row.original_currency),
    },
    {
      dataField: "price_usd_last",
      text: "Precio USD",
      type: "number",
      headerClasses: "text-center text-nowrap",
      formatter: (_, row) =>
        priceFormatter(row.price_usd_last, "USD", row.original_currency),
    },
    {
      dataField: "price_eur_last",
      text: "Precio EUR",
      type: "number",
      headerClasses: "text-center text-nowrap",
      formatter: (_, row) =>
        priceFormatter(row.price_eur_last, "EUR", row.original_currency),
    },
    {
      dataField: "price_date_last",
      text: "Fecha",
      type: "string",
      headerClasses: "text-center text-nowrap",
      formatter: (_, row) => new Date(row.price_date_last).toLocaleDateString(),
    },
    {
      dataField: "actions",
      text: "Acciones",
      headerClasses: "text-center text-nowrap",
      formatter: (_, row) => {
        const add = !productIcomponents.includes(row);
        return (
          <Button
            color={add ? "success" : "danger"}
            onClick={() => toggleIComponent(row.id)}
            disabled={see}
          >
            {add ? "Añadir" : "Eliminar"}
          </Button>
        );
      },
    },
  ];

  return (
    <AvForm>
      <fieldset>
        <h3 className="mb-4 ">Datos producto IConstruye</h3>
        <Row className="align-items-center">
          <TextInput
            name="code"
            label="Código"
            placeholder="M1055145"
            noRow
            onChange={(_, value) => setICode(value)}
            value={iCode}
            disabled={see}
          />
          <Col className="">
            <Button
              color="info"
              className="w-100"
              disabled={see}
              onClick={fetchData}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        <Row>
          <Table
            headers={columns}
            data={[
              ...productIcomponents,
              ...icomponents.filter(
                (item) =>
                  !productIcomponents
                    .map((item) => item.icode)
                    .includes(item.icode)
              ),
            ]}
          />
        </Row>
        <Row>
          {requestMessage && (
            <Row>
              <Alert color={errors ? "danger" : "success"}>
                {requestMessage}
              </Alert>
            </Row>
          )}
          <Col className="d-grid">
            <Link to="/product" className="d-grid">
              <Button color="info" size="lg">
                Volver a la lista
              </Button>
            </Link>
          </Col>
        </Row>
      </fieldset>
    </AvForm>
  );
};

export default Iconstruye;
