import { AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { TextAreaInput, SelectInput, useForm } from "../../Form/";
import { checkRole } from "helpers/getUser";

const ModalAuthorization = ({ open, toggle, budget_id, update }) => {
  const [data, setData] = useState({ authstatus: 1 });
  const { requestMessage, errors, sendForm, clearMsg, status } = useForm();

  useEffect(() => {
    if (!budget_id) {
      clearMsg();
    }
  }, [budget_id, clearMsg]);

  const sendData = async () => {
    await sendForm("budgets/authorization", {
      budget_id,
      ...data,
    });

    if (status !== 200) return;
    await update();
  };

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  if (!budget_id) return <></>;

  return (
    <Modal size="lg" isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Autorización de nueva iteración</ModalHeader>
      <ModalBody className="vstack gap-3">
        <AvForm onValidSubmit={sendData} className="vstack gap-3">
          {checkRole(["Gerente Inmobiliaria"]) && (
            <SelectInput
              name="authstatus"
              label="Estado"
              value={data.authstatus}
              options={[
                { value: 2, label: "Aprobado" },
                { value: 3, label: "Rechazado" },
              ]}
              onChange={handleInput}
              placeholder="Ingrese un estado..."
            />
          )}
          <TextAreaInput
            name="comment"
            label="Comentario"
            value={data.comment}
            onChange={handleInput}
            placeholder="Ingrese un comentario..."
          />
          {requestMessage && (
            <Row>
              <Alert color={Object.keys(errors).length ? "danger" : "success"}>
                {requestMessage}
              </Alert>
            </Row>
          )}
          <Row>
            <Col className="d-grid">
              <Button color="secondary" size="lg" onClick={toggle}>
                Cerrar
              </Button>
            </Col>
            <Col className="">
              <Button color="info" type="submit" className="w-100">
                Enviar
              </Button>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default ModalAuthorization;
