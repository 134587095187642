import React, { useCallback, useEffect } from "react";
import SearchBar from "./catelog-sections/SearchBar";
import Card from "./catelog-sections/Card";
import { Col, Pagination, Row } from "reactstrap";
import { get } from "helpers/ApiHelper";
import usePagination, {
  PaginationProvider,
  PaginationItems,
} from "hooks/usePagination";
import { ModalProvider } from "hooks/useModal";
import Modal from "./catelog-sections/Modal";
import ModalImage from "./catelog-sections/ModalImage";

export default function Wrapper() {
  const getData = useCallback(async (params) => {
    // status 1 = activated
    const response = await get(
      "prototypes/catalog?prototypestatus_id=1" + params
    );

    if (!response) return;

    return {
      items: response.data.prototypes,
      total: response.data.length,
    };
  }, []);

  return (
    <PaginationProvider getData={getData}>
      <ModalProvider>
        <Catalog />
      </ModalProvider>
    </PaginationProvider>
  );
}

export function Catalog() {
  const { items } = usePagination();

  return (
    <>
      <h2 className="text-white">Resultados Para:</h2>
      <SearchBar />

      <Modal />
      <ModalImage />

      <Row xs={1} lg={3} className="g-3 mb-3">
        {items.map((item, idx) => (
          <Col key={idx}>
            <Card item={item} />
          </Col>
        ))}
      </Row>

      <Row className="justify-content-end">
        <Pagination listClassName="justify-content-end">
          <PaginationItems />
        </Pagination>
      </Row>
    </>
  );
}
