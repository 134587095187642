import React from "react";
import { TextInput } from "components/Form/";
import { Row, Col, Label, Button, Card, CardBody, CardTitle } from "reactstrap";
import { API_URL } from "helpers/ApiHelper";
import { TextAreaInput } from "components/Form";

const FileItem = ({ idx, item, see, errors, methods }) => {
  if (item.deleted) {
    return <></>;
  }

  return (
    <Col>
      <Card className="rounded-border">
        <CardBody className="vstack gap-3">
          <div className="hstack">
            <CardTitle className="mb-0">Ingrese datos del archivo:</CardTitle>
            <Button
              color="danger"
              className="ms-auto"
              onClick={() => methods.handleDeleteFile(idx)}
            >
              Eliminar
            </Button>
          </div>
          <TextInput
            name="file_name"
            label="Tipo"
            placeholder="-- Ingrese tipo del archivo --"
            onChange={(_, value) =>
              methods.handleChangeFileInput(idx, "name", value)
            }
            value={item.name}
            error={errors.name}
            required={false}
            disabled={see}
          />
          <TextAreaInput
            name="description"
            label="Descripción"
            placeholder="-- ingrese descripción --"
            onChange={(name, value) =>
              methods.handleChangeFileInput(idx, name, value)
            }
            error={errors.details}
            value={item.description}
            required={false}
            disabled={see}
          />
          <Row className="align-items-center">
            <Col xs={2}>
              <Label className="mb-0">Archivo</Label>
            </Col>
            <Col>
              <input
                className={
                  errors.file
                    ? "form-control border border-danger rounded is-invalid"
                    : "form-control"
                }
                type="file"
                id="formFile"
                onChange={async (e) =>
                  await methods.handleAcceptFile(e.target.files, idx)
                }
                disabled={see}
              />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={2}>
              <Label className="mb-0">Archivo Guardado</Label>
            </Col>
            <Col className="d-grid">
              <Button
                href={item.link ? API_URL + item.link : ""}
                target="_blank"
                rel="noreferrer"
                color="info"
                disabled={!item.link}
              >
                {item.link?.split("/")[2] || "-"}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default FileItem;
