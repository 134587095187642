import React, { useContext } from "react";
import { Col, Row, Button, Input } from "reactstrap";
import Table from "./Table";
import { DataContext } from "./List";
import { checkRole } from "helpers/getUser";

const Category = ({ zone_idx, category_idx }) => {
  const { data, setData } = useContext(DataContext);

  const item = data[zone_idx].categories[category_idx];

  const roles = checkRole([
    "Administrador",
    "Inmobiliaria",
    "Gerencia Presupuesto",
    "Gerencia ODI",
    "ODI",
  ]);

  const appendRow = () => {
    setData((prev) => {
      prev[zone_idx].categories.push({
        name: " - ",
        pos: data[zone_idx].categories.length + 1,
        elementtypes: [
          {
            name: "",
            pos: 1,
            typeelement: {
              name: "",
            },
            elementdetail: {
              name: "",
              general_attribute: "",
              specific_attribute: "",
              unit_measurement: "",
            },
          },
        ],
      });
      return [...prev];
    });
  };

  const removeRow = () => {
    setData((prev) => {
      prev[zone_idx].categories.splice(category_idx, 1);
      return [...prev];
    });
  };

  const changeName = (e) => {
    setData((prev) => {
      prev[zone_idx].categories[category_idx].name = e.target.value;
      return [...prev];
    });
  };

  const changePosition = (value) => {
    if (category_idx < 1 && value < 0) return;
    if (category_idx >= data[zone_idx].categories.length - 1 && value > 0)
      return;

    setData((prev) => {
      if (value === 1) {
        prev[zone_idx].categories[category_idx].pos += 1;
        prev[zone_idx].categories[category_idx + 1].pos -= 1;
      } else if (value === -1) {
        prev[zone_idx].categories[category_idx].pos -= 1;
        prev[zone_idx].categories[category_idx - 1].pos += 1;
      }

      prev[zone_idx].categories.sort((a, b) => a.pos - b.pos);

      return [...prev];
    });
  };

  return (
    <>
      <Row className="fw-bold fs-5 m-0">
        <Col
          className={
            item.error
              ? "array-bg-danger text-white border border-top-0 border-dark"
              : "bg-secondary text-white border border-top-0 border-dark"
          }
        >
          <div className="hstack my-auto">
            <Input
              className="text-white"
              onChange={changeName}
              plaintext
              value={item.name}
              disabled={!roles}
            />
            {roles && (
              <div className="vstack py-1 gap-1">
                <Button
                  outline
                  color="dark"
                  size="sm"
                  onClick={() => changePosition(-1)}
                >
                  {" "}
                  <i className="fas fa-angle-up" />{" "}
                </Button>
                <Button
                  outline
                  color="dark"
                  size="sm"
                  onClick={() => changePosition(1)}
                >
                  {" "}
                  <i className="fas fa-angle-down" />{" "}
                </Button>
              </div>
            )}
          </div>
        </Col>
        <Col xs={1} className="p-1 hstack gap-2 justify-content-center">
          {roles && (
            <>
              <Button size="sm" color="success" outline onClick={appendRow}>
                <i className="fas fa-plus"></i>
              </Button>
              <Button size="sm" color="danger" outline onClick={removeRow}>
                <i className="fas fa-minus"></i>
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Table
        zone_idx={zone_idx}
        category_idx={category_idx}
        data={item.elementtypes}
      />
    </>
  );
};

export default Category;
