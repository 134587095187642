import React, { useState } from "react";
import { useFetchSelect, NumberInput, SelectInput } from "components/Form";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { Button, Col, Label, Row } from "reactstrap";

const Filters = ({ setFilters, setOffset }) => {
  const [helper, setHelper] = useState({});

  const segments = useFetchSelect("segments");

  const suppliers = useFetchSelect("suppliers");

  const brands = useFetchSelect("brands");

  const works = useFetchSelect("works?show_in_filter=true");

  const updateHelper = (name, value) => {
    setHelper((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setOffset(0);
    setFilters((prev) => ({ ...prev, ...helper }));
  };

  return (
    <AvForm id="filters" className="h-100" onSubmit={handleSubmit}>
      <Row xs={1} md={4} className="gy-3">
        <Col>
          <SelectInput
            name="segments"
            placeholder="Segmentos"
            inline
            noLabel
            isMultiple
            options={segments}
            onChange={updateHelper}
            value={helper.segments}
          />
        </Col>

        <Col>
          <SelectInput
            name="supplier_id"
            noLabel
            placeholder="Proveedor"
            options={suppliers}
            onChange={updateHelper}
            value={helper.supplier_id}
          />
        </Col>

        <Col>
          <SelectInput
            name="brand_id"
            noLabel
            placeholder="Marca"
            options={brands}
            onChange={updateHelper}
            value={helper.brand_id}
          />
        </Col>

        <Col>
          <SelectInput
            name="works"
            noLabel
            isMultiple
            placeholder="Proyectos"
            options={works}
            onChange={updateHelper}
            value={helper.works}
          />
        </Col>

        <Col className="d-flex">
          <Label className="my-auto">Precio (Mínimo - Máximo)</Label>
        </Col>

        <Col>
          <NumberInput
            name="price_min"
            placeholder="Precio Min (CLP)"
            prefix="$"
            noLabel
            onChange={updateHelper}
          />
        </Col>
        <Col>
          <NumberInput
            name="price_max"
            placeholder="Precio Max (CLP)"
            prefix="$"
            noLabel
            onChange={updateHelper}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <div className="">
          <div className="d-grid">
            <Button color="info" type="submit">
              Filtrar
            </Button>
          </div>
        </div>
      </Row>
    </AvForm>
  );
};
export default Filters;
