import { API_URL } from "helpers/ApiHelper";
import useModal from "hooks/useModal";
import React from "react";
import moment from "moment";
import { Button, Card as CardBS, CardBody, CardTitle } from "reactstrap";

export default function Card({ item }) {
  const { toggleModal } = useModal("ficha");
  const version =
    item.prototypeversions.find((item) => item.show_in_catalog) ??
    item.prototypeversions.at(0);

  return (
    <CardBS className="h-100 shadow product-card">
      <div className="thumbnail-scale overflow-visible">
        <img
          alt="Item img"
          src={
            API_URL +
            version.prototypeimages.find((item) => item.principal)?.link
          }
        />
      </div>
      <CardBody className="position-relative">
        <CardTitle>{item.name}</CardTitle>

        <p className="text-justify">{version.description}</p>

        <div className="position-absolute bottom-0 end-0 m-2 d-flex gap-3">
          <Button className="" color="info" onClick={() => toggleModal(item)}>
            Ficha
          </Button>
        </div>
      </CardBody>

      <div className="rounded-border-bottom py-2 px-4 fs-4 fw-bold bg-info text-white hstack justify-content-between gap-3">
        <span>Versión: {version.version_number}</span>
        <span>Fecha: {moment(version.versionDate).format("DD-MM-YYYY")}</span>
      </div>
    </CardBS>
  );
}
