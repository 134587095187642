import React, { useEffect, useState, createContext } from "react";
import { Row, Col, Button } from "reactstrap";
import { Table } from "../../Table/";
import { get, put } from "helpers/ApiHelper";
import ModalForm from "./ModalForm";
import ModalBudget from "./ModalBudget";
import { checkRole } from "helpers/getUser";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";

export const DataContext = createContext();

const Content = () => {
  const [data, setData] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [showBudget, setShowBudget] = useState(false);
  const location = useLocation();

  useEffect(() => {
    getProjects().then((projects) => {
      const params = new URLSearchParams(location.search);

      if (!params.get("project_id")) {
        return;
      }

      toggleModalBudget(
        projects.find((item) => item.id.toString() === params.get("project_id"))
      );
    });
  }, [location.search]);

  const getProjects = async () => {
    await new Promise((r) => setTimeout(r, 500));

    const request = await get("projects");
    setData(request.status === 200 ? request.data : []);

    setSelectedProject((prev) => {
      if (!prev) return null;

      return request.data.find((item) => item.id === prev.id);
    });

    return request.data;
  };

  const formatActions = (_cel, row) => {
    const active = row.projectstatus_id === 1;

    const roles = checkRole(["Administrador"]);

    const canEdit = row.budgets.length === 0 && roles;

    return (
      <Row className="g-1 flex-lg-nowrap">
        <Col className="d-grid">
          <Button size="sm" color="info" onClick={() => toggleModalBudget(row)}>
            Presupuestos
          </Button>
        </Col>
        {canEdit && (
          <Col className="d-grid">
            <Button
              size="sm"
              color="secondary"
              onClick={() => toggleModalEdit(row)}
            >
              Editar
            </Button>
          </Col>
        )}
        {roles && (
          <Col className="d-grid">
            <Button
              size="sm"
              onClick={() => toggleStatus(row)}
              color={active ? "danger" : "success"}
            >
              {active ? "Desactivar" : "Activar"}
            </Button>
          </Col>
        )}
      </Row>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "Product ID",
      customType: "number",
      hidden: true,
    },
    {
      dataField: "date",
      text: "Fecha",
      customType: "date",
    },
    {
      dataField: "owner",
      text: "Creador",
      customType: "string",
    },
    {
      dataField: "name",
      text: "Nombre",
      customType: "string",
    },
    {
      dataField: "code",
      text: "Codigo",
      customType: "string",
    },
    {
      dataField: "segment.id",
      text: "Segmento",
      formatter: (_, row) => row.segment.name,
      customType: "select",
      options: {
        1: "Bajo",
        2: "Medio Bajo",
        3: "Medio",
        4: "Medio Alto",
        5: "Alto",
      },
    },
    {
      dataField: "commune.name",
      text: "Comuna",
      customType: "string",
    },
    {
      dataField: "budget_amount_uf",
      text: "Presupuesto",
      customType: "price",
      formatter: (cell) => (
        <NumberFormat
          decimalScale={3}
          thousandSeparator="."
          decimalSeparator=","
          prefix="UF "
          value={cell}
          displayType="text"
        />
      ),
    },
    {
      dataField: "asdf",
      text: "Total",
      customType: "price",
      formatter: (_, row) => (
        <NumberFormat
          decimalScale={3}
          thousandSeparator="."
          decimalSeparator=","
          prefix="UF "
          value={row.budgets.at(-1)?.totalUF || 0}
          displayType="text"
        />
      ),
    },
    {
      dataField: "actions",
      text: "Acciones",
      formatter: formatActions,
    },
  ];

  const toggleStatus = async (row) => {
    await put(
      `projects/${row.id}/status/${row.projectstatus_id === 1 ? 2 : 1}`
    );
    getProjects();
  };

  const toggleModalEdit = (project) => {
    setSelectedProject(project?.id ? project : null);

    setShowEdit((prev) => !prev);
  };

  const toggleModalBudget = (project) => {
    setSelectedProject(project?.id ? project : null);

    setShowBudget((prev) => !prev);
  };

  return (
    <DataContext.Provider
      value={{
        update: getProjects,
      }}
    >
      <ModalForm
        open={showEdit}
        toggle={toggleModalEdit}
        project={selectedProject ? selectedProject : null}
        update={getProjects}
      />
      <ModalBudget
        open={showBudget}
        toggle={toggleModalBudget}
        project_id={selectedProject ? selectedProject.id : null}
        update={getProjects}
      />
      <Row className="align-items-center mb-3">
        <Col sm={4} lx="auto" className="me-auto">
          <h4 className="text-white">Proyectos / Listar</h4>
        </Col>
        {checkRole(["Administrador", "Gerente Inmobiliaria"]) && (
          <Col className="d-sm-flex" sm={4} lx={2}>
            <Button
              className="ms-auto"
              color="info"
              size="lg"
              onClick={() => toggleModalEdit()}
            >
              Crear Proyecto
            </Button>
          </Col>
        )}
      </Row>
      <Row className="p-2">
        <Table headers={columns} data={data} />
      </Row>
    </DataContext.Provider>
  );
};

export default Content;
