import React, { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert } from "reactstrap";
import { TextInput, SelectInput } from "../../Form/";
import { get } from "helpers/ApiHelper";
import { useForm } from "components/Form";
import { useParams, Link } from "react-router-dom";

const Content = () => {
  const { id } = useParams();
  const see = window.location.pathname.includes("/see/");

  const { requestMessage, errors, sendForm } = useForm();
  const [data, setData] = useState({});
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const getRoles = async () => {
      const response = await get("roles");
      if (response.status === 200)
        setRoles(
          response.data.map((item) => ({
            label: item.role,
            value: item.id,
            isDisabled: item.id === 1,
          }))
        );
    };

    getRoles();
  }, []);

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    const response = await get("users/" + id);
    setData({
      ...response.data,
      role_id: response.data.roles.map((item) => item.id),
    });
  };

  const handleInput = (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const renderTitle = () => {
    let text;
    if (see) {
      text = "Ver";
    } else if (id) {
      text = "Editar";
    } else {
      text = "Crear";
    }

    return <h4 className="text-white">Usuario / {text}</h4>;
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>{renderTitle()}</Col>
      </Row>
      <Row className="p-2">
        <AvForm onValidSubmit={() => sendForm("users", data, id)}>
          <fieldset>
            <h3>Detalle Usuario</h3>
            <TextInput
              name="name"
              label="Nombre"
              placeholder="-- Ingrese nombre --"
              value={data.name}
              onChange={handleInput}
              error={errors.name}
              disabled={see}
            />
            <TextInput
              name="lastname"
              label="Apellido"
              placeholder="-- Ingrese Apellido --"
              value={data.lastname}
              onChange={handleInput}
              error={errors.lastname}
              disabled={see}
            />
            <TextInput
              name="rut"
              label="RUT"
              placeholder="-- Ingrese rut --"
              value={data.rut}
              onChange={handleInput}
              error={errors.rut}
              disabled={see}
            />
            <TextInput
              name="email"
              label="Email"
              placeholder="-- Ingrese email --"
              value={data.email}
              onChange={handleInput}
              error={errors.email}
              disabled={see}
            />
            <SelectInput
              name="role_id"
              label="Rol"
              placeholder="-- Ingrese rol --"
              options={roles}
              value={data.role_id}
              onChange={handleInput}
              isMultiple
              error={errors.role_id}
              disabled={see}
            />
            {requestMessage && (
              <Row>
                <Alert
                  color={Object.keys(errors).length ? "danger" : "success"}
                >
                  {requestMessage}
                </Alert>
              </Row>
            )}
            <Row>
              <Col className="d-grid">
                <Link to="/users" className="d-grid">
                  <Button color="info" size="lg">
                    Volver a la lista
                  </Button>
                </Link>
              </Col>
              {!see && (
                <Col className="d-grid">
                  <Button type="submit" size="lg" color="info">
                    Guardar
                  </Button>
                </Col>
              )}
            </Row>
          </fieldset>
        </AvForm>
      </Row>
    </React.Fragment>
  );
};

export default Content;
